import React, { Component } from 'react';
import axios from 'axios';
import { Container, CardBody, } from "mdbreact";
// import './../../public/css/vazir.css';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';
// import './../../public/css/SelectColor.css';
import './../../public/css/countryStyle.css';
import { connect } from 'react-redux';
import { subject, siteType, pageTitle, pageName, page, userInfo, auth, member, fullAccess} from '../../dataStore/actions';
import LoadingBar from 'react-top-loading-bar';
import srcSet from '../../srcSet';
import toFarsi from '../../modules/toFarsi';
import countryName from '../../modules/countryName';
import { ReactCountryDropdown } from 'react-country-dropdown'
import siteView from '../../modules/siteView';

var w = window.innerWidth
var s = srcSet.s
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

const genderOptions = [
    { value: 1},
    { value: 0},
];
const membershipOptions = [
    { value: 0},
    { value: 1},
];

class LoginPage extends Component {

    state = {
        genderTitleL: this.props.setLT.gender,
        membershipTitleL: this.props.setLT.membershipType,
        registerType: false,
        loginType: false,
        modal: false,
        passwordView: false,
        username: '',
        password: '',
        email: '',
        messageFailed: '',
        messageSuccess: '',
        genderOption: '',
        membershipOption: '',
}

    componentDidMount = async () => {
        await this.props.dispatch(pageName(this.props.setLT.signupLogin))
        await this.props.dispatch(pageTitle(`${this.props.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
        await this.props.dispatch(page('login'))
        await this.props.dispatch(subject('login'))
        await this.props.dispatch(siteType(1))

        siteView(this.props)
        genderOptions[0].label = this.props.setLT.male
        genderOptions[1].label = this.props.setLT.female
        membershipOptions[0].label = this.props.setLT.person
        membershipOptions[1].label = this.props.setLT.business
    }

    genderChange = (genderOption) => {
        this.setState({ genderOption });
    }

    membershipChange = (membershipOption) => {
        this.setState({ membershipOption });
    }

    changeHandler = e => {
        var tx = this.props.lang==='fa' ? toFarsi(e.target.value) : e.target.value
        this.setState({ ...this.state, [e.target.name]: tx.toLowerCase().replace(/\s/g, '') });
    };

    passwordHandler = e => {
        var tx = this.props.lang==='fa' ? toFarsi(e.target.value) : e.target.value
        this.setState({ ...this.state, [e.target.name]: tx.replace(/\s/g, '') });
    };

    usernameHandler = event => {
        var tx = toFarsi(event.target.value)
        if(tx.length<=20) {
            this.setState({ username: tx.toLowerCase().replace(/\s/g, '') });
        }
    };

    handlePasswordView = () => {
        this.setState({
            passwordView: !this.state.passwordView
        })
    }

    onRecaptchaChange = (value) => {
        this.setState({ recaptchaValue: value })
    }

    handleUsernameErrors(response) {
        const usernameErr = response.data[0]['usernameErrors']
        if (usernameErr.length>0) {
            const usernameErrorsList = usernameErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ usernameErrors: usernameErrorsList })
        } else {
            this.setState({ usernameErrors: "" })
        }
    }

    handleEmailErrors(response) {
        const emailErr = response.data[0]['emailErrors']
        if (emailErr.length>0) {
            const emailErrorsList = emailErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ emailErrors: emailErrorsList })
        } else {
            this.setState({ emailErrors: "" })
        }
    }

    handlePasswordErrors(response) {
        const passwordErr = response.data[0]['passwordErrors']
        if (passwordErr.length>0) {
            const passwordErrorsList = passwordErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ passwordErrors: passwordErrorsList })
        } else {
            this.setState({ passwordErrors: "" })
        }
    }

    handleGenderErrors(response) {
        const genderErr = response.data[0]['genderErrors']
        if (genderErr.length>0) {
            const genderErrorsList = genderErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ genderErrors: genderErrorsList })
        } else {
            this.setState({ genderErrors: "" })
        }
    }

    handleMembershipErrors(response) {
        const membershipErr = response.data[0]['membershipErrors']
        if (membershipErr.length>0) {
            const membershipErrorsList = membershipErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ membershipErrors: membershipErrorsList })
        } else {
            this.setState({ membershipErrors: "" })
        }
    }

    handleFavoriteColorErrors(response) {
        const favoriteColorErr = response.data[0]['favoriteColorErrors']
        if (favoriteColorErr.length>0) {
            const favoriteColorErrorsList = favoriteColorErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ favoriteColorErrors: favoriteColorErrorsList })
        } else {
            this.setState({ favoriteColorErrors: "" })
        }
    }

    handleCountryErrors(response) {
        const countryErr = response.data[0]['countryErrors']
        if (countryErr.length>0) {
            const countryErrorsList = countryErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ countryErrors: countryErrorsList })
        } else {
            this.setState({ countryErrors: "" })
        }
    }
 
    handleRecaptchaErrors(response) {
        const recaptchaErr = response.data[0]['recaptchaErrors']
        if (recaptchaErr.length>0) {
            const recaptchaErrorsList = recaptchaErr.map((err, index) => <li key={index}>{err}</li>)
            this.setState({ recaptchaErrors: recaptchaErrorsList })
        } else {
            this.setState({ recaptchaErrors: "" })
        }
    }

    onRegister = async () => {
        this.LoadingBar.continuousStart()

        const user = {
            userId: this.props.userId,
            lang: this.props.lang,
            username: this.state.username,
            fc: this.state.fc,
            continent: this.state.continent,
            country: this.state.country,
            countryCode: this.state.countryCode,
            //email: this.state.email,
            password: this.state.password,
            recaptchaValue: this.state.recaptchaValue,
            genderValue: this.state.genderOption.value,
            businessType: this.state.membershipOption.value,
            userType : 1,
        }
        axios.post('http://localhost:4200/register/registerProccess', user)
            .then(response => {
                if (response.data !== 'register proccess') {
                    this.handleGenderErrors(response);
                    this.handleMembershipErrors(response);
                    this.handleFavoriteColorErrors(response);
                    this.handleCountryErrors(response);
                    this.handleUsernameErrors(response);
                    //this.handleEmailErrors(response);
                    this.handlePasswordErrors(response);
                    this.handleRecaptchaErrors(response);
                    this.LoadingBar.complete()
                } else {
                    axios.post('http://localhost:4200/register/register', user)
                    .then(async (res) => {
                        if(res.data.msg==='Registration failed. Username is already exist.') {
                            this.setState({
                                signedInUserErr: this.props.setLT.signedInUserErr,
                                modal: this.props.subUser ? false : true,
                                messageFailed: '',
                                usernameErrors: "",
                                passwordErrors: "",
                                genderErrors: "",
                                membershipErrors: "",
                                favoriteColorErrors: "",
                                recaptchaErrors: ""
                            });
                            this.LoadingBar.complete()
                        } else {
                            await this.props.dispatch(userInfo(res.data.user))
                            await this.props.dispatch(auth(true))
                            await this.props.dispatch(member(true))

                            this.LoadingBar.complete()
                            this.setState({
                                signedInUserErr: "",
                                messageSuccess: this.state.registerModalContentOK,
                                modal: this.props.subUser ? false : true,
                                messageFailed: '',
                                usernameErrors: "",
                                passwordErrors: "",
                                genderErrors: "",
                                membershipErrors: "",
                                favoriteColorErrors: "",
                                recaptchaErrors: ""
                            });
                            window.scrollTo(0, 0);
                            this.props.history.push(`/${this.props.lang}/user-panel`)
                        }

                    })

                    .catch((error) => {
                        this.setState({
                            messageFailed: this.state.registerModalContentNotOK,
                            messageSuccess: '',
                            modal: this.props.subUser ? false : true,
                            usernameErrors: this.state.usernameR,
                            emailErrors: "",
                            genderErrors: "",
                            membershipErrors: "",
                            favoriteColorErrors: "",
                            recaptchaErrors: ""
                        });
                        this.LoadingBar.complete()
                    });
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    onLogin = async() => {
        const user = {
            lang: this.props.lang,
            username: this.state.username,
            password: this.state.password,
            recaptchaValue: this.state.recaptchaValue
        }
        await axios.post('http://localhost:4200/login/loginProccess', user)
            .then(response => {
                // console.log('ssss', response.data)
                if (response.data !== 'login proccess') {
                    this.handleUsernameErrors(response);
                    this.handlePasswordErrors(response);
                    this.handleRecaptchaErrors(response);
                } else {
                    this.LoadingBar.continuousStart()
                    const loginInfo = {
                        username:this.state.username, 
                        password:this.state.password,
                    }
                    axios.post('http://localhost:4200/login/login', loginInfo)
                    .then(async(result) => {
                        if(result.data==='User not found'){
                            this.setState({
                                loginModal: true,
                                userPassErr: this.props.setLT.userPassErr,
                                emailErrors:'',
                                passwordErrors:'',
                                recaptchaErrors: ""
                            });
                            this.LoadingBar.complete()
                        } else if(result.data==='Wrong password') {
                            this.setState({
                                loginModal: true,
                                userPassErr: this.props.setLT.userPassErr,
                                passwordErrors: this.state.passwordNotOK,
                                usernameErrors: '',
                                recaptchaErrors: ""
                            });
                            this.LoadingBar.complete()
                        } else {
                            this.setState({
                                messageFailed: '',
                                loginModal: false,
                                loginError: '',
                                usernameErrors: '',
                                passwordErrors:'',
                                recaptchaErrors: ""
                            });
                            await this.props.dispatch(userInfo(result.data.user))
                            await this.props.dispatch(auth(true))
                            await this.props.dispatch(member(true))
                            if(this.props.userId==='607e9088bede482040af3574') await this.props.dispatch(fullAccess(true))
                            this.props.history.push(`/${this.props.lang}`)
                            window.location.reload();
                            window.scrollTo(0, 0);
                            this.LoadingBar.complete()
                        }
                    })
                    .catch((error) => {
                        if(error) {
                            this.setState({
                                messageFailed: 'متاسفانه ورود انجام نشد.\nنام کاربری یا رمز عبور اشتباه است.'.split ('\n').map ((item, i) => <p key={i}>{item}</p>),
                                auth: ''
                            });
                        }
                    });

                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    onRegisterX = () => {
        this.setState({
            registerType: !this.state.registerType,
            loginType: false,
            userPassErr: "",
        })
    }

    onLoginX = () => {
        this.setState({
            loginType: !this.state.loginType,
            registerType: false,
        })
    }

    favoriteColor = (x) => {
        this.setState({ fc : x })
    }
    
    render() {
        const {fc, userPassErr, signedInUserErr, registerType, loginType, username, email, password,
                usernameErrors, emailErrors, recaptchaErrors, passwordErrors, genderErrors, membershipErrors, favoriteColorErrors, countryErrors,
                genderOption, membershipOption, emailL, genderTitleL, membershipTitleL, 
            } = this.state;

        const {rtl, lang, setLT} = this.props;

        window.recaptchaOptions = {
            lang: lang,
            useRecaptchaNet: true,
            removeOnUnmount: false,
        };

        const loadingBar = (
            <LoadingBar
                onRef={ref => (this.LoadingBar = ref)}
                height={LBH}
                background={LBC}
            />
        )

        const pageHeader = (
            <div className='d-flex justify-content-center' style={{color:'',
                fontSize: w<s ? '30px' : '40px', alignItems:'center', margin:'0px 0px 30px'}}>
                <span>{registerType
                    ? <span className='animated fadeIn' style={{animationDelay:'.2s'}}>{setLT.signUpForm}</span>
                    : (loginType 
                        ? <span className='animated zoomIn' style={{animationDelay:'.2s'}}>{setLT.loginForm }</span>
                        : <span className='animated zoomIn' style={{animationDelay:'.2s'}}>{setLT.signupLogin }</span>
                    )}
                </span>
            </div>
        )

        const customStylesG = {
            control: (base, state) => ({
              ...base,
              'border':'2px solid #999999',
              'backgroundColor':'#ffffff99',
              width:'100% !important',
              height: 35,
              minHeight: 35,
              fontSize: '15px',
              cursor: 'pointer',
              padding: '3px 0px 0px'
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              //const color = chroma(data.color);
              return {
                ...styles,
                fontSize: '14px',
                padding:'5px 10px',
                textAlign: rtl ? 'right' : 'left',
                //backgroundColor: isDisabled ? 'red' : 'blue',
                //color: '#FFF',
                //cursor: isDisabled ? 'not-allowed' : 'default',
              }
            },
            dropdownIndicator: base => ({ ...base, padding: '2px 5px 5px' })
        };
      
        const membershipConst = (
            <div>
                <div className='d-flex animated fadeIn sticky-top' style={{ animationDelay:'0s', height:'20px', width:'100%', margin:'15px 0px 50px', zIndex:'2'}}>
                    <label htmlFor="inp5" className={`${rtl ? 'inpRTL' : 'inpLTR' }`}>
                        <Select
                            components={
                            {
                                // DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }
                            }
                            value={membershipOption}
                            onChange={this.membershipChange}
                            options={membershipOptions}
                            placeholder= {setLT.membershipType}
                            isSearchable={false}
                            styles={customStylesG}
                            theme={theme => ({
                            ...theme,
                            borderRadius: 3,
                            colors: {
                                ...theme.colors,
                                //primary25: 'hotpink',
                                primary: 'black',
                            },
                            })}
                        />
                        <input defaultValue={membershipOption} style={{textAlign: 'center', visibility:'hidden', height:'0px'}} type="text" id="inp5" placeholder="&nbsp;"/>
                        <span className="label" style={{visibility: membershipOption ? 'visible' : 'hidden'}}>{membershipTitleL}</span>
                    </label>
                </div>
                <span className='invalid-feedback' style={{ margin: '-30px 0px 10px 0px' ,display: membershipErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {membershipErrors}
                </span>
            </div>
        )

        const genderConst = (
            <div>
                <div className='d-flex animated fadeIn sticky-top' style={{ animationDelay:'0.2s', height:'20px', width:'100%', margin:'0px 0px 50px', zIndex:'1'}}>
                    <label htmlFor="inp5" className={`${rtl ? 'inpRTL' : 'inpLTR' }`}>
                        <Select
                            components={
                            {
                                // DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }
                            }
                            value={genderOption}
                            onChange={this.genderChange}
                            options={genderOptions}
                            placeholder= {setLT.gender}
                            isSearchable={false}
                            styles={customStylesG}
                            theme={theme => ({
                            ...theme,
                            borderRadius: 3,
                            colors: {
                                ...theme.colors,
                                //primary25: 'hotpink',
                                primary: 'black',
                            },
                            })}
                        />
                        <input defaultValue={genderOption} style={{textAlign: 'center', visibility:'hidden', height:'0px'}} type="text" id="inp5" placeholder="&nbsp;"/>
                        <span className="label" style={{visibility: genderOption ? 'visible' : 'hidden'}}>{genderTitleL}</span>
                    </label>
                </div>
                <span className='invalid-feedback' style={{ margin: '-30px 0px 0px 0px', display: genderErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {genderErrors}
                </span>
            </div>
        )

        const cStyle = {height:'40px', width:'40px', margin:'5px', borderRadius:'5px'}
        const sc1 = (<div className = {`C0 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(0)}></div>)
        const sc2 = (<div className = {`C1 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(1)}></div>)
        const sc3 = (<div className = {`C2 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(2)}></div>)
        const sc4 = (<div className = {`C3 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(3)}></div>)
        const sc5 = (<div className = {`C4 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(4)}></div>)
        const sc6 = (<div className = {`C5 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(5)}></div>)
        const sc7 = (<div className = {`C6 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(6)}></div>)
        const sc8 = (<div className = {`C7 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(7)}></div>)
        const sc9 = (<div className = {`C8 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(8)}></div>)
        const sc10 = (<div className = {`C9 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(9)}></div>)
        const sc11 = (<div className = {`C10 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(10)}></div>)
        const sc12 = (<div className = {`C11 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(11)}></div>)
        const sc13 = (<div className = {`C12 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(12)}></div>)
        const sc14 = (<div className = {`C13 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(13)}></div>)
        const sc15 = (<div className = {`C14 btnShadow waves-effect waves-light btn-large`} style={cStyle} onClick={() => this.favoriteColor(14)}></div>)
    
        const scFc = (<div className = {`C${fc} cardShadow`} style={cStyle}></div>)

        const colorConst = (
            <div className='animated fadeIn' style={{animationDelay:'.4s', margin: '0px 0px 30px'}}>
                <div className='d-flex' style={{color:'#7d828e', alignItems:'center'}}>
                    {setLT.favoriteColor}&nbsp;
                    {scFc}
                </div>
                <div className='d-flex ' style={{width:'100%', margin: '0px 0px 0px', padding:'0px', borderRadius:'5px', flexWrap:'wrap'}}>
                    {sc1}{sc2}{sc3}{sc4}{sc5}{sc6}{sc7}{sc8}{sc9}{sc10}{sc11}{sc12}{sc13}{sc14}{sc15}
                </div>
                <span className='invalid-feedback' style={{ margin: '10px 0px 0px 0px', display: favoriteColorErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {favoriteColorErrors}
                </span>

            </div>
        )

        const usernameConst = (
            <div>
                <div className='container1 animated fadeIn' style={{animationDelay:loginType ? '0s' : '.8s', margin: '10px 0px 0px 0px'}}>
                    <label htmlFor="inp1" className={`${rtl ? 'inpRTL' : 'inpLTR' }`}>
                        <input value={username} type="text" autocomplete="off" style={{textAlign: 'center', fontSize:'15px'}} id="inp1" placeholder="&nbsp;" name="username" onChange={this.usernameHandler}/>
                        <span className="label" style={{fontSize:'15px'}}>{setLT.username}</span>
                        <span className="border"></span>
                    </label>
                </div>
                <span className='invalid-feedback' style={{ margin: '-2px 0px 0px 0px', display : usernameErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {usernameErrors}
                </span>
                <span className='invalid-feedback' style={{ margin: '-2px 0px 0px 0px', display : signedInUserErr ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {signedInUserErr}
                </span>
            </div>
        )
        const emailConst = (
            <div>
                <div className='container1 animated fadeIn' style={{margin: '30px 0px 0px 0px'}}>
                    <label htmlFor="inp2" className={`${rtl ? 'inpRTL' : 'inpLTR' }`}>
                        <input value={email} autocomplete="off" style={{textAlign: 'center', fontSize:'15px'}} type="text" id="inp2" placeholder="&nbsp;" name="email" onChange={this.changeHandler}/>
                        <span className="label" style={{fontSize:'15px'}}>{emailL}</span>
                        <span className="border"></span>
                    </label>
                </div>
                <span className='invalid-feedback' style={{ margin: '-2px 0px 0px 0px', display: emailErrors ? 'block' : 'none'}}>
                    <ul>{emailErrors}</ul>
                </span>
            </div>
        )
        const passwordConst = (
            <div>
                <div className='container1 animated fadeIn' style={{animationDelay: loginType ? '.2s' : '1s', margin: '30px 0px 0px 0px'}}>
                    <label htmlFor="inp2" className={`${rtl ? 'inpRTL' : 'inpLTR' }`}>
                        <input value={password} autocomplete="off" style={{textAlign: 'center', fontSize:'15px'}} type="text" id="inp2" placeholder="&nbsp;" name="password" onChange={this.passwordHandler}/>
                        <span className="label" style={{fontSize:'15px'}}>{setLT.password}</span>
                        <span className="border"></span>
                    </label>
                </div>
                <span className='invalid-feedback' style={{ margin: '-2px 0px 0px 0px', display: passwordErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {passwordErrors}
                </span>
            </div>
        )
        const recaptchaConst = (
            <div>
                <div className="d-flex justify-content-start animated fadeIn" style={{animationDelay: loginType ? '.4s' : '1.2s'}}>
                    <div style={{margin: '20px 0px -5px 0px', transform:'scale(0.77)'}}>
                        <ReCAPTCHA
                            style={{margin: rtl ? '0px -38px 0px 0px' : '0px 0px 0px -38px'}}
                            sitekey={srcSet.sitekey}
                            secretkey={srcSet.secretkey}
                            onChange={this.onRecaptchaChange}
                        />
                    </div>
                </div>
                <span className='invalid-feedback rtl' style={{ fontFamily: "Vazir", display : recaptchaErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {recaptchaErrors}
                </span>
            </div>
        )
        const registerBtn = (
            <div className='d-flex justify-content-center animated fadeIn' style={{animationDelay:loginType ? '.6s' : '1.2s', alignItems:'center', flexDirection:'column'}}>
                <span className='invalid-feedback' style={{ margin: '20px 0px 0px', display: userPassErr ? 'block' : 'none', textAlign:'center'}}>
                    {userPassErr}
                </span>

                <div className='d-flex justify-content-center btnShadow'
                    style={{width: '120px', alignItems:'center', 
                            height: '35px',
                            margin: '50px',
                            //border: '2px solid #9e5dff',
                            padding: '2px',
                            color: '#ffffff',
                            backgroundColor: registerType ? '#FFA502' : '#00CCFF',
                            borderRadius: '5px'}}
                    onClick = {() => registerType ? this.onRegister() : this.onLogin()}>
                    <span style={{fontSize:'16px'}}>{registerType ? setLT.signUp : setLT.login}</span>
                </div>
            </div>
        )

        const registerX = (
            <div className='disable-select waves-effect waves-light btn-large'>
                <div style={{margin:'0px', color:registerType ? '#ffffff' : '#000000'}} onClick={() => this.onRegisterX()}>
                    <div  className={`btnShadow`} style={{width:'140px', height:'33px', margin:'5px', fontSize:'16px', border: '2px solid #FFA502', backgroundColor:registerType && '#FFA502', borderRadius:'3px', padding: '3px 5px', textAlign:'center'}}>{setLT.notMember}</div>
                </div>
            </div>
        )

        const loginX = (
            <div className='disable-select waves-effect waves-light btn-large'>
                <div style={{margin:'0px', color:loginType ? '#ffffff' : '#000000'}} onClick={() => this.onLoginX()}>
                    <div className={`btnShadow`} style={{width:'140px', height:'33px', margin:'5px', fontSize:'16px', border: '2px solid #00CCFF', backgroundColor:loginType && '#00CCFF', borderRadius:'3px', padding: '3px 5px', textAlign:'center'}}>{setLT.isMember}</div>
                </div>
            </div>
        )

        const handleSelect = (country) => {
            // console.log(country)
            // console.log(countryName(country.code, country.name))
            this.setState({
                country: countryName(country.code, country.name),
                countryCode: country.code,
                continent: country.region,
            });
        }

        const country = (
            <div className='animated fadeIn sticky-top' style={{animationDelay:'.6s', margin:'20px 0px 50px', }}>
                <div className='d-flex' style={{marginBottom:'0px', color:'#7d828e', alignItems: 'center'}}>
                    {setLT.country}&nbsp;
                    <div className='d-flex sticky-top' style={{direction:'ltr', }}>
                        <ReactCountryDropdown onSelect={handleSelect} countryCode='کد' />
                    </div>
                </div>
                <span className='invalid-feedback' style={{ margin: '-10px 0px 0px 10px', display: countryErrors ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>
                    {countryErrors}
                </span>
            </div>
        )

        return (
            <div style={{}}>
                {loadingBar}
                <Container className='d-flex justify-content-center' style={{flexDirection:'column'}}>
                    <div className='d-flex justify-content-center' style={{width:'100%'}}>
                        <div className='animated fadeInUpX backBlur' style={{animationDelay:'.5s', backgroundColor: '#ffffff99', width: '100%', maxWidth:'500px', margin:'30px 10px', padding:'5px'}}>
                        <div className='animated fadeInUpX backBlur' style={{animationDelay:'.5s', backgroundColor: '#ffffff30', width: '100%', maxWidth:'500px', margin:'0px 0px', padding:'20px 0px 0px'}}>
                            <div style={{padding:'0px'}}>
                                {pageHeader}
                            </div>
                            <CardBody style={{padding:'10px'}}>
                                <div className='d-flex justify-content-center' style={{marginBottom:'30px'}}>
                                    {loginX}
                                    {registerX}
                                </div>
                                { (registerType || loginType) &&
                                    <div className=' animated fadeInUpX' style={{borderTop: registerType ? '3px solid #FFA502' : '3px solid #00CCFF', borderRadius:'5px', padding:'25px 10px 5px'}}>
                                        {registerType && membershipConst}
                                        {registerType && genderConst}
                                        {registerType && colorConst}
                                        {registerType && country}
                                        {usernameConst}
                                        {/* emailConst */}
                                        {passwordConst}
                                        {recaptchaConst}
                                        {registerBtn}
                                    </div>
                                }
                            </CardBody>
                        </div>
                        </div>
                    </div>
                    </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mainUserId: state.userInfo['_id'],
        mainUser: state.userInfo,
        userId: state.userInfo['_id'],
        username: state.userInfo['username'],
        password: state.userInfo['password'],
        email: state.userInfo['email'],
        genderValue: state.userInfo['genderValue'],
        businessType: state.userInfo['businessType'],
        rtl: state.rtl,
        page: state.page,
    subject: state.subject,
        lang: state.lang,
        geo: state.geo,
        subUser: state.subUser,
        subUserId: state.subUserInfo['_id'],
        setLT: state.setLT,
        pageName: state.pageName,
        memberCountry: state.memberCountry,

    }
  }
  export default connect (mapStateToProps)(LoginPage);
