import React, { Component } from 'react';
import { connect } from 'react-redux';

class Eyes extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3, type4, type5, type6, type7, type8 } = this.props
            
    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type1}.jpg`)}
                    className='imgSelectSm' alt="eyes type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type2}.jpg`)}
                    className='imgSelectSm' alt="eyes type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type3}.jpg`)}
                    className='imgSelectSm' alt="eyes type 3"/>
              </div>
            }
            { type4 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type4}.jpg`)}
                    className='imgSelectSm' alt="eyes type 4"/>
              </div>
            }
            { type5 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type5}.jpg`)}
                    className='imgSelectSm' alt="eyes type 5"/>
              </div>
            }
            { type6 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type6}.jpg`)}
                    className='imgSelectSm' alt="eyes type 6"/>
              </div>
            }
            { type7 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type7}.jpg`)}
                    className='imgSelectSm' alt="eyes type 7"/>
              </div>
            }
            { type8 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyes/${type8}.jpg`)}
                    className='imgSelectSm' alt="eyes type 8"/>
              </div>
            }

          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    type1: state.eyes['type1'],
    type2: state.eyes['type2'],
    type3: state.eyes['type3'],
    type4: state.eyes['type4'],
    type5: state.eyes['type5'],
    type6: state.eyes['type6'],
    type7: state.eyes['type7'],
    type8: state.eyes['type8'],
    lang: state.lang,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(Eyes);
