import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';

import LoginPage from './pages/Public/LoginPage';

import CharacterAnalysisPage from './pages/Character/CharacterAnalysisPage';
import CharacterCardPage from './pages/Public/CharacterCardPage';

import AbjadPage from "./pages/Character/CosmicNumbers/AbjadPage";
import AstrologyPage from "./pages/Character/CosmicNumbers/AstrologyPage";
import ColorsPage from "./pages/Character/ColorPsychology/ColorsPage";
import CheeksPage from "./pages/Character/FaceReading/CheeksPage";
import ChinPage from "./pages/Character/FaceReading/ChinPage";
import EarsPage from "./pages/Character/FaceReading/EarsPage";
import EyebrowsPage from "./pages/Character/FaceReading/EyebrowsPage";
import EyeColorPage from "./pages/Character/FaceReading/EyeColorPage";
import EyesPage from "./pages/Character/FaceReading/EyesPage";
import FaceShapePage from "./pages/Character/FaceReading/FaceShapePage";
import ForeheadPage from "./pages/Character/FaceReading/ForeheadPage";
import LipsPage from "./pages/Character/FaceReading/LipsPage";
import NosePage from "./pages/Character/FaceReading/NosePage";
import BodyShapePage from "./pages/Character/Others/BodyShapePage";
import BloodTypePage from "./pages/Character/Others/BloodTypePage";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/:lang' component={CharacterCardPage} />
        <Route path='/:lang/login' component={LoginPage} />

        <Route path="/:lang/abjad" component={AbjadPage} />
        <Route path="/:lang/astrology" component={AstrologyPage} />
        <Route path="/:lang/colors" component={ColorsPage} />
        <Route path="/:lang/cheeks" component={CheeksPage} />
        <Route path="/:lang/chin" component={ChinPage} />
        <Route path="/:lang/ears" component={EarsPage} />
        <Route path="/:lang/eyebrows" component={EyebrowsPage} />
        <Route path="/:lang/eye-color" component={EyeColorPage} />
        <Route path="/:lang/eyes" component={EyesPage} />
        <Route path="/:lang/face-shape" component={FaceShapePage} />
        <Route path="/:lang/forehead" component={ForeheadPage} />
        <Route path="/:lang/lips" component={LipsPage} />
        <Route path="/:lang/nose" component={NosePage} />
        <Route path="/:lang/body-shape" component={BodyShapePage} />
        <Route path="/:lang/blood-type" component={BloodTypePage} />

        <Route path='/:lang/character-analysis' component={CharacterAnalysisPage} />

        <Route
          render={() => {
            return <CharacterCardPage/>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
