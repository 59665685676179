import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "mdbreact";
import '../../../../public/css/SelectColor.css';
import { Link } from "react-router-dom";
import langText from './../../../../langText/text';

class Abjad extends Component {

  state = {}

  componentDidMount = () => {
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { auth, setLT, PSYInfo } = this.props
    const { commonName, motherName } = this.props.PSYInfo.abjad

    return (
        <div className='d-flex justify-content-start' style={{fontWeight:''}}>
          { PSYInfo.card.abjad===1 &&
            <div style={{margin: '0px 5px -10px'}}>
                <p style={{margin: '10px 0px 0px 10px', color: '#ffffff'}}> 
                    {setLT.commonName}:
                    <span style={{ color: commonName ? '#00ff00' : 'red' }}>
                      &nbsp; { commonName ? commonName : 'undefined' }
                    </span>
                </p>
                <p style={{margin: '10px 0px 0px 10px', color: '#ffffff'}}>
                    {setLT.motherName}:
                    <span style={{ color: motherName ? '#00ff00' : 'red' }}>
                      &nbsp; { motherName ? motherName : 'undefined' }
                    </span>
                </p>
            </div>
          }
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    genderValue: state.subUser ? state.subUserInfo['genderValue'] : state.userInfo['genderValue'],
    commonName: state.subUser ? state.subUserInfo['commonName'] : state.userInfo['commonName'],
    motherName: state.subUser ? state.subUserInfo['motherName'] : state.userInfo['motherName'],
    abjadDataChart: state.abjadDataChart,
    auth: state.auth,
    lang: state.lang,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(Abjad);
