import React, { Component } from 'react';
import { connect } from 'react-redux';

class Eyebrows extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3, type4, type5, type6 } = this.props
            
    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type1}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type2}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type3}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 3"/>
              </div>
            }
            { type4 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type4}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 4"/>
              </div>
            }
            { type5 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type5}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 5"/>
              </div>
            }
            { type6 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/eyebrows/${type6}.jpg`)}
                    className='imgSelectSm' alt="eyebrows type 6"/>
              </div>
            }

          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    type1: state.eyebrows['type1'],
    type2: state.eyebrows['type2'],
    type3: state.eyebrows['type3'],
    type4: state.eyebrows['type4'],
    type5: state.eyebrows['type5'],
    type6: state.eyebrows['type6'],
    lang: state.lang,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(Eyebrows);
