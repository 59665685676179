const abjadCalculator = async (x) => {

  var i, a, z=0
  var lx = x.length
  for (i=0; i < lx; i++) {
      var s = x.slice(i,i+1)
      //پ= ب، چ = ج، ژ= ز، و گ= ک
      switch (s) {
          case 'آ':
          case 'ا':
          case 'ﺀ':
          case 'A':
          case 'E':
          case 'e':
              a = 1;
              break;
          case 'ب':
          case 'پ'://////
          case 'B':
          case 'b':
              a = 2;
              break;
          case 'ج':
          case 'چ'://////
          case 'J':
          case 'j':
              a = 3;
              break;
          case 'د':
          case 'D':
          case 'd':
              a = 4;
              break;
          case 'ه':
          case 'H':
              a = 5;
              break;
          case 'و':
          case 'V':
          case 'v':
              a = 6;
              break;
          case 'ز':
          case 'ژ'://////
          case 'Z':
              a = 7;
              break;
          case 'ح':
          case 'h':
              a = 8;
              break;
          case 'ط':
          case 't':
              a = 9;
              break;
          case 'ی':
          case 'ي':
          case 'Y':
          case 'y':
              a = 10;
              break;
          case 'ک':
          case 'ك':
          case 'گ'://////
          case 'k':
              a = 20;
              break;
          case 'ل':
          case 'L':
          case 'l':
              a = 30;
              break;
          case 'م':
          case 'M':
          case 'm':
              a = 40;
              break;
          case 'ن':
          case 'N':
          case 'n':
              a = 50;
              break;
          case 'س':
          case 's':
              a = 60;
              break;
          case 'ع':
          case 'a':
              a = 70;
              break;
          case 'ف':
          case 'F':
          case 'f':
              a = 80;
              break;
          case 'ص':
          case 'C':
              a = 90;
              break;
          case 'ق':
          case 'q':
              a = 100;
              break;
          case 'ر':
          case 'R':
          case 'r':
              a = 200;
              break;
          case 'ش':
          case 'S':
              a = 300;
              break;
          case 'ت':
          case 'T':
              a = 400;
              break;
          case 'ث':
          case 'c':
              a = 500;
              break;
          case 'خ':
          case 'K':
              a = 600;
              break;
          case 'ذ':
          case 'z':
              a = 700;
              break;
          case 'ض':
          case 'x':
              a = 800;
              break;
          case 'ظ':
          case 'X':
              a = 900;
              break;
          case 'غ':
          case 'Q':
              a = 1000;
              break;
          default: 
              a = 0;
      }
      a = a + z
      // console.log(111, a)
      z = a
  }
  var zA=z%12
  var zS=z%7
  if (zA === 0) {zA = 12}
  if (zS === 0) {zS = 7}
  //this.setState({ itemNo: zA })
//   console.log(zA + ' Abjad')
//   console.log(zS + ' Star')

  return zA
}

export default abjadCalculator; 