export const PSYInfo = (state = {
    fakeId: '',
    genderValue: '',
    card: {
        abjad : 0,
        astrology : 0,
        colors : 0,
        cheeks : 0,
        chin : 0,
        ears : 0,
        eyebrows : 0,
        eyeColor : 0,
        eyes : 0,
        faceShape : 0,
        forehead : 0,
        lips : 0,
        nose : 0,
        bloodType : 0,
        bodyShape : 0,
        sCount : 0,
        nsCount : 0,
    },
    abjad: {
        commonName:'',
        motherName:''
    },
    astrology: {
        y: {value: '', label: ''},
        m: {value: '', label: ''},
        d: {value: '', label: ''},
        mx: ''
    },
    colors: [],
    faceShape: [],
    lips: [],
    ears: [],
    eyes: [],
    eyeColor: [],
    cheeks: [],
    chin: [],
    forehead: [],
    eyebrows: [],
    nose: [],
    bodyShape: [],
    bloodType: []
}, action) => {
    switch (action.type) {
        case 'PSYInfo':
            return action.PSYInfo
        default:
            return state;
    }
  }
