import React, { Component } from 'react';
import { Container, Fa, Button, Modal, ModalBody, ModalHeader, ModalFooter} from "mdbreact";
import axios from 'axios';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import ColorsList from './ColorsList';
import '../../../public/css/SelectColor.css';
import { subject, siteType, pageTitle, colorsDataChart, PSYInfo, toggleGender, genderChange, cardChange, colorsSelectedZ, membership, pageName, page, colorsSelected, colorResult} from '../../../dataStore/actions';
import ReactResizeDetector from 'react-resize-detector';
import attributes from '../../../langText/attributes';
import siteView from '../../../modules/siteView';
import clean from '../../../public/images/background/clean.png';
import LoadingBar from 'react-top-loading-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import definition from '../../../langText/definition';
import { FaInfoCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import CardCompelete from '../../../components/CardCompelete';
import BackToCharacterCards from '../../../components/Psychology/BackToCharacterCards';
import ScrollView, { ScrollElement } from "../../../scroller";
import srcSet from '../../../srcSet';

var s = srcSet.s 
var NavH = srcSet.NavH
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

class ColorsPage extends Component { 

  state = {
    count : 25,
    preSelected:this.props.selected,
    modal2: false,
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.colorsPsychology,
    ASum1:0,
    BSum1:0,

  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
    } else {
      this.LoadingBar.continuousStart()
      await this.props.dispatch(page('colors'))
      await this.props.dispatch(subject('colors'))
      await this.props.dispatch(siteType(2))
      if(this.state.pageName===undefined) window.location.reload()
      siteView(this.props)
      // await this.props.dispatch(pageName(this.props.setLT.colorsPsychology))
      await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
      await this.colorsChart()
      this.LoadingBar.complete()
    }
  }

  onResize = () => {
    this.setState({ w: window.innerWidth })
  }

  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  colorPriorities = async () => {
    var selected = this.props.selectedZ
    this.setState({
      colorPriorities : [
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[0], priority: 1},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[1], priority: 2},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[2], priority: 3},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[3], priority: 4},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[4], priority: 5},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[5], priority: 6},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[6], priority: 7},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[7], priority: 8},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[8], priority: 9},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[9], priority: 10},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[10], priority: 11},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[11], priority: 12},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[12], priority: 13},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[13], priority: 14},
        {fakeId: this.props.PSYInfo.fakeId, itemNo: selected[14], priority: 15},
      ],
      colorsSelected : {
        fakeId: this.props.PSYInfo.fakeId,
        selected: this.props.selectedZ,
        selectedX: this.props.selectedZ.toString(),
      },
    })
  }
 
  onApprove = async () => {
      var selectedQ = this.props.selectedZ.length
      if (selectedQ !== 15) {
        this.toggle(3)
      } else {
        this.LoadingBar.continuousStart()
        this.setState({
          ASum1: undefined,
          BSum1: undefined,
        })
        await this.colorPriorities()
        await axios.post('http://localhost:4200/colors/add', this.state.colorPriorities).then(res => {});
        await axios.get('http://localhost:4200/colors/chart/' + this.props.PSYInfo.fakeId).then(async res => {
        // console.log(res.data)

          await this.props.dispatch(colorsDataChart(res.data))
        });
        await axios.get('http://localhost:4200/colors/delete/' + this.props.PSYInfo.fakeId).then(res => {});
        await this.props.dispatch(colorsSelected(this.props.selectedZ))
        await this.colorsChart()
        await this.props.dispatch(cardChange(true))
        this._scroller.scrollTo('chart')
        this.LoadingBar.complete()
      }
  }

  colorsChart = async () =>{
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
    })

    var xt = this.props.colorsDataChart
    var {rtl, lang} = this.props
    // console.log(xt)

    var A = []
    var B = []
    // var sumA = 0
    // var sumB = 0
    for(var i=0; i<xt.length; i++) {
      if(xt[i].score>0) {
        A.push(xt[i])
        // sumA = sumA + xt[i].score
      } else {
        // xt[i].score = -xt[i].score
        B.push(xt[i])
        // sumB = sumB + xt[i].score
      }
    }

    // this.props.dispatch(colorsDataChart(xt))

    A.sort((a, b) => (a.score > b.score) ? -1 : 1)
    B.sort((a, b) => (a.score > b.score) ? 1 : -1)

    // console.log(A)
    // console.log(B)

    var maxA = A[0] ? A[0].score : 0
    var minB = B[0] ? B[0].score : 0
    var maxX = Math.max(maxA, -minB)

    // console.log(maxA)
    // console.log(minB)
    // console.log(maxX)


    for (var i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      xt[i]['character']=attributes[cx][lang]
      xt[i]['definition']=definition[cx][lang]
    }

    var PSYInfoX = this.props.PSYInfo
    if(xt.length===0){
      PSYInfoX.card.colors = 0
    } else {
      PSYInfoX.card.colors = 1
    }
    await this.props.dispatch(PSYInfo(PSYInfoX))

    const definitionIcone = (
      <div className=''
          style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
          <FaInfoCircle/>
      </div>
    )
    var p
    var AChartData1 = A.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'green', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    var BChartData1 = B.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent:'', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'red', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    this.setState({ 
      AChartData1, 
      BChartData1,
      ASum1: A.length,
      BSum1: B.length
     })
  
  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }

  onNewSelection = async () => {
    this.LoadingBar.continuousStart()
    await this.props.dispatch(colorsSelected([]))
    await this.props.dispatch(colorsSelectedZ([]))
    await this.props.dispatch(colorsDataChart([]))
    await this.colorsChart()
    await this.props.dispatch(cardChange(true))
    window.location.reload()
    this.LoadingBar.complete()
  }

  render() {
    const { w, h, AChartData1, BChartData1, ASum1, BSum1 } = this.state
    const {rtl, setLT, genderChange, } = this.props;

    const loaderZ = <div className='loader-02' style={{margin: '0px', color:'#ffffff', transform: rtl ? 'rotate(180deg)' : ''}}></div>

    const approveBtn = (
      <Button color='success' onClick={this.onApprove} style={{fontSize:'15px', width:'100px'}}>
        {setLT.approve}
      </Button>
    )

    const unselectBtn = (
      <div className="text-center">
          <Button outline color="danger" onClick={() => this.toggle(2)} style={{fontSize:'13px', padding: '5px 10px 5px 10px'}}>
              {setLT.unselectAll}
              <img src={clean} alt="clean" height="25" style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}/>
          </Button>
      </div>
    )

    const btns = (
      <div className= 'd-flex justify-content-center' style={{margin: '10px 0px'}}>
        {approveBtn}
        {unselectBtn}
      </div>
    )

    const testConst = (
      <div className='' style={{margin:'0px 0px 0px 0px', padding:'0px', backgroundColor:''}}>
          <div>
            <ColorsList/>
            {btns}
          </div>
      </div>
    )

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column', padding: '0px 10px'}}>
        <div className='d-flex' style={{justifyContent:rtl ? 'space-between' : 'flex-end', alignItems:'center', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '25px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.colorsPsychology}</h1>
          <BackToCharacterCards/>
        </div>
        <img
          className='animated fadeInUpX'
          style={{width: w<s ? '100%' : '400px', borderRadius:'5px'}}
          src={require(`../../../public/images/character/colorPsychology/colors/0.jpg`)}
          alt={setLT.colorsPsychology}
        />
      </div>
    )

    const helpSelect = (
      <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'', margin:'20px 5px 30px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
          <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
              <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                  <p style={{fontSize:'16px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 0px'}}>{setLT.colorSelectionText}</p>
              </div>
          </div>
      </div>
    )

    const AChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#007304', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;{ASum1!==undefined ? `(${ASum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:'420px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {AChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const BChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', borderRadius:'0px', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#FE0202', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;{BSum1!==undefined ? `(${BSum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:'420px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {BChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const chart = (
      <ScrollView ref={scroller => this._scroller = scroller}>
        <ScrollElement name='chart'>
          <div className='center' style={{minWidth:'150px', margin:'33px 0px 20px', padding: '0px', zIndex:''}}>
            {AChart1}
            {BChart1}
          </div>
        </ScrollElement>
      </ScrollView>
    )

    const modalDeleteAll = (
      <Modal centered isOpen={this.state.modal2} toggle={() => this.toggle(2)}>
          <ModalHeader style={{fontFamily:'Vazir', padding:'0px 10px'}}>
            <MdClose style={{width:'20px', fontSize:'20px', cursor:'pointer'}} onClick={() => this.toggle(2)}/>
          </ModalHeader>
          <ModalBody style={{textAlign: rtl ? 'right' : 'left', backgroundColor:'#ffffff99'}}>
              <span style={{fontSize:'15px', }}>{setLT.unselectColorsText}</span>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-center' style={{padding:'5px'}}>
              <Button color="danger" outline style={{width:'100px', fontSize:'14px', padding:'5px'}} onClick={this.onNewSelection}>
                  {setLT.delete}
              </Button>
          </ModalFooter>
      </Modal>
    )

    const modalDragAll = (
      <Modal centered isOpen={this.state.modal3} toggle={() => this.toggle(3)}>
          <ModalHeader style={{fontFamily:'Vazir', padding:'0px 10px'}}>
            <MdClose style={{width:'20px', fontSize:'20px', cursor:'pointer'}} onClick={() => this.toggle(3)}/>
          </ModalHeader>
          <ModalBody style={{textAlign: rtl ? 'right' : 'left', backgroundColor:'#ffffff99'}}>
              <span style={{fontSize:'15px'}}>{setLT.completeColors}</span>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-center' style={{padding:'5px'}}>
              <Button color="white" style={{width:'100px', fontSize:'14px', padding:'5px'}} onClick={() => this.toggle(3)}>
                  OK
              </Button>
          </ModalFooter>
      </Modal>
    )
    
    return (
      <div>
          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
          <LoadingBar onRef={ref => (this.LoadingBar = ref)} height={LBH} background={LBC}/>
          <Container style= {{backgroundColor:'', zIndex:'0'}}>
              <div className='center' style={{flexDirection:'column', alignItems:'center'}}>
                {header}
              </div>
              {helpSelect}
              {testConst}
              {chart}
              
          </Container>
          {modalDeleteAll}
          {modalDragAll}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userInfo: state.userInfo,
    userId: state.userInfo['_id'],
    username: state.userInfo['username'],
    colorsDataChart: state.colorsDataChart,
    selected: state.colorsSelected,
    selectedZ: state.colorsSelectedZ,
    auth: state.auth,
    member: state.member,
    rtl: state.rtl,
    url: state.url,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    genderValue: state.userInfo['genderValue'],
    subUser: state.subUser,
    chSp: state.chSp,
    colorTestStart: state.colorTestStart,
    colorResult: state.colorResult,
    colorChSp: state.colorChSp,
    colorIntLink: state.colorIntLink,
    access: state.userInfo.access,
    card: state.card,
    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(ColorsPage);
