import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button } from "mdbreact";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { subject, siteType, eyebrows, card, PSYInfo, toggleGender, genderChange, eyebrowsDataChart, pageName, pageTitle, page, cardChange } from '../../../dataStore/actions';
import '../../../public/css/SelectColor.css';
import './../../../public/css/alerts.css';
import clean from '../../../public/images/background/clean.png';
import ReactResizeDetector from 'react-resize-detector';
import attributes from './../../../langText/attributes';
import srcSet from '../../../srcSet';
import LoadingBar from 'react-top-loading-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import definition from '../../../langText/definition';
import { FaInfoCircle } from 'react-icons/fa';
import CardCompelete from '../../../components/CardCompelete';
import BackToCharacterCards from '../../../components/Psychology/BackToCharacterCards';
import Gender from '../../../components/Gender';
import cardSelect from '../../../modules/cardSelect';
import HelpSelect from '../../../components/Psychology/HelpSelect';
import EyebrowsSelected from '../../../components/Psychology/FaceReading/Eyebrows/EyebrowsSelected';
import siteView from '../../../modules/siteView';

var s = srcSet.s
var NavH = srcSet.NavH
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

class EyebrowsPage extends Component {
    
  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.eyebrows,
    ASum1:0,
    BSum1:0,
}

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    // this.LoadingBar.continuousStart()
    // await this.props.dispatch(pageName(this.props.setLT.eyebrows))
    await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
    await this.props.dispatch(page('eyebrows'))
    await this.props.dispatch(subject('eyebrows'))
    await this.props.dispatch(siteType(2))
    if(this.state.pageName===undefined) window.location.reload()

    siteView(this.props)
    await this.findSelected()
    await this.setDataChart()
    await this.makeChart()
  }

  onResize = () => {
    this.setState({
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  findSelected = async () => {
    var PSYInfoX = this.props.PSYInfo
    var dataX = PSYInfoX.eyebrows
    var sx = {}
    for(var i=0; i<dataX.length; i++) {
      dataX[i].genderValue=PSYInfoX.genderValue
      var t = dataX[i].typeNo
      var n = dataX[i].itemNo
      this.setState({[`type${t}`]: n})
      sx[`type${t}`] = n
    }
    await this.props.dispatch(eyebrows(sx))
    this.LoadingBar.complete()
  }

  setDataChart = async () => {
    var data = this.props.PSYInfo.eyebrows
    await axios.post('http://localhost:4200/eyebrows/add', data).then(async res => {});
    await this.eyebrowsChart()
    await axios.get('http://localhost:4200/eyebrows/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
  }

  typeToolTip = () => {
    var T = this.state.T
    var N = this.state.N
    for(let i=0; i<=9; i++) {
      this.setState({ [`typeToolTip${i}`]:'' })
    }
    this.setState({[`typeToolTip${T}`]: eval(`this.props.setLT.eyebrowsT${N}`)})
  }

  touchMove = (T, N) => {
    this.setState({ T, N })
    this.typeToolTip()
  }

  touchEnd() {
    this.setState({ T: '', N: '' })
  }

  onImageOver(t, n) {
    this.setState({
      t, n
    })
  }

  onImageOut() {
    this.setState({
      t: '',
      n: '',
    })
  }

  // cardSelected = () => {
  //   if (this.props.auth) {
  //     cardSelect(this.props.userId).then(res => {
  //       if(res!==undefined) this.props.dispatch(card(res))
  //     })
  //   }
  // }

  onSelect = async (t, n) =>  {
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
    } else {
      this.LoadingBar.continuousStart()
      this.setState({
        ASum1: undefined,
        BSum1: undefined,
      })
      var PSYInfoX = this.props.PSYInfo
      var dataX = PSYInfoX.eyebrows
      const info = {
        fakeId: PSYInfoX.fakeId,
        genderValue: PSYInfoX.genderValue,
        typeNo: t,
        itemNo: n
      }

      if(this.state[`type${t}`]===n) {
        this.setState({[`type${t}`]: ''})
        for(var i=0; i<dataX.length; i++) {
          if(dataX[i].typeNo===t) {
            dataX.splice(i, 1)
          }
        }
      } else {
        this.setState({[`type${t}`]: n})
        if (dataX.length===0) {
          dataX.push(info)
        } else {
          for(var i=0; i<dataX.length; i++) {
            if(dataX[i].typeNo===t) {
              dataX[i].itemNo=n
              var exist = true
            }
            dataX[i].fakeId=info.fakeId
            dataX[i].genderValue=info.genderValue
          }
          if(!exist) dataX.push(info)
        }
      }
      PSYInfoX.eyebrows = dataX
      await this.props.dispatch(PSYInfo(PSYInfoX))
      await this.findSelected()
      await this.setDataChart()
      await this.props.dispatch(cardChange(true))
      this.LoadingBar.complete()
    }
  }

  onCleanAll = async () => {
    await axios.get('http://localhost:4200/eyebrows/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
    var PSYInfoX = this.props.PSYInfo
    PSYInfoX.eyebrows = []
    await this.props.dispatch(PSYInfo(PSYInfoX))
    this.setState({type1:'', type2:'', type3:'', type4:'', type5:'', type6:'', type7:'', type8:'', type9:'', type10:''})
    await this.findSelected()
    await this.eyebrowsChart()
    await this.props.dispatch(cardChange(true))
  }

  getDataChart = async () => {
    await axios.get('http://localhost:4200/eyebrows/chart/' + this.props.PSYInfo.fakeId).then(async res => {
      this.props.dispatch(eyebrowsDataChart(res.data))
    });
  }

  makeChart = async () => {
    var {rtl, lang, eyebrowsDataChart} = this.props
    var xt = eyebrowsDataChart
    // console.log(xt)

    var A = []
    var B = []
    for(var i=0; i<xt.length; i++) {
      if(xt[i].score>0) {
        A.push(xt[i])
      } else {
        B.push(xt[i])
      }
    }

    A.sort((a, b) => (a.score > b.score) ? -1 : 1)
    B.sort((a, b) => (a.score > b.score) ? 1 : -1)

    var maxA = A[0] ? A[0].score : 0
    var minB = B[0] ? B[0].score : 0
    var maxX = Math.max(maxA, -minB)

    for (var i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      xt[i]['character']=attributes[cx][lang]
      xt[i]['definition']=definition[cx][lang]
    }

    var PSYInfoX = this.props.PSYInfo
    if(xt.length===0){
      PSYInfoX.card.eyebrows = 0
    } else {
      PSYInfoX.card.eyebrows = 1
    }
    await this.props.dispatch(PSYInfo(PSYInfoX))

    const definitionIcone = (
      <div className=''
          style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
          <FaInfoCircle/>
      </div>
    )
    var p
    var AChartData1 = A.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'green', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    var BChartData1 = B.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent:'', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'red', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    this.setState({ 
      AChartData1, 
      BChartData1,
      ASum1: A.length,
      BSum1: B.length
    })

  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }
  
  eyebrowsChart = async () => {
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
    })

    await this.getDataChart()
    await this.makeChart()

  }

  onGenderChenge = async () => {
    await this.props.dispatch(genderChange(''))
    var PSYInfoX = this.props.PSYInfo
    var dataX = PSYInfoX.eyebrows
    for(var i=0; i<dataX.length; i++) {
      dataX[i].genderValue=PSYInfoX.genderValue
    }
    await axios.post('http://localhost:4200/eyebrows/add', dataX).then(async res => {});
    await this.eyebrowsChart()
    await axios.get('http://localhost:4200/eyebrows/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
  }

  render() {
    const { w, h, AChartData1, BChartData1, ASum1, BSum1, T,
          type1, type2, type3, type4, type5, type6, 
          typeToolTip1, typeToolTip2, typeToolTip3, typeToolTip4, typeToolTip5, typeToolTip6 } = this.state
    const { rtl, lang, setLT, genderChange,  } = this.props
    const loaderZ = <div className='loader-02' style={{margin: '0px', color:'#ffffff', transform: rtl ? 'rotate(180deg)' : ''}}></div>
    const center = 'd-flex justify-content-center'
    const myStyle = { transition:'0s', height: '40px', width: '', display: 'flex', margin: '40px 5px 10px', top: NavH+5, zIndex:'0' }
    
    if(genderChange==='eyebrows-Gender') this.onGenderChenge()

    const typeConst1 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===1) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===1) ? typeToolTip1 : setLT.eyebrowsShape}
        </div>
      </div>
    )

    const typeConst2 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===2) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===2) ? typeToolTip2 : setLT.eyebrowsDistance}
        </div>
      </div>
    )

    const typeConst3 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===3) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===3) ? typeToolTip3 : setLT.eyebrowsLength}
        </div>
      </div>
    )

    const typeConst4 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===4) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===4) ? typeToolTip4 : setLT.eyebrowsAsymmetric}
        </div>
      </div>
    )

    const typeConst5 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===5) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===5) ? typeToolTip5 : setLT.eyebrowsIrregular}
        </div>
      </div>
    )

    const typeConst6 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===6) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===6) ? typeToolTip6 : setLT.eyebrowsThick}
        </div>
      </div>
    )

    const unselectBtn = (
      <div className="text-center animated fadeInDown" style={{animationDelay:'.5s', margin:'0px 0px', zIndex:'0'}}>
          <Button outline color="danger" onClick={() => this.onCleanAll()} style={{fontSize:'13px', padding: '5px 10px 5px 10px'}}>
              {setLT.unselectAll}
              <img src={clean} alt="clean" height="25" style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}/>
          </Button>
      </div>
    )

    const eyebrows5 = (
      <div>
        <a data-tip data-for='eyebrows5' className={center} onClick={() => this.onSelect(1, 5)}>
          <img
            onTouchMove={() => this.touchMove(1, 5)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 5)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 5 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/5.jpg`)}
            alt={setLT.eyebrowsT5}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows5' place='bottom' type='default' className={type1 === 5 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT5 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows6 = (
      <div>
        <a data-tip data-for='eyebrows6' className={center} onClick={() => this.onSelect(1, 6)}>
          <img
            onTouchMove={() => this.touchMove(1, 6)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 6)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 6 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/6.jpg`)}
            alt={setLT.eyebrowsT6}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows6' place='bottom' type='default' className={type1 === 6 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT6 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows7 = (
      <div>
        <a data-tip data-for='eyebrows7' className={center} onClick={() => this.onSelect(1, 7)}>
          <img
            onTouchMove={() => this.touchMove(1, 7)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 7)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 7 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/7.jpg`)}
            alt={setLT.eyebrowsT7}
          />
        </a>
        {w>s &&
        <ReactTooltip id='eyebrows7' place='bottom' type='default' className={type1 === 7 ? 'greenWhite' : 'blackWhite'} effect="float">
          { setLT.eyebrowsT7 }
        </ReactTooltip>
        }
      </div>
    )

    const eyebrows9 = (
      <div>
        <a data-tip data-for='eyebrows9' className={center} onClick={() => this.onSelect(1, 9)}>
          <img
            onTouchMove={() => this.touchMove(1, 9)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 9)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 9 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/9.jpg`)}
            alt={setLT.eyebrowsT9}
          />
        </a>
        {w>s &&
        <ReactTooltip id='eyebrows9' place='bottom' type='default' className={type1 === 9 ? 'greenWhite' : 'blackWhite'} effect="float">
          { setLT.eyebrowsT9 }
        </ReactTooltip>
        }
      </div>
    )

    const eyebrows11 = (
      <div>
        <a data-tip data-for='eyebrows11' className={center} onClick={() => this.onSelect(1, 11)}>
          <img
            onTouchMove={() => this.touchMove(1, 11)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 11)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 11 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/11.jpg`)}
            alt={setLT.eyebrowsT11}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows11' place='bottom' type='default' className={type1 === 11 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT11 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows12 = (
      <div>
        <a data-tip data-for='eyebrows12' className={center} onClick={() => this.onSelect(1, 12)}>
          <img
            onTouchMove={() => this.touchMove(1, 12)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 12)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 12 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/12.jpg`)}
            alt={setLT.eyebrowsT12}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows12' place='bottom' type='default' className={type1 === 12 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT12 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows13 = (
      <div>
        <a data-tip data-for='eyebrows13' className={center} onClick={() => this.onSelect(1, 13)}>
          <img
            onTouchMove={() => this.touchMove(1, 13)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 13)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 13 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/13.jpg`)}
            alt={setLT.eyebrowsT13}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows13' place='bottom' type='default' className={type1 === 13 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT13 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows14 = (
      <div>
        <a data-tip data-for='eyebrows14' className={center} onClick={() => this.onSelect(1, 14)}>
          <img
            onTouchMove={() => this.touchMove(1, 14)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 14)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 14 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/14.jpg`)}
            alt={setLT.eyebrowsT14}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows14' place='bottom' type='default' className={type1 === 14 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT14 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows15 = (
      <div>
        <a data-tip data-for='eyebrows15' className={center} onClick={() => this.onSelect(1, 15)}>
          <img
            onTouchMove={() => this.touchMove(1, 15)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 15)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 15 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/15.jpg`)}
            alt={setLT.eyebrowsT15}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows15' place='bottom' type='default' className={type1 === 15 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT15 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows3 = (
      <div>
        <a data-tip data-for='eyebrows3' className={center} onClick={() => this.onSelect(2, 3)}>
          <img
            onTouchMove={() => this.touchMove(2, 3)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type2, 3)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type2 === 3 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/3.jpg`)}
            alt={setLT.eyebrowsT3}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows3' place='bottom' type='default' className={type2 === 3 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT3 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows2 = (
      <div>
        <a data-tip data-for='eyebrows2' className={center} onClick={() => this.onSelect(2, 2)}>
          <img
            onTouchMove={() => this.touchMove(2, 2)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type2, 2)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type2 === 2 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/2.jpg`)}
            alt={setLT.eyebrowsT2}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows2' place='bottom' type='default' className={type2 === 2 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT2 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows4 = (
      <div>
        <a data-tip data-for='eyebrows4' className={center} onClick={() => this.onSelect(2, 4)}>
          <img
            onTouchMove={() => this.touchMove(2, 4)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type2, 4)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type2 === 4 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/4.jpg`)}
            alt={setLT.eyebrowsT4}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows4' place='bottom' type='default' className={type2 === 4 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT4 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows16 = (
      <div>
        <a data-tip data-for='eyebrows16' className={center} onClick={() => this.onSelect(3, 16)}>
          <img
            onTouchMove={() => this.touchMove(3, 16)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type3, 16)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type3 === 16 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/16.jpg`)}
            alt={setLT.eyebrowsT16}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows16' place='bottom' type='default' className={type3 === 16 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT16 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows17 = (
      <div>
        <a data-tip data-for='eyebrows17' className={center} onClick={() => this.onSelect(3, 17)}>
          <img
            onTouchMove={() => this.touchMove(3, 17)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type3, 17)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type3 === 17 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/17.jpg`)}
            alt={setLT.eyebrowsT17}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows17' place='bottom' type='default' className={type3 === 17 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT17 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows18 = (
      <div>
        <a data-tip data-for='eyebrows18' className={center} onClick={() => this.onSelect(4, 18)}>
          <img
            onTouchMove={() => this.touchMove(4, 18)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type4, 18)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type4 === 18 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/18.jpg`)}
            alt={setLT.eyebrowsT18}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows18' place='bottom' type='default' className={type4 === 18 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT18 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows8 = (
      <div>
        <a data-tip data-for='eyebrows8' className={center} onClick={() => this.onSelect(5, 8)}>
          <img
            onTouchMove={() => this.touchMove(5, 8)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type5, 8)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type5 === 8 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/8.jpg`)}
            alt={setLT.eyebrowsT8}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows8' place='bottom' type='default' className={type5 === 8 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT8 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows10 = (
      <div>
        <a data-tip data-for='eyebrows10' className={center} onClick={() => this.onSelect(5, 10)}>
          <img
            onTouchMove={() => this.touchMove(5, 10)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type5, 10)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type5 === 10 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/10.jpg`)}
            alt={setLT.eyebrowsT10}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows10' place='bottom' type='default' className={type5 === 10 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT10 }
          </ReactTooltip>
        }
      </div>
    )

    const eyebrows1 = (
      <div>
        <a data-tip data-for='eyebrows1' className={center} onClick={() => this.onSelect(6, 1)}>
          <img
            onTouchMove={() => this.touchMove(6, 1)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type6, 1)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type6 === 1 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyebrows/1.jpg`)}
            alt={setLT.eyebrowsT1}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyebrows1' place='bottom' type='default' className={type6 === 1 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyebrowsT1 }
          </ReactTooltip>
        }
      </div>
    )

    const ch = ((h-(NavH*2))/2)-60
    const AChart1 = (
      <div style={{width: w<s ? '100%' : '300px', minWidth: w<s ? '100%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex sticky-top' style={{backgroundColor:'#007304', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex sticky-top' style={{width:'100%', top:0, height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;{ASum1!==undefined ? `(${ASum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {AChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const BChart1 = (
      <div style={{width: w<s ? '100%' : '300px', minWidth: w<s ? '100%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', borderRadius:'0px', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex sticky-top' style={{backgroundColor:'#FE0202', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex sticky-top' style={{width:'100%', top:0, height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;{BSum1!==undefined ? `(${BSum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {BChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const loadingBar = (
      <LoadingBar
              onRef={ref => (this.LoadingBar = ref)}
              height={LBH}
              background={LBC}
          />
    )

    const chart = (
      <div className='sticky-top' style={{minWidth:'150px', width:w<s ? '150px' : '', top:NavH, marginTop:'33px', padding: '0px', zIndex:'1'}}>
        {AChart1}
        {BChart1}
      </div>
    )

    const items = (
      <div className='' style={{width:'calc(100% - 150px)'}}>
          <div style={{margin:'40px 5px -20px'}}><Gender/></div>

          {typeConst1}
          <div className={w<s ? '' : 'center'}>
            <div className='center' style={{flexWrap:'wrap'}}>
              {eyebrows5}
              {eyebrows6}
              {eyebrows7}
              {eyebrows9}
              {eyebrows11}
              {eyebrows12}
              {eyebrows13}
              {eyebrows14}
              {eyebrows15}
            </div>
          </div>

          {typeConst2}
          <div className='center' style={{flexWrap:'wrap'}}>
            {eyebrows3}
            {eyebrows2}
            {eyebrows4}
          </div>

          {typeConst3}
          <div className='center' style={{flexWrap:'wrap'}}>
            {eyebrows16}
            {eyebrows17}
          </div>

          {typeConst4}
          <div className='center' style={{flexWrap:'wrap'}}>
            {eyebrows18}
          </div>

          {typeConst5}
          <div className='center' style={{flexWrap:'wrap'}}>
            {eyebrows8}
            {eyebrows10}
          </div>

          {typeConst6}
          <div className='center' style={{flexWrap:'wrap'}}>
            {eyebrows1}
          </div>

      </div>
    )

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column', padding: '0px 10px'}}>
        <div className='d-flex' style={{justifyContent:rtl ? 'space-between' : 'flex-end', alignItems:'center', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.eyebrows}</h1>
          <BackToCharacterCards/>
        </div>
        <img
          className='animated fadeInUpX'
          style={{width: w<s ? '100%' : '400px', borderRadius:'5px'}}
          src={require(`../../../public/images/character/faceReading/eyebrows/0.jpg`)}
          alt={setLT.eyebrows}
        />
        <div className='animated fadeInUpX' style={{height:'50px'}}><EyebrowsSelected/></div>
      </div>
    )

    return (
      <div className='' style= {{fontFamily: "Vazir", backgroundColor:'', zIndex:'0'}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        {loadingBar}
        <Container>
          <div className='center' style={{flexDirection:'column', alignItems:'center'}}>
            {header}
          </div>
          <div className='center'><HelpSelect/></div>
          {unselectBtn}
          <div className='d-flex animated fadeInUpX' style={{animationDelay:'.8s', margin:'0px 0px 20px'}}>
            {items}
            <div className='sticky-top' style={{top:NavH, zIndex:'1'}}>
              {chart}
            </div>
          </div>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    username: state.userInfo['username'],
    genderLabel: state.userInfo['genderLabel'],
    genderValue: state.subUser ? state.subUserInfo['genderValue'] : state.userInfo['genderValue'],
    eyebrowsDataChart: state.eyebrowsDataChart,
    totalDataChart: state.totalDataChart,
    type1: state.eyebrows['type1'],
    type2: state.eyebrows['type2'],
    type3: state.eyebrows['type3'],
    type4: state.eyebrows['type4'],
    type5: state.eyebrows['type5'],
    type6: state.eyebrows['type6'],
    auth: state.auth,
    rtl: state.rtl,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    subUser: state.subUser,
    chSp: state.chSp,
    chSpZ: state.chSpZ,
    subChSp: state.subChSp,
    subChSpZ: state.subChSpZ,
    totalDataChart: state.totalDataChart,
    subTotalDataChart: state.subTotalDataChart,
    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(EyebrowsPage);
