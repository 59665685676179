import { combineReducers } from 'redux';
import { userInfo } from './user/userInfo';
import { subUserInfo } from './user/subUserInfo';
import { subUser } from './user/subUser';
import { abjadDataChart } from './character/cosmicNumbers/Abjad/abjadDataChart';
import { astrologyDataChart } from './character/cosmicNumbers/Astrology/astrologyDataChart';
import { totalDataChart } from './character/total/totalDataChart';
import { subTotalDataChart } from './character/total/subTotalDataChart';
import { chSp } from './character/total/chSp';
import { chSpZ } from './character/total/chSpZ';
import { subChSp } from './character/total/subChSp';
import { totalSubChSp } from './character/total/totalSubChSp';
import { subChSpZ } from './character/total/subChSpZ';
import { character } from './character/total/character';
import { color8DataChart } from './character/colorPsychology/Color8/color8DataChart';
import { color8Selected } from './character/colorPsychology/Color8/color8Selected';
import { colorsDataChart } from './character/colorPsychology/Colors/colorsDataChart';
import { colorsSelected } from './character/colorPsychology/Colors/colorsSelected';
import { colorsSelectedZ } from './character/colorPsychology/Colors/colorsSelectedZ';
import { colorSelectedAds } from './character/colorPsychology/Colors/colorSelectedAds';
import { colorType } from './character/colorPsychology/colorType';
import { cheeks } from './character/faceReading/cheeks/cheeks';
import { cheeksDataChart } from './character/faceReading/cheeks/cheeksDataChart';
import { chin } from './character/faceReading/chin/chin';
import { chinDataChart } from './character/faceReading/chin/chinDataChart';
import { ears } from './character/faceReading/ears/ears';
import { earsDataChart } from './character/faceReading/ears/earsDataChart';
import { eyebrows } from './character/faceReading/eyebrows/eyebrows';
import { eyebrowsDataChart } from './character/faceReading/eyebrows/eyebrowsDataChart';
import { eyeColor } from './character/faceReading/eyeColor/eyeColor';
import { eyeColorDataChart } from './character/faceReading/eyeColor/eyeColorDataChart';
import { eyes } from './character/faceReading/eyes/eyes';
import { eyesDataChart } from './character/faceReading/eyes/eyesDataChart';
import { faceShape } from './character/faceReading/faceShape/faceShape';
import { faceShapeDataChart } from './character/faceReading/faceShape/faceShapeDataChart';
import { forehead } from './character/faceReading/forehead/forehead';
import { foreheadDataChart } from './character/faceReading/forehead/foreheadDataChart';
import { lips } from './character/faceReading/lips/lips';
import { lipsDataChart } from './character/faceReading/lips/lipsDataChart';
import { nose } from './character/faceReading/nose/nose';
import { noseDataChart } from './character/faceReading/nose/noseDataChart';
import { bodyShape } from './character/others/bodyShape/bodyShape';
import { bodyShapeDataChart } from './character/others/bodyShape/bodyShapeDataChart';
import { bloodType } from './character/others/bloodType/bloodType';
import { bloodTypeDataChart } from './character/others/bloodType/bloodTypeDataChart';
import { pageY } from './other/pageY';
import { page } from './other/page';
import { pageName } from './other/pageName';
import { geo } from './other/geo';
import { pageTitle } from './other/pageTitle';
import { subNavH } from './other/subNavH';
import { url } from './other/url';
import { lang } from './other/lang';
import { rtl } from './other/rtl';
import { colorProgress } from './other/colorProgress';
import { colorTestStart } from './other/colorTestStart';
import { colorResult } from './other/colorResult';
import { colorChSp } from './other/colorChSp';
import { colorIntLink } from './other/colorIntLink';
import { flag } from './other/flag';
import { birthDate } from './other/birthDate';
import { birthZ } from './other/birthZ';
import { simUnd } from './other/simUnd';
import { card } from './other/card';
import { simList } from './other/simList';
import { likeeInfo } from './other/likeeInfo';
import { memberList } from './other/memberList';
import { likerList } from './other/likerList';
import { toggleLiker } from './other/toggleLiker';
import { toggleProfile } from './other/toggleProfile';
import { comment } from './other/comment';
import { sim } from './other/sim';
import { simColor } from './other/simColor';
import { totalSim } from './other/totalSim';
import { requestIn } from './other/requestIn';
import { requestOut } from './other/requestOut';
import { follower } from './other/follower';
import { totalUnd } from './other/totalUnd';
import { auth } from './user/auth';
import { member } from './user/member';
import { membership } from './user/membership';
import { userImg } from './user/userImg';
import { subUserImg } from './user/subUserImg';
import { und } from './other/und';
import { msgDraft } from './other/msgDraft';
import { activityStatus } from './other/activityStatus';
import { typingSecond } from './other/typingSecond';
import { messageList } from './other/messageList';
import { age } from './other/age';
import { blockerList } from './other/blockerList';
import { blockeeList } from './other/blockeeList';
import { notSeenChatQTY } from './other/notSeenChatQTY';
import { notSeenNotificationQTY } from './other/notSeenNotificationQTY';
import { profileTab } from './other/profileTab';
import { searchAds } from './other/searchAds';
import { adsType } from './other/adsType';
import { rubyQty } from './other/rubyQty';
import { websiteTime } from './other/websiteTime';
import { dateZ } from './other/dateZ';
import { userSelected } from './other/userSelected';
import { toggleShowAds } from './other/toggleShowAds';
import { toggleShowRequest } from './other/toggleShowRequest';
import { adsInfo } from './other/adsInfo';
import { requestInfo } from './other/requestInfo';
import { toggleSidebar } from './other/toggleSidebar';
import { likees } from './other/likees';
import { seoProfile } from './other/seoProfile';
import { toggleEditAdsAdmin } from './other/toggleEditAdsAdmin';
import { toggleEditRequestAdmin } from './other/toggleEditRequestAdmin';
import { toggleCategory } from './other/toggleCategory';
import { categoryArr } from './other/categoryArr';
import { categoryApprove } from './other/categoryApprove';
import { updateProfile } from './other/updateProfile';
import { toggleChat } from './other/toggleChat';
import { pageYOffset } from './other/pageYOffset';
import { userLanguage } from './other/userLanguage';
import { setLT } from './other/setLT';
import { toggleStarAds } from './other/toggleStarAds';
import { toggleNewAds } from './other/toggleNewAds';
import { toggleNewRequest } from './other/toggleNewRequest';
import { toggleEditAds } from './other/toggleEditAds';
import { toggleEditRequest } from './other/toggleEditRequest';
import { toggleDeleteAds } from './other/toggleDeleteAds';
import { toggleDeleteRequest } from './other/toggleDeleteRequest';
import { toggleSpecialAds } from './other/toggleSpecialAds';
import { profileLang } from './other/profileLang';
import { lastAds } from './other/lastAds';
import { lastRequest } from './other/lastRequest';
import { starredAds } from './other/starredAds';
import { userAds } from './other/userAds';
import { userRequest } from './other/userRequest';
import { toggleUserShowAds } from './other/toggleUserShowAds';
import { toggleUserShowRequest } from './other/toggleUserShowRequest';
import { updateUserAds } from './other/updateUserAds';
import { updateUserRequest } from './other/updateUserRequest';
import { countryCountAll } from './other/countryCountAll';
import { countryCountAllChart } from './other/countryCountAllChart';
import { topElement } from './other/topElement';
import { starredCompany } from './other/starredCompany';
import { bigImage } from './other/bigImage';
import { fullAccess } from './other/fullAccess';
import { starredVideo } from './other/starredVideo';
import { cardChange } from './other/cardChange';
import { PSYInfo } from './other/PSYInfo';
import { genderChange } from './other/genderChange';
import { toggleGender } from './other/toggleGender';
import { subject } from './other/subject';
import { siteType } from './other/siteType';
import { toggleNewVideo } from './other/toggleNewVideo';
import { toggleEditVideo } from './other/toggleEditVideo';
import { toggleEditVideoAdmin } from './other/toggleEditVideoAdmin';
import { updateUserVideo } from './other/updateUserVideo';
import { userVideo } from './other/userVideo';
import { videoInfo } from './other/videoInfo';
import { memberCountry } from './other/memberCountry';
import { toggleShowVideo } from './other/toggleShowVideo';
import { toggleLoading } from './other/toggleLoading';

export default combineReducers({
    starredCompany, topElement, toggleLoading, toggleUserShowRequest, userRequest, toggleStarAds, lastRequest, toggleNewRequest, toggleDeleteRequest, toggleEditRequest, updateUserRequest, toggleEditRequestAdmin, toggleNewAds, toggleEditAds, toggleShowRequest, toggleDeleteAds, toggleUserShowAds, starredAds, 
    userInfo, notSeenChatQTY, notSeenNotificationQTY, profileTab, toggleShowAds, updateUserAds, toggleSpecialAds, 
    searchAds, adsType, rubyQty, websiteTime, dateZ, userSelected, pageName, 
    subUserInfo, adsInfo, requestInfo, toggleSidebar, likees, seoProfile, toggleEditAdsAdmin, toggleEditVideoAdmin,
    subUser, toggleCategory, categoryArr, categoryApprove, updateProfile, videoInfo, 
    abjadDataChart, toggleChat, pageYOffset, userLanguage, fullAccess, toggleShowVideo, 
    astrologyDataChart, setLT, profileLang, lastAds, userAds, starredVideo, 
    color8DataChart, countryCountAll, countryCountAllChart, toggleNewVideo, toggleEditVideo, 
    color8Selected, bigImage, cardChange, PSYInfo, genderChange, updateUserVideo, 
    colorsDataChart, toggleGender, subject, siteType, memberCountry, 
    colorsSelected, colorsSelectedZ, colorSelectedAds,
    totalDataChart, userVideo, 
    subTotalDataChart,
    totalSubChSp,
    chSp,
    chSpZ,
    subChSp,
    subChSpZ,
    character,
    colorType,
    cheeks,
    cheeksDataChart,
    chin,
    chinDataChart,
    ears,
    earsDataChart,
    eyebrows,
    eyebrowsDataChart,
    eyeColor,
    eyeColorDataChart,
    eyes,
    eyesDataChart,
    faceShape,
    faceShapeDataChart,
    forehead,
    foreheadDataChart,
    lips,
    lipsDataChart,
    nose,
    noseDataChart,
    bodyShape,
    bodyShapeDataChart,
    bloodType,
    bloodTypeDataChart,
    page,
    geo,
    pageTitle,
    subNavH,
    pageY,
    url,
    lang,
    rtl, colorProgress,
    colorTestStart,
    colorResult,
    colorChSp,
    colorIntLink,
    flag, msgDraft, activityStatus, 
    birthDate, typingSecond, 
    birthZ,
    simUnd,
    card,
    simList, memberList, likeeInfo, messageList, age, blockerList, blockeeList, 
    comment, likerList, toggleLiker, toggleProfile,
    sim, simColor, 
    totalSim,
    requestIn,
    requestOut,
    follower,
    und,
    totalUnd,
    auth,
    member, membership,
    userImg,
    subUserImg
})