import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row } from "mdbreact";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { colorsSelected, colorsSelectedZ, toggleGender } from '../../../dataStore/actions';
import '../../../public/css/SelectColor.css';
import ReactResizeDetector from 'react-resize-detector';
import srcSet from '../../../srcSet';

var s = srcSet.s
var w = window.innerWidth

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 2;

const getItemStyleH = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    ...draggableStyle,
    // width: 50,
    // height: 10,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',
  //     // some basic styles to make the items look a bit nicer
//     userSelect: 'none',
//     //padding: 0,//grid * 2*0,
// //    margin: '0px',//`0 0 ${grid}px 0`,
    // height: '7Vw',
    // width: '150Vh',
//     borderRadius: '80px',
//     //marginBottom: '30px',
    // float: 'right',
//     //marginRight: '20px',
//     borderRadius: '5px',
//     background:'#ffff',
//     // change background colour if dragging
//     //background: isDragging ? 'lightgreen' : 'yellow',

//     // styles we need to apply on draggables
});

const getListStyleH1 = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: grid,
    margin:'0px 5px',
    overflow: 'auto',
    height: '95px',
    width: '100Vw',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
    //minWidth: '1100px',
    //height: `${h/5}px`
});
const getListStyleH1RTL = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'row',
    padding: grid,
    margin:'0px 5px',
    overflow: 'auto',
    height: '95px',
    width: '100Vw',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
    //minWidth: '1100px',
    //height: `${h/5}px`
});
const getListStyleH2 = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'row',
    padding: grid,
    margin:'0px 5px',
    overflow: 'auto',
    height: '95px',
    width: '100Vw',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
    //minWidth: '1100px',
    //height: `${h/5}px`
});
const getListStyleH2RTL = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: grid,
    margin:'0px 5px',
    overflow: 'auto',
    height: '95px',
    width: '100Vw',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
    //minWidth: '1100px',
    //height: `${h/5}px`
});

const getItemStyleV = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: 120,
    height: 35,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyleV1 = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: grid,
    width: w<300 ? 110 : 130,
    height: 580,
    margin: '0px 20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
});
const getListStyleV1RTL = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: grid,
    width: w<300 ? 110 : 130,
    height: 580,
    margin: '0px 20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
});
const getListStyleV2 = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'column',
    padding: grid,
    width: w<300 ? 110 : 130,
    height: 580,
    margin: '0px 20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
});
const getListStyleV2RTL = isDraggingOver => ({
    display: 'flex',
    flexDirection: 'column',
    padding: grid,
    width: w<300 ? 110 : 130,
    height: 580,
    margin: '0px 20px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#c1c1c110',
    borderRadius: '5px',
    background: isDraggingOver ? '#ffffff50' : '#ffffff',
});

class ColorsList extends Component {

    state = {
        w: window.innerWidth,
        colorNoC0: 0,
        colorNoC1: 1,
        colorNoC2: 2,
        colorNoC3: 3,
        colorNoC4: 4,
        colorNoC5: 5,
        colorNoC6: 6,
        colorNoC7: 7,

        ColorPrioritys: [],

        items: [
            { id: '0', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C0` : 'shapeMH C0'}></div>) },
            { id: '1', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C1` : 'shapeMH C1'}></div>) },
            { id: '2', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C2` : 'shapeMH C2'}></div>) },
            { id: '3', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C3` : 'shapeMH C3'}></div>) },
            { id: '4', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C4` : 'shapeMH C4'}></div>) },
            { id: '5', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C5` : 'shapeMH C5'}></div>) },
            { id: '6', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C6` : 'shapeMH C6'}></div>) },
            { id: '7', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C7` : 'shapeMH C7'}></div>) },
            { id: '8', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C8` : 'shapeMH C8'}></div>) },
            { id: '9', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C9` : 'shapeMH C9'}></div>) },
            { id: '10', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C10` : 'shapeMH C10'}></div>) },
            { id: '11', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C11` : 'shapeMH C11'}></div>) },
            { id: '12', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C12` : 'shapeMH C12'}></div>) },
            { id: '13', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C13` : 'shapeMH C13'}></div>) },
            { id: '14', content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C14` : 'shapeMH C14'}></div>) },
        ],

        selected: [],

        isHiddenC0: true,
        isHiddenC1: true,
        isHiddenC2: true,
        isHiddenC3: true,
        isHiddenC4: true,
        isHiddenC5: true,
        isHiddenC6: true,
        isHiddenC7: true,

        priority: 0,
        priorityC0: 0,
        priorityC1: 0,
        priorityC2: 0,
        priorityC3: 0,
        priorityC4: 0,
        priorityC5: 0,
        priorityC6: 0,
        priorityC7: 0,

        xSelected: []
    };

    componentDidMount() {
        // this.language()
        this.setColorSelected()
    }

    setColorSelected() {
        if(this.props.selected.length!==0){
            var preSelected = this.props.selected
            var x0 = preSelected[0]
            var x1 = preSelected[1]
            var x2 = preSelected[2]
            var x3 = preSelected[3]
            var x4 = preSelected[4]
            var x5 = preSelected[5]
            var x6 = preSelected[6]
            var x7 = preSelected[7]
            var x8 = preSelected[8]
            var x9 = preSelected[9]
            var x10 = preSelected[10]
            var x11 = preSelected[11]
            var x12 = preSelected[12]
            var x13 = preSelected[13]
            var x14 = preSelected[14]
            // console.log('preSelectedCount', preSelected.length)
            //console.log('preSelected', x0, x1, x2, x3, x4, x5, x6, x7, x8, x9, x10, x11, x12, x13, x14)
            var w = this.state.w
            if (preSelected.length===15) {
                this.setState({
                    items: [],
                    selected: [
                        { id: `${x0}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x0}` : `shapeMH C${x0}`}></div>) },
                        { id: `${x1}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x1}` : `shapeMH C${x1}`}></div>) },
                        { id: `${x2}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x2}` : `shapeMH C${x2}`}></div>) },
                        { id: `${x3}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x3}` : `shapeMH C${x3}`}></div>) },
                        { id: `${x4}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x4}` : `shapeMH C${x4}`}></div>) },
                        { id: `${x5}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x5}` : `shapeMH C${x5}`}></div>) },
                        { id: `${x6}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x6}` : `shapeMH C${x6}`}></div>) },
                        { id: `${x7}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x7}` : `shapeMH C${x7}`}></div>) },
                        { id: `${x8}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x8}` : `shapeMH C${x8}`}></div>) },
                        { id: `${x9}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x9}` : `shapeMH C${x9}`}></div>) },
                        { id: `${x10}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x10}` : `shapeMH C${x10}`}></div>) },
                        { id: `${x11}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x11}` : `shapeMH C${x11}`}></div>) },
                        { id: `${x12}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x12}` : `shapeMH C${x12}`}></div>) },
                        { id: `${x13}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x13}` : `shapeMH C${x13}`}></div>) },
                        { id: `${x14}`, content: (<div className={w<s ? `${w<300 ? 'shapeMVS' : 'shapeMV'} C${x14}` : `shapeMH C${x14}`}></div>) },
                    ]
                });
            }

        }
    }

    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        if(this.props.PSYInfo.genderValue==='') {
            this.props.dispatch(toggleGender(true))
        } else {
            //console.log(result)
            const { source, destination } = result;
            // dropped outside the list
            if (!destination) {
                return;
            }
      
            if (source.droppableId === destination.droppableId) {
                const items = reorder(
                    this.getList(source.droppableId),
                    source.index,
                    destination.index
                );
    
                let state = { items };
    
                if (source.droppableId === 'droppable2') {
                    state = { selected: items };
                    //console.log(1111,items)
                    this.props.dispatch(colorsSelectedZ(this.colorNumber(items) ) )
    
                }
    
                this.setState(state);
            } else {
                const result = move(
                    this.getList(source.droppableId),
                    this.getList(destination.droppableId),
                    source,
                    destination
                );
    
                this.setState({
                    items: result.droppable,
                    selected: result.droppable2
                });
                this.props.dispatch(colorsSelectedZ(this.colorNumber(result.droppable2) ) )
                //console.log(1111,result.droppable2)
            }
        }
      
    };

    onClick1to2 = index => {
        if(this.props.PSYInfo.genderValue==='') {
            this.props.dispatch(toggleGender(true))
        } else {
              const droppableSource = {
                  index: index,
                  droppableId: "droppable"
              }
              const droppableDestination = {
                  index: this.state.selected.length,
                  droppableId: "droppable2"
              }
              const result = move(
                  this.state.items,
                  this.state.selected,
                  droppableSource,
                  droppableDestination
              );
      
              this.setState({
                  items: result.droppable,
                  selected: result.droppable2
              });
              this.props.dispatch(colorsSelectedZ(this.colorNumber(result.droppable2) ) )
          }
    };

    onClick2to1 = index => {
        const droppableSource = {
            index: index,
            droppableId: "droppable2"
        }
        const droppableDestination = {
            index: this.state.items.length,
            droppableId: "droppable"
        }
        const result = move(
            this.state.selected,
            this.state.items,
            droppableSource,
            droppableDestination
        );

        this.setState({
            items: result.droppable,
            selected: result.droppable2
        });
        this.props.dispatch(colorsSelectedZ(this.colorNumber(result.droppable2) ) )
    };

    onResize = async () => {
        await this.setState({
            w: window.innerWidth,
        })
        this.setColorSelected()
        // console.log(this.state.w)
    }

    colorNumber = (x) => {
        var cArray = x ? x.map(({ id }) => Number(id)) : ''
        return cArray
    }
    
    render() {
        const { w, title, box1, box2, box1T, box2T, items, selected } = this.state;
        const { rtl, lang, setLT, genderChange, selectedZ  } = this.props;

        console.log(w)
        
        const headerBox1 =  (
            <div style={{backgroundColor:"#ffffff99", borderRadius:'5px', margin:'0px -10px 5px', width:w<300 ? '110px' : '130px'}}>
                <div style={{backgroundColor:"#ffffff99", borderRadius:'5px', padding:'5px 10px', width:'100%'}}>
                    <p className='' style={{fontWeight: 'bold', color:'', margin: '0px', fontSize: '15px', flexDirection:w<s ? 'column' : '', textAlign: 'center'}}>
                        {setLT.box1}
                        {/* setLT.box1Title */}
                    </p>
                </div>
            </div>
        )
        
        const headerBox2 =  (
            <div style={{backgroundColor:"#ffffff99", borderRadius:'5px', margin:'0px -10px 5px', width:w<300 ? '110px' : '130px'}}>
                <div style={{backgroundColor:"#ffffff99", borderRadius:'5px', padding:'5px 10px', width:'100%'}}>
                    <p className='' style={{fontWeight: 'bold', color:'', margin: '0px', fontSize: '15px', flexDirection:w<s ? 'column' : '', textAlign: 'center'}}>
                        {setLT.box2}
                        {/* setLT.box2Title */}
                    </p>
                </div>
            </div>
        )
        
        return (
            <Container style={{fontFamily: "Vazir"}}>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
                <div className={w<s ? 'd-flex justify-content-center' : ''} style={{display: w<s ? "flex" : "", margin: '0px 15px'}}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className='d-flex' style={{marginTop:w>s ? '30px' : '', flexDirection:'column', alignItems: w<s ? 'center' : ''}}>
                            {headerBox1}
                            <Row>
                                <Droppable droppableId="droppable" direction={w<s ? "vertical" : "horizontal"}>
                                    {(provided, snapshot) => (
                                        <div className= 'cardShadow'
                                            ref={provided.innerRef}
                                            style={w<s
                                                ? (rtl
                                                    ? getListStyleV1RTL(snapshot.isDraggingOver)
                                                    : getListStyleV1(snapshot.isDraggingOver)
                                                )
                                                : (rtl
                                                    ? getListStyleH1RTL(snapshot.isDraggingOver)
                                                    : getListStyleH1(snapshot.isDraggingOver)
                                                )
                                            }>
                                            {this.state.items.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            onClick={() => this.onClick1to2(index)}//this.onDragEnd()
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={w<s
                                                                ? getItemStyleV(snapshot.isDragging,provided.draggableProps.style)
                                                                : getItemStyleH(snapshot.isDragging,provided.draggableProps.style)
                                                            }>
                                                            {item.content}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Row>
                        </div>
                        <div className='d-flex' style={{marginTop:w>s ? '20px' : '', flexDirection:'column', alignItems: w<s ? 'center' : ''}}>
                            {headerBox2}
                            <Row>
                                <Droppable droppableId="droppable2" direction={w<s ? "vertical" : "horizontal"}>
                                    {(provided, snapshot) => (
                                        <div className= 'cardShadow'
                                            ref={provided.innerRef}
                                            style={w<s
                                                ? (rtl
                                                        ? getListStyleV2RTL(snapshot.isDraggingOver)
                                                        : getListStyleV2(snapshot.isDraggingOver)
                                                )
                                                : (rtl
                                                        ? getListStyleH2RTL(snapshot.isDraggingOver)
                                                        : getListStyleH2(snapshot.isDraggingOver)
                                                )
                                            }>
                                            {this.state.selected.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            onClick={() => this.onClick2to1(index)}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={w<s
                                                                ? getItemStyleV(snapshot.isDragging,provided.draggableProps.style)
                                                                : getItemStyleH(snapshot.isDragging,provided.draggableProps.style)
                                                            }>
                                                            {item.content}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Row>
                        </div>
                    </DragDropContext>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
        selected: state.colorsSelected,
        selectedZ: state.colorsSelectedZ,
        rtl: state.rtl,
        lang: state.lang,
        genderChange: state.genderChange,
        PSYInfo: state.PSYInfo,
        setLT: state.setLT,
    
    }
}
export default connect (mapStateToProps)(ColorsList);
