import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../public/css/colorLoading.css';
import srcSet from '../srcSet';

var s = srcSet.s

class ColorLoading extends Component {

  state = {}

  componentDidMount() {  }

  render() {
    const {} = this.state
    const {} = this.props

    return (
      <div className='d-flex colorLoading' style={{alignItems:'center'}}>
        <p className='sub'>l</p>
        <p className='sub'>o</p>
        <p className='sub'>a</p>
        <p className='sub'>d</p>
        <p className='sub'>i</p>
        <p className='sub'>n</p>
        <p className='sub'>g</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rtl: state.rtl,
    lang: state.lang,
    auth: state.auth,
  }
}

export default connect (mapStateToProps)(ColorLoading);

