import React from 'react'; 
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './dataStore/reducers';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.css';
import './index.css';
import './public/css/loader.scss';
import './public/css/vazir.css';
import { loadState, saveState } from './localStorage';
import HttpsRedirect from 'react-https-redirect';
import { HashRouter } from 'react-router-dom'

import App from './App';
import registerServiceWorker from './registerServiceWorker';
const persistedState = loadState();
const store = createStore(reducers, persistedState)

store.subscribe(() => {
    saveState(store.getState());
})

ReactDOM.render(
    <Provider store = {store}>
        <App/>
    </Provider> , document.getElementById('root'));

registerServiceWorker();

const storeX = store.getState();
//const msg = state.msgDraft;
export default storeX;
//console.log(2222, msg)

    // <Provider store = {store}>
    //     <HttpsRedirect>
    //         <HashRouter>
    //             <App/>
    //         </HashRouter>
    //     </HttpsRedirect>
    // </Provider> , document.getElementById('root'));
