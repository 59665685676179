import React, { Component } from 'react';
import axios from 'axios';
import Chart from 'chart.js';
import { Card, CardHeader, CardBody } from "mdbreact";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../public/css/SelectColor.css';
import maleFemale from '../public/images/background/chSpNull0.png';
import male from '../public/images/background/chSpNullMale.png';
import female from '../public/images/background/chSpNullFemale.png';
import { PSYInfo, cardChange, card, chSp, subChSp, totalDataChart, subTotalDataChart, 
  colorsSelectedZ, colorsSelected, cheeks, chin, ears, eyebrows, eyeColor, eyes, faceShape, forehead, lips, nose, bloodType, bodyShape,
  abjadDataChart, astrologyDataChart, colorsDataChart, cheeksDataChart, chinDataChart, earsDataChart, eyebrowsDataChart, eyeColorDataChart,
  eyesDataChart, faceShapeDataChart, foreheadDataChart, lipsDataChart, noseDataChart, bloodTypeDataChart, bodyShapeDataChart,
 } from '../dataStore/actions';

import { Link } from "react-router-dom";
import srcSet from '../srcSet';

var s = srcSet.s
var cardBackColor1 = srcSet.psyCard.cardBackColor1
var cardBackColor2 = srcSet.psyCard.cardBackColor2
var headerColor1 = srcSet.headerColor1
var headerColor2 = srcSet.headerColor2

class CardFooter extends Component {

  state = {
    w: window.innerWidth,
  }

  async componentDidMount() {
    // console.log(this.props.page)
    await this.cardSelect()
    await this.doughnutChart()
  }

  doughnutChart = async () => {
    const { rtl, lang } = this.props;
    const { sCount, nsCount } = this.props.PSYInfo.card;
      document.getElementById("doughnutChartContainer").innerHTML = '&nbsp;';
      document.getElementById("doughnutChartContainer").innerHTML = '<canvas id="doughnutChart"></canvas>';
      var ctx = document.getElementById("doughnutChart").getContext("2d");

      var xSize = 60
      ctx.canvas.width = xSize;
      ctx.canvas.height = xSize;

      new Chart(ctx, {
        type: 'doughnut',
        data : {
          datasets: [{
              backgroundColor : ['#3bf503', '#f3f3f300'],
              data: [sCount, nsCount]
          }]
        },
        options: {
          tooltips: {
            enabled: false
          },
          cutoutPercentage: 70,
          chartArea: {
            // backgroundColor: 'transparent'
          },
          responsive:false,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                //fontSize: 7,
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                display: false,
                color: "#ffffff",
                lineWidth:2
              }
            }],
            yAxes: [{
              display: false,
              position: rtl? 'right' : 'left', 
              ticks: {
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                color: "#ffffff",
                lineWidth:1,
                zeroLineColor: '#616161'
              }
            }]
          }
        }
      })  

  }

  cardSelect = async () => {
    var PSYInfoX = this.props.PSYInfo
    var card = PSYInfoX.card
    var sCount = 
        card.abjad +
        card.astrology +
        card.colors + 
        card.faceShape +
        card.lips +
        card.ears +
        card.eyes +
        card.eyeColor +
        card.cheeks +
        card.chin +
        card.forehead +
        card.eyebrows +
        card.nose +
        card.bodyShape +
        card.bloodType
    var nsCount = 15-sCount
    card.sCount=sCount
    card.nsCount=nsCount
    await this.props.dispatch(PSYInfo(PSYInfoX))
  }

  updateCardSelected = async () => {
    await this.props.dispatch(cardChange(false))
    this.componentDidMount()
  }

  deleteAll = async () => {
    var PSYInfoX = this.props.PSYInfo
    const info = {
      fakeId: PSYInfoX.fakeId,
      genderValue: '',
      card: {
        abjad : 0,
        astrology : 0,
        colors : 0,
        cheeks : 0,
        chin : 0,
        ears : 0,
        eyebrows : 0,
        eyeColor : 0,
        eyes : 0,
        faceShape : 0,
        forehead : 0,
        lips : 0,
        nose : 0,
        bloodType : 0,
        bodyShape : 0,
        sCount : 0,
        nsCount : 0,
      },
      abjad: {
          commonName:'',
          motherName:''
      },
      astrology: {
          y: {value: '', label: ''},
          m: {value: '', label: ''},
          d: {value: '', label: ''},
          mx: ''
      },
      colors: [],
      faceShape: [],
      lips: [],
      ears: [],
      eyes: [],
      eyeColor: [],
      cheeks: [],
      chin: [],
      forehead: [],
      eyebrows: [],
      nose: [],
      bodyShape: [],
      bloodType: []
    }

    PSYInfoX = info

    this.props.dispatch(PSYInfo(info))
    // console.log(this.props.PSYInfo)

    this.props.dispatch(colorsSelected([]))
    this.props.dispatch(colorsSelectedZ([]))
    this.props.dispatch(cheeks([]))
    this.props.dispatch(chin([]))
    this.props.dispatch(ears([]))
    this.props.dispatch(eyebrows([]))
    this.props.dispatch(eyeColor([]))
    this.props.dispatch(eyes([]))
    this.props.dispatch(faceShape([]))
    this.props.dispatch(forehead([]))
    this.props.dispatch(lips([]))
    this.props.dispatch(nose([]))
    this.props.dispatch(bloodType([]))
    this.props.dispatch(bodyShape([]))
    this.props.dispatch(bodyShape([]))

    this.props.dispatch(abjadDataChart([]))
    this.props.dispatch(astrologyDataChart([]))
    this.props.dispatch(colorsDataChart([]))
    this.props.dispatch(cheeksDataChart([]))
    this.props.dispatch(chinDataChart([]))
    this.props.dispatch(earsDataChart([]))
    this.props.dispatch(eyebrowsDataChart([]))
    this.props.dispatch(eyeColorDataChart([]))
    this.props.dispatch(eyesDataChart([]))
    this.props.dispatch(faceShapeDataChart([]))
    this.props.dispatch(foreheadDataChart([]))
    this.props.dispatch(lipsDataChart([]))
    this.props.dispatch(noseDataChart([]))
    this.props.dispatch(bloodTypeDataChart([]))
    this.props.dispatch(bodyShapeDataChart([]))
    this.props.dispatch(bodyShapeDataChart([]))

    this.props.dispatch(chSp([]))

    window.location.href=`/${this.props.lang}`
  }

  deleteOption = () => {
    this.setState({
      deleteOption: !this.state.deleteOption
    })
  }

  render() {
    const { w, deleteOption, restartbtn } = this.state
    const {rtl, lang, auth, page, setLT, PSYInfo, bigImage , siteType} = this.props
    const {abjad, astrology, colors, faceShape, lips,
      ears, eyes, eyeColor, cheeks, chin, forehead,
      eyebrows, nose, bodyShape, bloodType, sCount, nsCount} = this.props.PSYInfo.card
    const temColor = siteType===1 ? headerColor1 : headerColor2

    if(this.props.cardChange) {this.updateCardSelected()}
    this.cardSelect()
    // console.log(55, this.props.card)
    const cardStyle = {width:'65px' , height:'25px', borderRadius:'3px', margin:'0px', 
            boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3)' }

    const dot = (
      <div className='center' style={{}}>
          <div className='' style={{width:'5px', height:'5px', backgroundColor:'#3bf503', borderRadius:'100px', margin:'-7px 0px 0px', transition:'1s'}}>
          </div>
      </div>
    )
        
    const abjadGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='abjad' && dot}
        <Link to={`/${lang}/abjad`} className='d-flex' style={{borderRadius:'5px', backgroundColor:abjad===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/cosmicNumbers/abjad/0.jpg`)}
          style={cardStyle} alt="abjad"
          />
        </Link>
      </div>
    )
    const astrologyGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='astrology' && dot}
        <Link to={`/${lang}/astrology`} className='d-flex' style={{borderRadius:'5px', backgroundColor:astrology===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/cosmicNumbers/astrology/0.jpg`)}
          style={cardStyle} alt="astrology"
          />
        </Link>
      </div>
    )
    const colorsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='colors' && dot}
        <Link to={`/${lang}/colors`} className='d-flex' style={{borderRadius:'5px', backgroundColor:colors===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/colorPsychology/colors/0.jpg`)}
              style={cardStyle} alt="colors"
          />
        </Link>
      </div>
    )
    const faceShapeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='face-shape' && dot}
        <Link to={`/${lang}/face-shape`} className='d-flex' style={{borderRadius:'5px', backgroundColor:faceShape===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/faceShape/0.jpg`)}
              style={cardStyle} alt="face shape"
          />
        </Link>
      </div>
    )
    const cheeksGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='cheeks' && dot}
        <Link to={`/${lang}/cheeks`} className='d-flex' style={{borderRadius:'5px', backgroundColor:cheeks===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/cheeks/0.jpg`)}
          style={cardStyle} alt="cheeks"
          />
        </Link>
      </div>
    )
    const earsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='ears' && dot}
        <Link to={`/${lang}/ears`} className='d-flex' style={{borderRadius:'5px', backgroundColor:ears===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/ears/0.jpg`)}
          style={cardStyle} alt="ears"
          />
        </Link>
      </div>
    )
    const lipsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='lips' && dot}
        <Link to={`/${lang}/lips`} className='d-flex' style={{borderRadius:'5px', backgroundColor:lips===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/lips/0.jpg`)}
          style={cardStyle} alt="lips"
          />
        </Link>
      </div>
    )
    const chinGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='chin' && dot}
        <Link to={`/${lang}/chin`} className='d-flex' style={{borderRadius:'5px', backgroundColor:chin===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/chin/0.jpg`)}
          style={cardStyle} alt="chin"
          />
        </Link>
      </div>
    )
    const foreheadGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='forehead' && dot}
        <Link to={`/${lang}/forehead`} className='d-flex' style={{borderRadius:'5px', backgroundColor:forehead===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/forehead/0.jpg`)}
          style={cardStyle} alt="forehead"
          />
        </Link>
      </div>
    )
    const eyebrowsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='eyebrows' && dot}
        <Link to={`/${lang}/eyebrows`} className='d-flex' style={{borderRadius:'5px', backgroundColor:eyebrows===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/eyebrows/0.jpg`)}
          style={cardStyle} alt="eyebrows"
          />
        </Link>
      </div>
    )
    const eyesGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='eyes' && dot}
        <Link to={`/${lang}/eyes`} className='d-flex' style={{borderRadius:'5px', backgroundColor:eyes===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/eyes/0.jpg`)}
          style={cardStyle} alt="eyes"
          />
        </Link>
      </div>
    )
    const eyeColorGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='eye-color' && dot}
        <Link to={`/${lang}/eye-color`} className='d-flex' style={{borderRadius:'5px', backgroundColor:eyeColor===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/eyeColor/0.jpg`)}
          style={cardStyle} alt="eye color"
          />
        </Link>
      </div>
    )
    const noseGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='nose' && dot}
        <Link to={`/${lang}/nose`} className='d-flex' style={{borderRadius:'5px', backgroundColor:nose===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/faceReading/nose/0.jpg`)}
          style={cardStyle} alt="nose"
          />
        </Link>
      </div>
    )
    const bodyShapeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='body-shape' && dot}
        <Link to={`/${lang}/body-shape`} className='d-flex' style={{borderRadius:'5px', backgroundColor:bodyShape===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/others/bodyShape/0.jpg`)}
          style={cardStyle} alt="body shape"
          />
        </Link>
      </div>
    )

    const bloodTypeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        {page==='blood-type' && dot}
        <Link to={`/${lang}/blood-type`} className='d-flex' style={{borderRadius:'5px', backgroundColor:bloodType===1 ? '#3bf503' : '', padding:'5px', margin:'0px 5px'}}>
          <img src={require(`../public/images/character/others/bloodType/0.jpg`)}
            style={cardStyle} alt="blood type"
          />
        </Link>
      </div>
    )
// console.log(sCount)

    const deleteAllBtn = (
      <div className='center' style={{}}>
        <div className={`d-flex justify-content-center disable-select`}
            style={{width: '300px', height: '32px', //whiteSpace:'nowrap',
                    alignItems:'center',
                    margin: '0px 10px',
                    border: '3px solid #ff0000',
                    borderRadius:'100px',
                    padding: '2px 10px',
                    color: '#ff0000',
                    backgroundColor: '#ffffff',
                    //visibility: !resultX ? 'hidden' : 'visible',// onClick = {() => this.deleteAll()}>
                    //opacity: !resultX ? '0' : '1',
                    transition:'.5s',
                    curser:'pointer'
                  }}
            onClick = {() => this.deleteOption()}>
            {!deleteOption && <span style={{fontSize:'15px', fontWeight:'bold', marginTop:'5px', zIndex:'1'}}>{setLT.deleteAllSelections}</span>}
            {deleteOption &&
                <div className='d-flex' style={{alignItems:'center'}}>
                    <span style={{fontSize:'15px', fontWeight:'bold', marginTop:'5px', zIndex:'1'}}>{setLT.delete}{rtl ? '؟' : '?'}</span>&nbsp;&nbsp;&nbsp;
                    <div className='center'>
                        <Button variant="primary" onClick={this.deleteOption}
                            style={{width:'70px', height:'20px', lineHeight:'0px'}}>
                            <span style={{fontSize:'13px'}}>{setLT.no}</span>
                        </Button>
                        <Button variant="danger" onClick={this.deleteAll}
                            style={{width:'70px', height:'20px', lineHeight:'0px'}}>
                            <span style={{fontSize:'13px'}}>{setLT.yes}</span>
                        </Button>
                    </div>
                </div>
            }
        </div>
      </div>
    )

    const analysis = (
      <div className='d-flex'
          style={{flexDirection:'column', margin:'0px 15px', alignItems:'center'}}>
          <Link to={`/${lang}/character-analysis`} className='analysis'
            style={{textDecoration:'none', width:'35px', height:'35px', backgroundColor: '#ffffff', border: '2px solid #ffd400', flexDirection:'column', borderRadius:'5px'}}
          >
          </Link>
      </div>
    )

    const unCompletedCards = (
      <div className='d-flex' style={{fontSize:'14px', width:'calc(100% - 0px)', alignItems:'center', marginTop:'0px', overflow:'scroll'}}>
        <div className='d-flex' style={{height:w>s ? '105px' : '50px', alignItems:'center', flexWrap:w>s ? 'wrap' : ''}}>
          {faceShapeGoto}
          {cheeksGoto}
          {earsGoto}
          {lipsGoto}
          {chinGoto}
          {foreheadGoto}
          {eyebrowsGoto}
          {eyesGoto}
          {eyeColorGoto}
          {noseGoto}
          {colorsGoto}
          {abjadGoto}
          {astrologyGoto}
          {bodyShapeGoto}
          {bloodTypeGoto}
          {analysis}
          {deleteAllBtn}
        </div>
      </div>
    )

    const nCard = sCount/(sCount+nsCount)*100
    const chartx = (
      <div style={{ backgroundColor:'#ffffff00', width:'60px', height:'60px'}}>
        <div className="canvas" id="doughnutChartContainer" style={{backgroundColor: '#ffffff00'}}>
          <canvas id="doughnutChart"></canvas>
        </div>
      </div>
    )

    const analysisPercentage = (
      <Link to={`/${lang}/character-analysis`} style={{marginTop:w>s ? '5px' : '5px'}}>
        <div className='center' style={{margin:'21px 10px -37px', fontWeight:'bold', fontSize:'13px', width:'', height:'', backgroundColor:'#ffffff00', borderRadius:'100px', border:'0px solid #3bf503', alignItems:'center'}}>
          <span style={{color:'#ffffff'}}>{nCard.toFixed(0)}%</span>
        </div>
        {chartx}
      </Link>
    )

    return (
      <div className='center'
        style={{alignItems:'center', width:'100%', fontSize:'15px', backgroundColor:'#00194c', margin:'0px', padding:'0px', borderRadius:'0px'}}>
        {analysisPercentage}
        {unCompletedCards}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo['_id'],
    rtl: state.rtl,
    lang: state.lang,
    page: state.page,
    card: state.card,
    auth: state.auth,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    cardChange: state.cardChange,
    bigImage: state.bigImage,
    siteType: state.siteType,

  }
}

export default connect (mapStateToProps)(CardFooter);

