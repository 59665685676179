import React, { Component } from 'react';
import axios from 'axios';
import Chart from 'chart.js';
import { Card, CardHeader, CardBody } from "mdbreact";
import { connect } from 'react-redux';
import '../public/css/SelectColor.css';
import maleFemale from '../public/images/background/chSpNull0.png';
import male from '../public/images/background/chSpNullMale.png';
import female from '../public/images/background/chSpNullFemale.png';
import { PSYInfo, cardChange } from '../dataStore/actions'; 
import { Link } from "react-router-dom";
import srcSet from '../srcSet';

var s = srcSet.s
var cardBackColor1 = srcSet.psyCard.cardBackColor1
var cardBackColor2 = srcSet.psyCard.cardBackColor2

class CardCompelete extends Component {

  state = {
    w: window.innerWidth,
  }

  async componentDidMount() {
    // console.log(this.props.page)
    await this.cardSelect()
    await this.doughnutChart()
  }

  doughnutChart = async () => {
    const { rtl, lang } = this.props;
    const { sCount, nsCount } = this.props.PSYInfo.card;
      document.getElementById("doughnutChartContainer").innerHTML = '&nbsp;';
      document.getElementById("doughnutChartContainer").innerHTML = '<canvas id="doughnutChart"></canvas>';
      var ctx = document.getElementById("doughnutChart").getContext("2d");

      var xSize = 120
      ctx.canvas.width = xSize;
      ctx.canvas.height = xSize;

      new Chart(ctx, {
        type: 'doughnut',
        data : {
          datasets: [{
              backgroundColor : ['#3bf503', '#f3f3f300'],
              data: [sCount, nsCount]
          }]
        },
        options: {
          tooltips: {
            enabled: false
          },
          cutoutPercentage: 70,
          chartArea: {
            // backgroundColor: 'transparent'
          },
          responsive:true,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                //fontSize: 7,
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                display: false,
                color: "#ffffff",
                lineWidth:2
              }
            }],
            yAxes: [{
              display: false,
              position: rtl? 'right' : 'left', 
              ticks: {
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                color: "#ffffff",
                lineWidth:1,
                zeroLineColor: '#616161'
              }
            }]
          }
        }
      })  

  }

  cardSelect = async () => {
    var PSYInfoX = this.props.PSYInfo
    var card = PSYInfoX.card
    var sCount = 
        card.abjad +
        card.astrology +
        card.colors + 
        card.faceShape +
        card.lips +
        card.ears +
        card.eyes +
        card.eyeColor +
        card.cheeks +
        card.chin +
        card.forehead +
        card.eyebrows +
        card.nose +
        card.bodyShape +
        card.bloodType
    var nsCount = 15-sCount
    card.sCount=sCount
    card.nsCount=nsCount
    await this.props.dispatch(PSYInfo(PSYInfoX))
  }

  updateCardSelected = async () => {
    await this.props.dispatch(cardChange(false))
    this.componentDidMount()
  }

  render() {
    const { w, cardCompelete } = this.state
    const {rtl, lang, auth, page, setLT, PSYInfo, bigImage} = this.props
    const {abjad, astrology, colors, faceShape, lips,
      ears, eyes, eyeColor, cheeks, chin, forehead,
      eyebrows, nose, bodyShape, bloodType, sCount, nsCount} = this.props.PSYInfo.card

    if(this.props.cardChange) {this.updateCardSelected()}
    this.cardSelect()
    // console.log(55, this.props.card)
    const cardStyle = {width:'75px' , height:'30px', borderRadius:'5px', margin:'5px 8px', 
            boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3)' }

    const abjadGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/abjad`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/cosmicNumbers/abjad/0.jpg`)}
          style={cardStyle} alt="abjad"
          />
        </Link>
      </div>
    )
    const astrologyGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/astrology`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/cosmicNumbers/astrology/0.jpg`)}
          style={cardStyle} alt="astrology"
          />
        </Link>
      </div>
    )
    const colorsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/colors`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/colorPsychology/colors/0.jpg`)}
          style={cardStyle} alt="colors"
          />
        </Link>
      </div>
    )
    const faceShapeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/face-shape`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/faceShape/0.jpg`)}
          style={cardStyle} alt="face shape"
          />
        </Link>
      </div>
    )
    const cheeksGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/cheeks`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/cheeks/0.jpg`)}
          style={cardStyle} alt="cheeks"
          />
        </Link>
      </div>
    )
    const earsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/ears`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/ears/0.jpg`)}
          style={cardStyle} alt="ears"
          />
        </Link>
      </div>
    )
    const lipsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/lips`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/lips/0.jpg`)}
          style={cardStyle} alt="lips"
          />
        </Link>
      </div>
    )
    const chinGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/chin`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/chin/0.jpg`)}
          style={cardStyle} alt="chin"
          />
        </Link>
      </div>
    )
    const foreheadGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/forehead`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/forehead/0.jpg`)}
          style={cardStyle} alt="forehead"
          />
        </Link>
      </div>
    )
    const eyebrowsGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/eyebrows`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/eyebrows/0.jpg`)}
          style={cardStyle} alt="eyebrows"
          />
        </Link>
      </div>
    )
    const eyesGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/eyes`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/eyes/0.jpg`)}
          style={cardStyle} alt="eyes"
          />
        </Link>
      </div>
    )
    const eyeColorGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/eye-color`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/eyeColor/0.jpg`)}
          style={cardStyle} alt="eye color"
          />
        </Link>
      </div>
    )
    const noseGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/nose`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/faceReading/nose/0.jpg`)}
          style={cardStyle} alt="nose"
          />
        </Link>
      </div>
    )
    const bodyShapeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/body-shape`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/others/bodyShape/0.jpg`)}
          style={cardStyle} alt="body shape"
          />
        </Link>
      </div>
    )
    const bloodTypeGoto = (
      <div onClick={() => window.scrollTo(0, 0)}>
        <Link to={`/${lang}/blood-type`} className='d-flex' style={{marginBottom:'5px', color:'#000000'}}>
          <img src={require(`../public/images/character/others/bloodType/0.jpg`)}
            style={cardStyle} alt="blood type"
          />
        </Link>
      </div>
    )
// console.log(sCount)
    const nCard = sCount/(sCount+nsCount)*100
    var c1 = require(`../public/images/carousel/c1.jpg`)
    const characterAnalysisCards = (
      <Link to={`/${lang}`} style={{textDecoration:'none'}} onClick={() => page==='character-card' ? window.scrollTo(0, 0) : ''}>
        <Card className='btnShadowX' style={{ backgroundImage: `linear-gradient(to right , ${cardBackColor1} , ${cardBackColor2})`, margin:'20px 10px', border: '2px solid #FEED97', width: '250px', height:'199px'}}>
          <CardHeader className="white-text header tx" style={{fontWeight: 450, width:'100%', fontSize:'17px'}}>{setLT.characterAnalysisCards}</CardHeader>
          <CardBody style={{padding:'0px 10px 10px'}}>
            <img src={bigImage.psyCarousel1 ? bigImage.psyCarousel1 : c1} className='img-fluid' alt="character psychology"/>
          </CardBody>
        </Card>
      </Link>
    )

    const chartx = (
      <div style={{ backgroundColor:'#ffffff00'}}>
        <div className="canvas" id="doughnutChartContainer" style={{backgroundColor: '#ffffff00'}}>
          <canvas id="doughnutChart"></canvas>
        </div>
      </div>
    )

    const analysisPercentage = (
      <div style={{}}>
        <div className='center tx' style={{margin:'10px 10px -105px', fontWeight:'bold', fontSize:'20px', width:'100px', height:'100px', backgroundColor:'#ffffff00', borderRadius:'100px', border:'0px solid #3bf503', alignItems:'center'}}>
          <span style={{color:'#ffffff'}}>{nCard.toFixed(0)}%</span>
        </div>
        {chartx}
      </div>
    )

    const chImg = (
      PSYInfo.genderValue===''
      ? maleFemale
      : PSYInfo.genderValue===1
        ? male
        : female
    )
    
    const characterAnalysis = (
      <Link to={`/${lang}/character-analysis`} style={{textDecoration:'none'}} onClick={() => page==='character-analysis' ? window.scrollTo(0, 0) : ''}>
        <Card className='btnShadowX' style={{ backgroundImage: `linear-gradient(to right , ${cardBackColor1} , ${cardBackColor2})`, margin:'20px 10px', border: '2px solid #FEED97', width: '250px', height:'199px'}}>
          <CardHeader className="white-text header tx" style={{fontWeight: 450, width:'100%', fontSize:'17px'}}>{setLT.characterAnalysis}</CardHeader>
          <CardBody className='center' style={{padding:'10px', justifyContent:'', alignItems:'center', direction:'ltr'}}>
            {analysisPercentage}
          </CardBody>
        </Card>
      </Link>
    )

    const unCompletedCards = (
      <div style={{width:w<s ? '300px' : '40%', textAlign: 'center', margin:'10px'}}>
        <div className='tx' style={{margin:'0px 0px 10px', fontWeight:''}}>{nsCount===0 ? setLT.cardCompeleted : setLT.cardUncompeleted}</div>
        <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
          {abjad===0 && abjadGoto}
          {astrology===0 && astrologyGoto}
          {colors===0 && colorsGoto}
          {faceShape===0 && faceShapeGoto}
          {cheeks===0 && cheeksGoto}
          {ears===0 && earsGoto}
          {lips===0 && lipsGoto}
          {chin===0 && chinGoto}
          {forehead===0 && foreheadGoto}
          {eyebrows===0 && eyebrowsGoto}
          {eyes===0 && eyesGoto}
          {eyeColor===0 && eyeColorGoto}
          {nose===0 && noseGoto}
          {bodyShape===0 && bodyShapeGoto}
          {bloodType===0 && bloodTypeGoto}
        </div>
      </div>
    )

    return (
      <div className='d-flex'
        style={{flexWrap:'wrap', alignItems:'center', justifyContent:'center', width:'100%', fontSize:'15px', display : ((nsCount!==0 && nsCount!==undefined) && auth) ? 'block' : 'none', backgroundColor:'#ffffff99', margin:'50px 5px 10px', padding:'10px', borderRadius:'5px'}}>
        {characterAnalysis}
        {unCompletedCards}
        {characterAnalysisCards}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo['_id'],
    rtl: state.rtl,
    lang: state.lang,
    page: state.page,
    card: state.card,
    auth: state.auth,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    cardChange: state.cardChange,
    bigImage: state.bigImage,

  }
}

export default connect (mapStateToProps)(CardCompelete);

