import langText from '../langText/text';

const setLangText = (x) => {

  var data = {
    searchPlaceholder: langText.searchPlaceholder[x],
    search: langText.search[x],
    categoryPlaceholder: langText.categoryPlaceholder[x],
    colorControlText: langText.colorControlText[x],
    businessListTitle: langText.businessListTitle[x],
    productListTitle: langText.productListTitle[x],
    otherProductListTitle: langText.otherProductListTitle[x],
    otherProductInOtherColorsListTitle: langText.otherProductInOtherColorsListTitle[x],
    searchNA: langText.searchNA[x],
    businessMembers: langText.businessMembers[x],
    AnnualBusinessMembershipFee: langText.AnnualBusinessMembershipFee[x],
    AnnualCostSection: langText.AnnualCostSection[x],
    costStar: langText.costStar[x],
    returnStar: langText.returnStar[x],
    maxAdsStar: langText.maxAdsStar[x],
    validity: langText.validity[x],
    day: langText.day[x],
    rubyIncreaseLink: langText.rubyIncreaseLink[x],
    followInstagram: langText.followInstagram[x],
    shareWhoralyText: langText.shareWhoralyText[x],
    shareWhoralyTextPSY: langText.shareWhoralyTextPSY[x],
    copyWhoralyLinkText: langText.copyWhoralyLinkText[x],
    copyLink: langText.copyLink[x],
    consumerSpecificationsTitle: langText.consumerSpecificationsTitle[x],
    category: langText.category[x],
    ages: langText.ages[x],
    gender: langText.gender[x],
    all: langText.all[x],
    adult: langText.adult[x],
    child: langText.child[x],
    male: langText.male[x],
    female: langText.female[x],
    digitalProductsAccessories: langText.digitalProductsAccessories[x],
    cosmeticsHealthWellness: langText.cosmeticsHealthWellness[x],
    goldJewelryOrnaments: langText.goldJewelryOrnaments[x],
    artHandicraft: langText.artHandicraft[x],
    clothing: langText.clothing[x],
    bagsShoesAccessories: langText.bagsShoesAccessories[x],
    foodStuffs: langText.foodStuffs[x],
    gameEntertainment: langText.gameEntertainment[x],
    booksStationery: langText.booksStationery[x],
    sportsRecreationalEquipment: langText.sportsRecreationalEquipment[x],
    homeKitchenAppliances: langText.homeKitchenAppliances[x],
    officeSupplies: langText.officeSupplies[x],
    buildingMaterials: langText.buildingMaterials[x],
    industrialToolsEquipment: langText.industrialToolsEquipment[x],
    carSparePartsAccessories: langText.carSparePartsAccessories[x],
    petsAccessories: langText.petsAccessories[x],
    trainingServices: langText.trainingServices[x],
    other: langText.other[x],
    approve: langText.approve[x],
    forMen: langText.forMen[x],
    forWomen: langText.forWomen[x],
    profile: langText.profile[x],
    profileInfoShort: langText.profileInfoShort[x],
    profileInfoLong: langText.profileInfoLong[x],
    login: langText.login[x],
    loginInfoShort: langText.loginInfoShort[x],
    loginInfoLong: langText.loginInfoLong[x],
    loginInfoText: langText.loginInfoText[x],
    ProductsServices: langText.ProductsServices[x],
    otherProductsServices: langText.otherProductsServices[x],
    ProductsServicesText: langText.ProductsServicesText[x],
    new: langText.new[x],
    ads: langText.ads[x],
    newAds: langText.newAds[x],
    editAds: langText.editAds[x],
    deleteAds: langText.deleteAds[x],
    phoneNumber: langText.phoneNumber[x],
    mobilePhoneNumber: langText.mobilePhoneNumber[x],
    whatsapp: langText.whatsapp[x],
    facebook: langText.facebook[x],
    youtube: langText.youtube[x],
    linkedin: langText.linkedin[x],
    website: langText.website[x],
    email: langText.email[x],
    instagram: langText.instagram[x],
    instagramPlaceHolder: langText.instagramPlaceHolder[x],
    instagramText: langText.instagramText[x],
    telegram: langText.telegram[x],
    telegramPlaceHolder: langText.telegramPlaceHolder[x],
    telegramText: langText.telegramText[x],
    save: langText.save[x],
    edit: langText.edit[x],
    delete: langText.delete[x],
    upload: langText.upload[x],
    deleteProfilePhoto: langText.deleteProfilePhoto[x],
    username: langText.username[x],
    password: langText.password[x],
    approved: langText.approved[x],
    pending: langText.pending[x],
    reject: langText.reject[x],
    starAd: langText.starAd[x],
    totalRubies: langText.totalRubies[x],
    profileSaveInfoAlert: langText.profileSaveInfoAlert[x],
    profileSaveAlert: langText.profileSaveAlert[x],
    loginSaveAlert: langText.loginSaveAlert[x],
    AnnualCostLang: langText.AnnualCostLang[x],
    approvedAds: langText.approvedAds[x],
    profilePhoto: langText.profilePhoto[x],
    ruby: langText.ruby[x],
    buyRubyText: langText.buyRubyText[x],
    buyRubyLink: langText.buyRubyLink[x],
    prices: langText.prices[x],
    oneRubyPrice: langText.oneRubyPrice[x],
    buyDiscount: langText.buyDiscount[x],
    x55Discount: langText.x55Discount[x],
    x115Discount: langText.x115Discount[x],
    x360Discount: langText.x360Discount[x],
    x650Discount: langText.x650Discount[x],
    x1500Discount: langText.x1500Discount[x],
    x10Price: langText.x10Price[x],
    x55Price: langText.x55Price[x],
    x115Price: langText.x115Price[x],
    x360Price: langText.x360Price[x],
    x650Price: langText.x650Price[x],
    x1500Price: langText.x1500Price[x],
    likerList: langText.likerList[x],
    likers: langText.likers[x],
    people: langText.people[x],
    chatList: langText.chatList[x],
    chatPlaceHolder: langText.chatPlaceHolder[x],
    commentPlaceHolder: langText.commentPlaceHolder[x],
    block: langText.block[x],
    blockList: langText.blockList[x],
    unblock: langText.unblock[x],
    deleteChatText: langText.deleteChatText[x],
    blockText: langText.blockText[x],
    unknown: langText.unknown[x],
    deleteForMe: langText.deleteForMe[x],
    deleteForAll: langText.deleteForAll[x],
    searchMembers: langText.searchMembers[x],
    colorSelection: langText.colorSelection[x],
    colorSelectionText: langText.colorSelectionText[x],
    box1: langText.box1[x],
    box2: langText.box2[x],
    box1Title: langText.box1Title[x],
    box2Title: langText.box2Title[x],
    unselectColorsText: langText.unselectColorsText[x],
    completeColors: langText.completeColors[x],
    characterAnalysis: langText.characterAnalysis[x],
    characterAnalysisChart: langText.characterAnalysisChart[x],
    strengths: langText.strengths[x],
    weaknesses: langText.weaknesses[x],
    p0Title: langText.p0Title[x],
    p1Title: langText.p1Title[x],
    p2Title: langText.p2Title[x],
    p3Title: langText.p3Title[x],
    p4Title: langText.p4Title[x],
    p5Title: langText.p5Title[x],
    p6Title: langText.p6Title[x],
    chSpText1: langText.chSpText1[x],
    chSpText2: langText.chSpText2[x],
    chSpText3: langText.chSpText3[x],
    member: langText.member[x],
    members: langText.members[x],
    memberSim: langText.memberSim[x],
    memberSimText: langText.memberSimText[x],
    noMembers: langText.noMembers[x],
    similarityColorSelection: langText.similarityColorSelection[x],
    home: langText.home[x],
    dashboardPanel: langText.dashboardPanel[x],
    colorInterest: langText.colorInterest[x],
    memberComments: langText.memberComments[x],
    signupLogin: langText.signupLogin[x],
    signUp: langText.signUp[x],
    notMember: langText.notMember[x],
    isMember: langText.isMember[x],
    signUpForm: langText.signUpForm[x],
    loginForm: langText.loginForm[x],
    signedInUserErr: langText.signedInUserErr[x],
    userPassErr: langText.userPassErr[x],
    exit: langText.exit[x],
    scoring: langText.scoring[x],
    minute: langText.minute[x],
    hour: langText.hour[x],
    day: langText.day[x],
    month: langText.month[x],
    year: langText.year[x],
    ago: langText.ago[x],
    sendCommentTitle: langText.sendCommentTitle[x],
    send: langText.send[x],
    sendNewComment: langText.sendNewComment[x],
    commentSendSuccess: langText.commentSendSuccess[x],
    commentSendSuccessText: langText.commentSendSuccessText[x],
    chat: langText.chat[x],
    contactUs: langText.contactUs[x],
    contactMe: langText.contactMe[x],
    about: langText.about[x],
    aboutUs: langText.aboutUs[x],
    aboutMe: langText.aboutMe[x],
    productsServicesGallery: langText.productsServicesGallery[x],
    mustSignUp: langText.mustSignUp[x],
    freeSignUp: langText.freeSignUp[x],
    advertiserConnection: langText.advertiserConnection[x],
    more: langText.more[x],
    specializedApplications: langText.specializedApplications[x],
    eachMonth: langText.eachMonth[x],
    membershipType: langText.membershipType[x],
    person: langText.person[x],
    business: langText.business[x],
    favoriteColor: langText.favoriteColor[x],
    activeSection: langText.activeSection[x],
    adsFirstColor: langText.adsFirstColor[x],
    adsSecondColor: langText.adsSecondColor[x],
    adsThirdColor: langText.adsThirdColor[x],
    adsRelatedFirstColor: langText.adsRelatedFirstColor[x],
    adsOtherColor: langText.adsOtherColor[x],
    adsAllProducts: langText.adsAllProducts[x],
    adsAllBusinesses: langText.adsAllBusinesses[x],
    ownerTitle: langText.ownerTitle[x],
    globalMarketText: langText.globalMarketText[x],
    postNewAds: langText.postNewAds[x],
    adsTitle: langText.adsTitle[x],
    requestTitle: langText.requestTitle[x],
    requestStandard: langText.requestStandard[x],
    adsDescription: langText.adsDescription[x],
    activitySummary: langText.activitySummary[x],
    biography: langText.biography[x],
    address: langText.address[x],
    country: langText.country[x],
    city: langText.city[x],
    continues: langText.continues[x],
    formatErr: langText.formatErr[x],
    titleErr: langText.titleErr[x],
    requestTitleErr: langText.requestTitleErr[x],
    adsCommentErr: langText.adsCommentErr[x],
    userAds: langText.userAds[x],
    lastAds: langText.lastAds[x],
    starredAds: langText.starredAds[x],
    specialAds: langText.specialAds[x],
    specialAd: langText.specialAd[x],
    special: langText.special[x],
    fcErr: langText.fcErr[x],
    countryErr: langText.countryErr[x],
    usernameNullErr: langText.usernameNullErr[x],
    usernameCharErr: langText.usernameCharErr[x],
    passwordErr: langText.passwordErr[x],
    recaptchaErr: langText.recaptchaErr[x],
    whoralyMembersMap: langText.whoralyMembersMap[x],
    star: langText.star[x],
    siteMembers: langText.siteMembers[x],
    specialAdUpgrade: langText.specialAdUpgrade[x],
    specialAdCost1Star: langText.specialAdCost1Star[x],
    specialAdCost2Star: langText.specialAdCost2Star[x],
    specialAdCost3Star: langText.specialAdCost3Star[x],
    specialAd1StarText1: langText.specialAd1StarText1[x],
    specialAd2StarText1: langText.specialAd2StarText1[x],
    specialAd2StarText2: langText.specialAd2StarText2[x],
    specialAd3StarText1: langText.specialAd3StarText1[x],
    specialAd3StarText2: langText.specialAd3StarText2[x],
    supportContacts: langText.supportContacts[x],
    searchProductAndService: langText.searchProductAndService[x],
    unitPriceErr: langText.unitPriceErr[x],
    maxUnitPriceErr: langText.maxUnitPriceErr[x],
    unitMeasurementErr: langText.unitMeasurementErr[x],
    unitPrice: langText.unitPrice[x],
    maxUnitPrice: langText.maxUnitPrice[x],
    unitMeasurement: langText.unitMeasurement[x],
    oneStarAd: langText.oneStarAd[x],
    twoStarAd: langText.twoStarAd[x],
    threeStarAd: langText.threeStarAd[x],
    tradeRequest: langText.tradeRequest[x],
    otherDescription: langText.otherDescription[x],
    contactInfo: langText.contactInfo[x],
    priceInfo: langText.priceInfo[x],
    negotiable: langText.negotiable[x],
    adsInfo: langText.adsInfo[x],
    price: langText.price[x],
    purchaseRequest: langText.purchaseRequest[x],
    newRequest: langText.newRequest[x],
    postNewRequest: langText.postNewRequest[x],
    userRequest: langText.userRequest[x],
    requestInfo: langText.requestInfo[x],
    editRequest: langText.editRequest[x],
    deleteRequest: langText.deleteRequest[x],
    lastRequest: langText.lastRequest[x],
    categories: langText.categories[x],
    businessMemberCategory: langText.businessMemberCategory[x],
    services: langText.services[x],
    servicesList: langText.servicesList[x],
    clearanceCustoms: langText.clearanceCustoms[x],
    forwarding: langText.forwarding[x],
    insuranceOffices: langText.insuranceOffices[x],
    embassies: langText.embassies[x],
    commercialLegalLawyers: langText.commercialLegalLawyers[x],
    translators: langText.translators[x],
    websiteDesignerLogo: langText.websiteDesignerLogo[x],
    rules: langText.rules[x],
    aboutUs: langText.aboutUs[x],
    aboutUsT1: langText.aboutUsT1[x],
    aboutUsT2: langText.aboutUsT2[x],
    aboutUsT3: langText.aboutUsT3[x],
    ruleT1: langText.ruleT1[x],
    ruleT2: langText.ruleT2[x],
    ruleT3: langText.ruleT3[x],
    ruleT4: langText.ruleT4[x],
    ruleT5: langText.ruleT5[x],
    ruleT6: langText.ruleT6[x],
    requestT1: langText.requestT1[x],
    requestT2: langText.requestT2[x],
    requesterConnection: langText.requesterConnection[x],
    adRegistration: langText.adRegistration[x],
    businessAds: langText.businessAds[x],
    adsT1: langText.adsT1[x],
    adsT2: langText.adsT2[x],
    purchaseRequests: langText.purchaseRequests[x],
    activeCountries: langText.activeCountries[x],
    intTitle1: langText.intTitle1[x],
    intTitle2: langText.intTitle2[x],
    intTitle3: langText.intTitle3[x],
    intDescription1: langText.intDescription1[x],
    intDescription2: langText.intDescription2[x],
    intDescription3: langText.intDescription3[x],
    store: langText.store[x],
    exStore: langText.exStore[x],
    quranIstikhara: langText.quranIstikhara[x],
    userPanel: langText.userPanel[x],
    userLocation: langText.userLocation[x],
    locationT1: langText.locationT1[x],
    currentLocation: langText.currentLocation[x],
    latitude: langText.latitude[x],
    longitude: langText.longitude[x],
    testLocation: langText.testLocation[x],
    latErr: langText.latErr[x],
    lonErr: langText.lonErr[x],
    locationSaveAlert: langText.locationSaveAlert[x],
    locationPhoto: langText.locationPhoto[x],
    aboutUsPhoto: langText.aboutUsPhoto[x],
    uploadComment: langText.uploadComment[x],
    selectImgErr: langText.selectImgErr[x],
    formatImgErr: langText.formatImgErr[x],
    saveImageAlert: langText.saveImageAlert[x],
    deleteLocation: langText.deleteLocation[x],
    deleteLocationImage: langText.deleteLocationImage[x],
    deleteAboutUsImage: langText.deleteAboutUsImage[x],
    yes: langText.yes[x],
    no: langText.no[x],
    googleMapAddress: langText.googleMapAddress[x],
    BCR: langText.BCR[x],
    BCRText1: langText.BCRText1[x],
    characterAnalysisCards: langText.characterAnalysisCards[x],
    shTab: langText.shTab[x],
    cosmicNumbers: langText.cosmicNumbers[x],
    colorsPsychology: langText.colorsPsychology[x],
    facialPsychology: langText.facialPsychology[x],
    abjad: langText.abjad[x],
    astrology: langText.astrology[x],
    colors: langText.colors[x],
    faceShape: langText.faceShape[x],
    ears: langText.ears[x],
    lips: langText.lips[x],
    chin: langText.chin[x],
    forehead: langText.forehead[x],
    eyebrows: langText.eyebrows[x],
    eyes: langText.eyes[x],
    eyeColor: langText.eyeColor[x],
    nose: langText.nose[x],
    cheeks: langText.cheeks[x],
    bodyShape: langText.bodyShape[x],
    bloodType: langText.bloodType[x],
    characterText1: langText.characterText1[x],
    characterText2: langText.characterText2[x],
    faceShapeShape: langText.faceShapeShape[x],
    faceShapeDimension: langText.faceShapeDimension[x],
    faceShapeAsymmetric: langText.faceShapeAsymmetric[x],
    faceShapeT1: langText.faceShapeT1[x],
    faceShapeT2: langText.faceShapeT2[x],
    faceShapeT3: langText.faceShapeT3[x],
    faceShapeT4: langText.faceShapeT4[x],
    faceShapeT5: langText.faceShapeT5[x],
    faceShapeT6: langText.faceShapeT6[x],
    faceShapeT7: langText.faceShapeT7[x],
    faceShapeT8: langText.faceShapeT8[x],
    faceShapeT9: langText.faceShapeT9[x],
    faceShapeT10: langText.faceShapeT10[x],
    faceShapeT11: langText.faceShapeT11[x],
    faceShapeT12: langText.faceShapeT12[x],
    cheeksType: langText.cheeksType[x],
    cheeksT1: langText.cheeksT1[x],
    cheeksT2: langText.cheeksT2[x],
    cheeksT3: langText.cheeksT3[x],
    earsHelix: langText.earsHelix[x],
    earsLobule: langText.earsLobule[x],
    earsMode: langText.earsMode[x],
    earsVLocation: langText.earsVLocation[x],
    earsSize: langText.earsSize[x],
    earsHairy: langText.earsHairy[x],
    earsT1: langText.earsT1[x],
    earsT18: langText.earsT18[x],
    earsT2: langText.earsT2[x],
    earsT3: langText.earsT3[x],
    earsT19: langText.earsT19[x],
    earsT4: langText.earsT4[x],
    earsT5: langText.earsT5[x],
    earsT6: langText.earsT6[x],
    earsT17: langText.earsT17[x],
    earsT7: langText.earsT7[x],
    earsT8: langText.earsT8[x],
    earsT9: langText.earsT9[x],
    earsT10: langText.earsT10[x],
    earsT11: langText.earsT11[x],
    earsT12: langText.earsT12[x],
    earsT13: langText.earsT13[x],
    earsT14: langText.earsT14[x],
    earsT15: langText.earsT15[x],
    earsT16: langText.earsT16[x],
    lipsShapeSize1: langText.lipsShapeSize1[x],
    lipsShapeSize2: langText.lipsShapeSize2[x],
    lipsShapeSize3: langText.lipsShapeSize3[x],
    lipsCorner: langText.lipsCorner[x],
    lipsAsymmetric: langText.lipsAsymmetric[x],
    lipsWaveLine: langText.lipsWaveLine[x],
    lipsTopLines: langText.lipsTopLines[x],
    lipsOthers: langText.lipsOthers[x],
    lipsT1: langText.lipsT1[x],
    lipsT2: langText.lipsT2[x],
    lipsT3: langText.lipsT3[x],
    lipsT4: langText.lipsT4[x],
    lipsT5: langText.lipsT5[x],
    lipsT6: langText.lipsT6[x],
    lipsT7: langText.lipsT7[x],
    lipsT8: langText.lipsT8[x],
    lipsT9: langText.lipsT9[x],
    lipsT10: langText.lipsT10[x],
    lipsT11: langText.lipsT11[x],
    lipsT12: langText.lipsT12[x],
    lipsT13: langText.lipsT13[x],
    lipsT14: langText.lipsT14[x],
    lipsT15: langText.lipsT15[x],
    lipsT16: langText.lipsT16[x],
    lipsT17: langText.lipsT17[x],
    chinType: langText.chinType[x],
    chinChubby: langText.chinChubby[x],
    chinT1: langText.chinT1[x],
    chinT2: langText.chinT2[x],
    chinT3: langText.chinT3[x],
    chinT4: langText.chinT4[x],
    chinT5: langText.chinT5[x],
    chinT6: langText.chinT6[x],
    chinT7: langText.chinT7[x],
    chinT8: langText.chinT8[x],
    chinT9: langText.chinT9[x],
    foreheadShape: langText.foreheadShape[x],
    foreheadSize: langText.foreheadSize[x],
    foreheadDepth: langText.foreheadDepth[x],
    foreheadT1: langText.foreheadT1[x],
    foreheadT2: langText.foreheadT2[x],
    foreheadT3: langText.foreheadT3[x],
    foreheadT4: langText.foreheadT4[x],
    foreheadT5: langText.foreheadT5[x],
    foreheadT6: langText.foreheadT6[x],
    foreheadT7: langText.foreheadT7[x],
    foreheadT8: langText.foreheadT8[x],
    foreheadT9: langText.foreheadT9[x],
    foreheadT10: langText.foreheadT10[x],
    eyebrowsShape: langText.eyebrowsShape[x],
    eyebrowsDistance: langText.eyebrowsDistance[x],
    eyebrowsLength: langText.eyebrowsLength[x],
    eyebrowsAsymmetric: langText.eyebrowsAsymmetric[x],
    eyebrowsIrregular: langText.eyebrowsIrregular[x],
    eyebrowsThick: langText.eyebrowsThick[x],
    eyebrowsT1: langText.eyebrowsT1[x],
    eyebrowsT2: langText.eyebrowsT2[x],
    eyebrowsT3: langText.eyebrowsT3[x],
    eyebrowsT4: langText.eyebrowsT4[x],
    eyebrowsT5: langText.eyebrowsT5[x],
    eyebrowsT6: langText.eyebrowsT6[x],
    eyebrowsT7: langText.eyebrowsT7[x],
    eyebrowsT8: langText.eyebrowsT8[x],
    eyebrowsT9: langText.eyebrowsT9[x],
    eyebrowsT10: langText.eyebrowsT10[x],
    eyebrowsT11: langText.eyebrowsT11[x],
    eyebrowsT12: langText.eyebrowsT12[x],
    eyebrowsT13: langText.eyebrowsT13[x],
    eyebrowsT14: langText.eyebrowsT14[x],
    eyebrowsT15: langText.eyebrowsT15[x],
    eyebrowsT16: langText.eyebrowsT16[x],
    eyebrowsT17: langText.eyebrowsT17[x],
    eyebrowsT18: langText.eyebrowsT18[x],
    eyesShape: langText.eyesShape[x],
    eyesSize: langText.eyesSize[x],
    eyesDepth: langText.eyesDepth[x],
    eyesDistance: langText.eyesDistance[x],
    eyesLongCurvy: langText.eyesLongCurvy[x],
    eyesAsymmetric: langText.eyesAsymmetric[x],
    eyesCross: langText.eyesCross[x],
    eyesT1: langText.eyesT1[x],
    eyesT2: langText.eyesT2[x],
    eyesT3: langText.eyesT3[x],
    eyesT4: langText.eyesT4[x],
    eyesT5: langText.eyesT5[x],
    eyesT6: langText.eyesT6[x],
    eyesT16: langText.eyesT16[x],
    eyesT7: langText.eyesT7[x],
    eyesT8: langText.eyesT8[x],
    eyesT9: langText.eyesT9[x],
    eyesT15: langText.eyesT15[x],
    eyesT10: langText.eyesT10[x],
    eyesT11: langText.eyesT11[x],
    eyesT12: langText.eyesT12[x],
    eyesT13: langText.eyesT13[x],
    eyesT14: langText.eyesT14[x],
    eyeColorCornea: langText.eyeColorCornea[x],
    eyeColorT1: langText.eyeColorT1[x],
    eyeColorT2: langText.eyeColorT2[x],
    eyeColorT3: langText.eyeColorT3[x],
    eyeColorT4: langText.eyeColorT4[x],
    eyeColorT5: langText.eyeColorT5[x],
    eyeColorT6: langText.eyeColorT6[x],
    noseShape: langText.noseShape[x],
    noseT1: langText.noseT1[x],
    noseT2: langText.noseT2[x],
    noseT3: langText.noseT3[x],
    noseT4: langText.noseT4[x],
    noseT5: langText.noseT5[x],
    noseT6: langText.noseT6[x],
    noseT7: langText.noseT7[x],
    noseT8: langText.noseT8[x],
    noseT9: langText.noseT9[x],
    noseT10: langText.noseT10[x],
    noseT11: langText.noseT11[x],
    noseT12: langText.noseT12[x],
    bodyShapeShape: langText.bodyShapeShape[x],
    bodyShapeHeight: langText.bodyShapeHeight[x],
    bodyShapeT5: langText.bodyShapeT5[x],
    bodyShapeT6: langText.bodyShapeT6[x],
    bodyShapeT7: langText.bodyShapeT7[x],
    bodyShapeT8: langText.bodyShapeT8[x],
    bodyShapeT10: langText.bodyShapeT10[x],
    bodyShapeT9: langText.bodyShapeT9[x],
    bloodTypeType: langText.bloodTypeType[x],
    bloodTypeT1: langText.bloodTypeT1[x],
    bloodTypeT2: langText.bloodTypeT2[x],
    bloodTypeT3: langText.bloodTypeT3[x],
    bloodTypeT4: langText.bloodTypeT4[x],
    unselectAll: langText.unselectAll[x],
    birthDate: langText.birthDate[x],
    commonName: langText.commonName[x],
    commonNameComment: langText.commonNameComment[x],
    motherName: langText.motherName[x],
    positiveTraits: langText.positiveTraits[x],
    negativeTraits: langText.negativeTraits[x],
    cardUncompeleted: langText.cardUncompeleted[x],
    cardCompeleted: langText.cardCompeleted[x],
    helpSelect: langText.helpSelect[x],
    abjadText: langText.abjadText[x],
    abjadText2: langText.abjadText2[x],
    astrologyText: langText.astrologyText[x],
    astrologyText2: langText.astrologyText2[x],
    enterFarsiArabi: langText.enterFarsiArabi[x],
    completeInfo: langText.completeInfo[x],
    genderErr: langText.genderErr[x],
    commonNameErr: langText.commonNameErr[x],
    motherNameErr: langText.motherNameErr[x],
    completeBirthDate: langText.completeBirthDate[x],
    yy: langText.yy[x],
    mm: langText.mm[x],
    dd: langText.dd[x],
    birthErr: langText.birthErr[x],
    deleteAllSelections: langText.deleteAllSelections[x],
    imageManagement: langText.imageManagement[x],
    title: langText.title[x],
    importExportServices: langText.importExportServices[x],
    userCommentErr: langText.userCommentErr[x],
    userRatingErr: langText.userRatingErr[x],
    userMessageFailed: langText.userMessageFailed[x],
    helpSelectCard: langText.helpSelectCard[x],
    cosmicIstikhara: langText.cosmicIstikhara[x],
    userVideos: langText.userVideos[x],
    videoT1: langText.videoT1[x],
    postNewVideo: langText.postNewVideo[x],
    newVideo: langText.newVideo[x],
    videoCode: langText.videoCode[x],
    videoCodeErr: langText.videoCodeErr[x],
    editInfo: langText.editInfo[x],
    videos: langText.videos[x],
    nonAcceptanceReason: langText.nonAcceptanceReason[x],
    cancel: langText.cancel[x],
    businessVideo: langText.businessVideo[x],
    postAds: langText.postAds[x],
    saleAds: langText.saleAds[x],
    buyAds: langText.buyAds[x],
    visitsQTY: langText.visitsQTY[x],
    visitorCountries: langText.visitorCountries[x],
    today: langText.today[x],
    yesterday: langText.yesterday[x],
    last7Days: langText.last7Days[x],
    last30Days: langText.last30Days[x],
    last60Days: langText.last60Days[x],
    last90Days: langText.last90Days[x],
    last180Days: langText.last180Days[x],
    last12months: langText.last12months[x],
    todayVisits: langText.todayVisits[x],
    last7DaysVisits: langText.last7DaysVisits[x],
    totalVisits: langText.totalVisits[x],
    viewers: langText.viewers[x],
    view: langText.view[x],
    views: langText.views[x],
    notifications: langText.notifications[x],
    showDetails: langText.showDetails[x],
    videoInfo: langText.videoInfo[x],
    action: langText.action[x],
    bizAccess: langText.bizAccess[x],
    // xxxxx: langText.xxxxx[x],
    // xxxxx: langText.xxxxx[x],
  }

  return data
}

export default setLangText;
