// import axios from 'axios';
// import { abjadDataChart, pageName, PSYInfo, genderChange, page, pageTitle, cardChange } from '../../../dataStore/actions';

const cardSelect = (card) => {
console.log(card)
        var sCount = 
            card.abjad +
            card.astrology +
            card.colors + 
            card.faceShape +
            card.lips +
            card.ears +
            card.eyes +
            card.eyeColor +
            card.cheeks +
            card.chin +
            card.forehead +
            card.eyebrows +
            card.nose +
            card.bodyShape +
            card.bloodType
        var nsCount = 15-sCount
      
      return { sCount, nsCount }
}

export default cardSelect; 