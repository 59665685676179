import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button } from "mdbreact";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { subject, siteType, eyeColor, card, PSYInfo, toggleGender, genderChange, eyeColorDataChart, pageName, pageTitle, page, cardChange } from '../../../dataStore/actions';
import '../../../public/css/SelectColor.css';
import './../../../public/css/alerts.css';
import clean from '../../../public/images/background/clean.png';
import ReactResizeDetector from 'react-resize-detector';
import attributes from './../../../langText/attributes';
import srcSet from '../../../srcSet';
import LoadingBar from 'react-top-loading-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import definition from '../../../langText/definition';
import { FaInfoCircle } from 'react-icons/fa';
import CardCompelete from '../../../components/CardCompelete';
import BackToCharacterCards from '../../../components/Psychology/BackToCharacterCards';
import Gender from '../../../components/Gender';
import cardSelect from '../../../modules/cardSelect';
import HelpSelect from '../../../components/Psychology/HelpSelect';
import EyeColorSelected from '../../../components/Psychology/FaceReading/EyeColor/EyeColorSelected';
import siteView from '../../../modules/siteView';

var s = srcSet.s
var NavH = srcSet.NavH
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

class EyeColorPage extends Component {
    
  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.eyeColor,
    ASum1:0,
    BSum1:0,
}

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    // this.LoadingBar.continuousStart()
    // await this.props.dispatch(pageName(this.props.setLT.eyeColor))
    await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
    await this.props.dispatch(page('eye-color'))
    await this.props.dispatch(subject('eye-color'))
    await this.props.dispatch(siteType(2))
    if(this.state.pageName===undefined) window.location.reload()

    siteView(this.props)
    await this.findSelected()
    await this.setDataChart()
    await this.makeChart()
  }

  onResize = () => {
    this.setState({
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  findSelected = async () => {
    var PSYInfoX = this.props.PSYInfo
    var dataX = PSYInfoX.eyeColor
    var sx = {}
    for(var i=0; i<dataX.length; i++) {
      dataX[i].genderValue=PSYInfoX.genderValue
      var t = dataX[i].typeNo
      var n = dataX[i].itemNo
      this.setState({[`type${t}`]: n})
      sx[`type${t}`] = n
    }
    await this.props.dispatch(eyeColor(sx))
    this.LoadingBar.complete()
  }

  setDataChart = async () => {
    var data = this.props.PSYInfo.eyeColor
    await axios.post('http://localhost:4200/eyeColor/add', data).then(async res => {});
    await this.eyeColorChart()
    await axios.get('http://localhost:4200/eyeColor/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
  }

  typeToolTip = () => {
    var T = this.state.T
    var N = this.state.N
    for(let i=0; i<=9; i++) {
      this.setState({ [`typeToolTip${i}`]:'' })
    }
    this.setState({[`typeToolTip${T}`]: eval(`this.props.setLT.eyeColorT${N}`)})
  }

  touchMove = (T, N) => {
    this.setState({ T, N })
    this.typeToolTip()
  }

  touchEnd() {
    this.setState({ T: '', N: '' })
  }

  onImageOver(t, n) {
    this.setState({
      t, n
    })
  }

  onImageOut() {
    this.setState({
      t: '',
      n: '',
    })
  }

  // cardSelected = () => {
  //   if (this.props.auth) {
  //     cardSelect(this.props.userId).then(res => {
  //       if(res!==undefined) this.props.dispatch(card(res))
  //     })
  //   }
  // }

  onSelect = async (t, n) =>  {
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
    } else {
      this.LoadingBar.continuousStart()
      this.setState({
        ASum1: undefined,
        BSum1: undefined,
      })
      var PSYInfoX = this.props.PSYInfo
      var dataX = PSYInfoX.eyeColor
      const info = {
        fakeId: PSYInfoX.fakeId,
        genderValue: PSYInfoX.genderValue,
        typeNo: t,
        itemNo: n
      }

      if(this.state[`type${t}`]===n) {
        this.setState({[`type${t}`]: ''})
        for(var i=0; i<dataX.length; i++) {
          if(dataX[i].typeNo===t) {
            dataX.splice(i, 1)
          }
        }
      } else {
        this.setState({[`type${t}`]: n})
        if (dataX.length===0) {
          dataX.push(info)
        } else {
          for(var i=0; i<dataX.length; i++) {
            if(dataX[i].typeNo===t) {
              dataX[i].itemNo=n
              var exist = true
            }
            dataX[i].fakeId=info.fakeId
            dataX[i].genderValue=info.genderValue
          }
          if(!exist) dataX.push(info)
        }
      }
      PSYInfoX.eyeColor = dataX
      await this.props.dispatch(PSYInfo(PSYInfoX))
      await this.findSelected()
      await this.setDataChart()
      await this.props.dispatch(cardChange(true))
      this.LoadingBar.complete()
    }
  }

  onCleanAll = async () => {
    await axios.get('http://localhost:4200/eyeColor/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
    var PSYInfoX = this.props.PSYInfo
    PSYInfoX.eyeColor = []
    await this.props.dispatch(PSYInfo(PSYInfoX))
    this.setState({type1:'', type2:'', type3:'', type4:'', type5:'', type6:'', type7:'', type8:'', type9:'', type10:''})
    await this.findSelected()
    await this.eyeColorChart()
    await this.props.dispatch(cardChange(true))
  }

  getDataChart = async () => {
    await axios.get('http://localhost:4200/eyeColor/chart/' + this.props.PSYInfo.fakeId).then(async res => {
      this.props.dispatch(eyeColorDataChart(res.data))
    });
  }

  makeChart = async () => {
    var {rtl, lang, eyeColorDataChart} = this.props
    var xt = eyeColorDataChart
    // console.log(xt)

    var A = []
    var B = []
    for(var i=0; i<xt.length; i++) {
      if(xt[i].score>0) {
        A.push(xt[i])
      } else {
        B.push(xt[i])
      }
    }

    A.sort((a, b) => (a.score > b.score) ? -1 : 1)
    B.sort((a, b) => (a.score > b.score) ? 1 : -1)

    var maxA = A[0] ? A[0].score : 0
    var minB = B[0] ? B[0].score : 0
    var maxX = Math.max(maxA, -minB)

    for (var i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      xt[i]['character']=attributes[cx][lang]
      xt[i]['definition']=definition[cx][lang]
    }

    var PSYInfoX = this.props.PSYInfo
    if(xt.length===0){
      PSYInfoX.card.eyeColor = 0
    } else {
      PSYInfoX.card.eyeColor = 1
    }
    await this.props.dispatch(PSYInfo(PSYInfoX))

    const definitionIcone = (
      <div className=''
          style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
          <FaInfoCircle/>
      </div>
    )
    var p
    var AChartData1 = A.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'green', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    var BChartData1 = B.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent:'', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'red', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    this.setState({ 
      AChartData1, 
      BChartData1,
      ASum1: A.length,
      BSum1: B.length
    })

  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }
  
  eyeColorChart = async () => {
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
    })

    await this.getDataChart()
    await this.makeChart()

  }

  onGenderChenge = async () => {
    await this.props.dispatch(genderChange(''))
    var PSYInfoX = this.props.PSYInfo
    var dataX = PSYInfoX.eyeColor
    for(var i=0; i<dataX.length; i++) {
      dataX[i].genderValue=PSYInfoX.genderValue
    }
    await axios.post('http://localhost:4200/eyeColor/add', dataX).then(async res => {});
    await this.eyeColorChart()
    await axios.get('http://localhost:4200/eyeColor/delete/' + this.props.PSYInfo.fakeId).then(async res => {});
  }

  render() {
    const { w, h, AChartData1, BChartData1, ASum1, BSum1, T,
      type1, 
      typeToolTip1 } = this.state
    const { rtl, lang, setLT, genderChange,  } = this.props
    const loaderZ = <div className='loader-02' style={{margin: '0px', color:'#ffffff', transform: rtl ? 'rotate(180deg)' : ''}}></div>
    const center = 'd-flex justify-content-center'
    const myStyle = { transition:'0s', height: '40px', width: '', display: 'flex', margin: '40px 5px 10px', top: NavH+5, zIndex:'0' }
    if(genderChange==='eye-color-Gender') this.onGenderChenge()

    const typeConst1 = (
      <div className='d-flex justify-content-center silver2 sticky-top' style={myStyle}>
        <div style={{ width: (w<s && T===1) ? '200px': '180px',
              backgroundColor: 'white', transition:'1s', margin: '3px 3px 3px 3px',
              paddingTop: '4px', borderRadius: '4px', textAlign:'center'}}>
              {(w<s && T===1) ? typeToolTip1 : setLT.eyeColorCornea}
        </div>
      </div>
    )

    const unselectBtn = (
      <div className="text-center animated fadeInDown" style={{animationDelay:'.5s', margin:'0px 0px', zIndex:'0'}}>
          <Button outline color="danger" onClick={() => this.onCleanAll()} style={{fontSize:'13px', padding: '5px 10px 5px 10px'}}>
              {setLT.unselectAll}
              <img src={clean} alt="clean" height="25" style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}/>
          </Button>
      </div>
    )

    const eyeColor1 = (
      <div>
        <a data-tip data-for='eyeColor1' className={center} onClick={() => this.onSelect(1, 1)}>
          <img
            onTouchMove={() => this.touchMove(1, 1)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 1)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 1 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/1.jpg`)}
            alt={setLT.eyeColorT1}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor1' place='bottom' type='default' className={type1 === 1 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT1 }
          </ReactTooltip>
        }
      </div>
    )

    const eyeColor2 = (
      <div>
        <a data-tip data-for='eyeColor2' className={center} onClick={() => this.onSelect(1, 2)}>
          <img
            onTouchMove={() => this.touchMove(1, 2)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 2)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 2 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/2.jpg`)}
            alt={setLT.eyeColorT2}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor2' place='bottom' type='default' className={type1 === 2 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT2 }
          </ReactTooltip>
        }
      </div>
    )

    const eyeColor3 = (
      <div>
        <a data-tip data-for='eyeColor3' className={center} onClick={() => this.onSelect(1, 3)}>
          <img
            onTouchMove={() => this.touchMove(1, 3)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 3)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 3 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/3.jpg`)}
            alt={setLT.eyeColorT3}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor3' place='bottom' type='default' className={type1 === 3 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT3 }
          </ReactTooltip>
        }
      </div>
    )

    const eyeColor4 = (
      <div>
        <a data-tip data-for='eyeColor4' className={center} onClick={() => this.onSelect(1, 4)}>
          <img
            onTouchMove={() => this.touchMove(1, 4)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 4)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 4 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/4.jpg`)}
            alt={setLT.eyeColorT4}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor4' place='bottom' type='default' className={type1 === 4 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT4 }
          </ReactTooltip>
        }
      </div>
    )

    const eyeColor5 = (
      <div>
        <a data-tip data-for='eyeColor5' className={center} onClick={() => this.onSelect(1, 5)}>
          <img
            onTouchMove={() => this.touchMove(1, 5)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 5)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 5 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/5.jpg`)}
            alt={setLT.eyeColorT5}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor5' place='bottom' type='default' className={type1 === 5 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT5 }
          </ReactTooltip>
        }
      </div>
    )

    const eyeColor6 = (
      <div>
        <a data-tip data-for='eyeColor6' className={center} onClick={() => this.onSelect(1, 6)}>
          <img
            onTouchMove={() => this.touchMove(1, 6)} onTouchEnd={() => this.touchEnd()}
            onMouseOver={() => this.onImageOver(type1, 6)} onMouseOut={() => this.onImageOut()}
            className={ `btnImg ${type1 === 6 ? 'imgSelect' : 'imgNotSelect'}` }
            src={require(`../../../public/images/character/faceReading/eyeColor/6.jpg`)}
            alt={setLT.eyeColorT6}
          />
        </a>
        {w>s &&
          <ReactTooltip id='eyeColor6' place='bottom' type='default' className={type1 === 6 ? 'greenWhite' : 'blackWhite'} effect="float">
            { setLT.eyeColorT6 }
          </ReactTooltip>
        }
      </div>
    )

    const ch = ((h-(NavH*2))/2)-60
    const AChart1 = (
      <div style={{width: w<s ? '100%' : '300px', minWidth: w<s ? '100%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex sticky-top' style={{backgroundColor:'#007304', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex sticky-top' style={{width:'100%', top:0, height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;{ASum1!==undefined ? `(${ASum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {AChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const BChart1 = (
      <div style={{width: w<s ? '100%' : '300px', minWidth: w<s ? '100%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', borderRadius:'0px', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex sticky-top' style={{backgroundColor:'#FE0202', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex sticky-top' style={{width:'100%', top:0, height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;{BSum1!==undefined ? `(${BSum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='' style={{top:0, width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px', overflow:'scroll'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {BChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const loadingBar = (
      <LoadingBar
              onRef={ref => (this.LoadingBar = ref)}
              height={LBH}
              background={LBC}
          />
    )

    const chart = (
      <div className='sticky-top' style={{minWidth:'150px', width:w<s ? '150px' : '', top:NavH, marginTop:'33px', padding: '0px', zIndex:'1'}}>
        {AChart1}
        {BChart1}
      </div>
    )

    const items = (
      <div className='' style={{width:'calc(100% - 150px)'}}>
          <div style={{margin:'40px 5px -20px'}}><Gender/></div>

          {typeConst1}
          <div className={w<s ? '' : 'center'}>
            <div className='center' style={{flexWrap:'wrap'}}>
              {eyeColor1}
              {eyeColor2}
              {eyeColor3}
              {eyeColor4}
              {eyeColor5}
              {eyeColor6}
            </div>
          </div>
      </div>
    )

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column', padding: '0px 10px'}}>
        <div className='d-flex' style={{justifyContent:rtl ? 'space-between' : 'flex-end', alignItems:'center', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.eyeColor}</h1>
          <BackToCharacterCards/>
        </div>
        <img
          className='animated fadeInUpX'
          style={{width: w<s ? '100%' : '400px', borderRadius:'5px'}}
          src={require(`../../../public/images/character/faceReading/eyeColor/0.jpg`)}
          alt=''//{setLT.eyeColor}
        />
        <div className='animated fadeInUpX' style={{height:'50px'}}><EyeColorSelected/></div>
      </div>
    )

    return (
      <div className='' style= {{fontFamily: "Vazir", backgroundColor:'', zIndex:'0'}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        {loadingBar}
        <Container>
          <div className='center' style={{flexDirection:'column', alignItems:'center'}}>
            {header}
          </div>
          <div className='center'><HelpSelect/></div>
          {unselectBtn}
          <div className='d-flex animated fadeInUpX' style={{animationDelay:'.8s', margin:'0px 0px 20px'}}>
            {items}
            <div className='sticky-top' style={{top:NavH, zIndex:'1'}}>
              {chart}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    username: state.userInfo['username'],
    genderLabel: state.userInfo['genderLabel'],
    genderValue: state.subUser ? state.subUserInfo['genderValue'] : state.userInfo['genderValue'],
    eyeColorDataChart: state.eyeColorDataChart,
    totalDataChart: state.totalDataChart,
    type1: state.eyeColor['type1'],
    auth: state.auth,
    rtl: state.rtl,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    subUser: state.subUser,
    chSp: state.chSp,
    chSpZ: state.chSpZ,
    subChSp: state.subChSp,
    subChSpZ: state.subChSpZ,
    totalDataChart: state.totalDataChart,
    subTotalDataChart: state.subTotalDataChart,
    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(EyeColorPage);
