import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "mdbreact";
import '../../../../public/css/SelectColor.css';
import { Link } from "react-router-dom";
import langText from './../../../../langText/text';
import jalaali from 'jalaali-js';

class Astrology extends Component {

  state = {}

  componentDidMount = () => { }

  dateToShamsi = (date) => {
    //console.log(44, date)
    // this.setState({
    //     startDate: date
    // });
    var d = jalaali.toJalaali(date.getFullYear(), date.getMonth()+1, date.getDate()) 

    // var yy = d.jy
    // if(d.jm.toString().length === 2){ var mm = d.jm } else { mm = "0" + d.jm }
    // if(d.jd.toString().length === 2){ var dd = d.jd } else { dd = "0" + d.jd }
    
    // var ds = yy + "/" + mm + "/" + dd
    //console.log(15, ds)
    //return ds
    return d.jm
    // // console.log( 'شمسی', d.jy + "/" + d.jm + "/" + d.jd )
    // // console.log( 'شمسی', yy, mm, dd )
    // // console.log( 'شمسی', ds )
    //this.setState({ rqDate: ds });
  }

  birthDateDate = () => {
    //console.log(155)

    if(this.props.rtl){
      this.setState({
        birthDateDate: this.props.birthDate
      });
      //console.log(111, this.state.birthDateDate)

    } else {
        if(this.props.auth && !this.props.subUser) {
          var shBirth = this.props.birthDate
          var syy = shBirth.substring(0, 4)
          var smm = shBirth.substring(5, 7)
          var sdd = shBirth.substring(8, 10)

          var mb = jalaali.toGregorian(Number(syy), Number(smm), Number(sdd))
          this.setState({
            birthDateDate: mb.gy + "-" + mb.gm + "-" + mb.gd
          });
          //console.log(222, this.state.birthDateDate)
        } else {
          this.setState({
            birthDateDate: this.props.birthDate
          });
        }
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { birthDateDate } = this.state
    const { birthDate, rtl, setLT, PSYInfo } = this.props
    const { y, m, d } = this.props.PSYInfo.astrology
    var mx = m.value ? m.value : 1
    return (
          <div style={{margin: '0px'}}>
            <div className='center'>
              { m.value &&
                <img
                  src={require(`../../../../public/images/character/cosmicNumbers/astrology/Gold/${mx}.png`)}
                  style={{height:'100px', width:'100px', margin:'0px 0px -20px'}} alt="Birthday logo"
                />
              }
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    birthDate: state.subUser ? state.subUserInfo['birthDate'] : state.userInfo['birthDate'],
    subUser: state.subUser,
    birthZ: state.birthZ,
    rtl: state.rtl,
    lang: state.lang,
    auth: state.auth,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(Astrology);
