import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from "mdbreact";
import EyesSelected from './EyesSelected';
import view from '../../../../public/images/character/faceReading/eyes/0.jpg';
import '../../../../public/css/SelectColor.css';
import { Link } from "react-router-dom";
import srcSet from '../../../../srcSet';

var s = srcSet.s
var width = srcSet.psyCard.width
var cardBackColor1 = srcSet.psyCard.cardBackColor1
var cardBackColor2 = srcSet.psyCard.cardBackColor2

class Eyes extends Component {

  state = {
    w: window.innerWidth,
  }

  componentDidMount = () => {
  }

  render() {
    const {w } = this.state
    const { lang, setLT, PSYInfo } = this.props

    return (
      <Card className='btnShadowX animated fadeInUpX' style={{ backgroundImage: `linear-gradient(to right , ${cardBackColor1} , ${cardBackColor2})`, margin:'', border: PSYInfo.card.eyes===1 ? '10px solid #3bf503' : '2px solid #FEED97', width:w<s ? w-120 : width}}>
        <Link to={`/${lang}/eyes`} style={{textDecoration:'none'}}>
          <CardHeader className="white-text header" style={{fontWeight: 450, width:'100%'}}>{setLT.eyes}</CardHeader>
          <CardBody style={{padding:'0px 10px', height:'100%'}}>
            <img src={view} className='img-fluid' alt={setLT.eyes}/> 
            <EyesSelected/>
          </CardBody>
        </Link>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    setLT: state.setLT,
    PSYInfo: state.PSYInfo,
  }
}

export default connect (mapStateToProps)(Eyes);
