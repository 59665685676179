import axios from 'axios';
import Chart from 'chart.js';
import { Container, Button } from "mdbreact";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { subject, siteType, astrologyDataChart, page, PSYInfo, toggleGender, genderChange, pageName, pageTitle, cardChange } from '../../../dataStore/actions';
import '../../../public/css/SelectColor.css';
import './../../../public/css/alerts.css';
import ReactResizeDetector from 'react-resize-detector';
import attributes from './../../../langText/attributes';
import jalaali from 'jalaali-js';
import srcSet from '../../../srcSet';
import LoadingBar from 'react-top-loading-bar'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import definition from '../../../langText/definition';
import { FaInfoCircle } from 'react-icons/fa';
import CardCompelete from '../../../components/CardCompelete';
import BackToCharacterCards from '../../../components/Psychology/BackToCharacterCards';
import Gender from '../../../components/Gender';
import siteView from '../../../modules/siteView';

var s = srcSet.s
var NavH = srcSet.NavH
//var subNavH = srcSet.subNavH
var adsSpace = srcSet.adsSpace
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

var shYY = srcSet.shYY
var mlYY = srcSet.mlYY
var MM = srcSet.MM
var DD = srcSet.DD

const shYYOptions = shYY;
const mlYYOptions = mlYY;
const mmOptions = MM;
const ddOptions = DD;

class AstrologyPage extends Component {

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.astrology,
    ASum1:0,
    BSum1:0,
    yyOption: {value: this.props.PSYInfo.astrology.y.value, label: this.props.PSYInfo.astrology.y.label},
    mmOption: {value: this.props.PSYInfo.astrology.m.value, label: this.props.PSYInfo.astrology.m.label},
    ddOption: {value: this.props.PSYInfo.astrology.d.value, label: this.props.PSYInfo.astrology.d.label},
    mx: this.props.PSYInfo.astrology.mx
  }

  componentDidMount = async () => {
    // console.log(this.state)
    window.scrollTo(0, 0)
    // this.LoadingBar.continuousStart()
    // await this.props.dispatch(pageName(this.props.setLT.astrology))
    await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
    await this.props.dispatch(page('astrology'))
    await this.props.dispatch(subject('astrology'))
    await this.props.dispatch(siteType(2))
    if(this.state.pageName===undefined) window.location.reload()

    siteView(this.props)
    this.onApprove()
    await this.setDate()
    this.setLogo()
    // this.LoadingBar.complete()
  }

  setDate = async () => {
    if(this.state.yyOption.value==='') {
      this.setState({
        yyOption:'',
        mmOption:'',
        ddOption:'',
        birthErr: '',
      })  
    } else {
      var y2digit  =  this.state.yyOption.value.toString().substr(0,2)
      if((y2digit==='13' || y2digit==='14') && this.props.lang!=='fa') {
        await this.birthToM()
      }
      if((y2digit==='19' || y2digit==='20') && this.props.lang==='fa') {
        await this.birthToSh()
      }
      this.setDateOption()
    }

  }

  setLogo = async () => {
      var y2digit  =  this.state.yyOption.value.toString().substr(0,2)
      if((y2digit==='19' || y2digit==='20')) {
        await this.birthToSh()
      }
      this.setState({ mx: this.props.PSYInfo.astrology.m.value })
  }

  setDateOption = () => {
    const {y, m, d} = this.props.PSYInfo.astrology
    this.setState({
      yyOption: {value: y.value, label: y.label},
      mmOption: {value: m.value, label: m.label},
      ddOption: {value: d.value, label: d.label},
    })

  }

  yyChange = (yyOption) => {
    this.setState({ yyOption });
    console.log(yyOption)
  }

  mmChange = (mmOption) => {
    this.setState({ mmOption });
    console.log(mmOption)
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
      mx:''
    })
  }

  ddChange = (ddOption) => {
    this.setState({ ddOption });
    console.log(ddOption)
  }

  birthToM = async () => {
    var syy = this.state.yyOption.value
    var smm = this.state.mmOption.value
    var sdd = this.state.ddOption.value
    
    var date = jalaali.toGregorian(syy, smm, sdd)

    console.log(1)
    var yy = date.gy
    if(date.gm.toString().length === 2){ var mm = date.gm } else { mm = "0" + date.gm }
    if(date.gd.toString().length === 2){ var dd = date.gd } else { dd = "0" + date.gd }

    var PSYInfoX = this.props.PSYInfo
    const info = {
      y: {value: Number(yy), label: Number(yy)},
      m: {value: Number(mm), label: mm},
      d: {value: Number(dd), label: dd},
      mx: smm
    }
     console.log('userinfo', info)

    PSYInfoX.astrology = info
    await this.props.dispatch(PSYInfo(PSYInfoX))
    console.log(199, this.props.PSYInfo)
    // this.setState({ mx: smm })

  }

  birthToSh = async () => {
    var syy = this.state.yyOption.value
    var smm = this.state.mmOption.value
    var sdd = this.state.ddOption.value

    var date = jalaali.toJalaali(Number(syy), Number(smm), Number(sdd))

    var yy = date.jy
    if(date.jm.toString().length === 2){ var mm = date.jm } else { mm = "0" + date.jm }
    if(date.jd.toString().length === 2){ var dd = date.jd } else { dd = "0" + date.jd }

    var PSYInfoX = this.props.PSYInfo
    const info = {
      y: {value: Number(yy), label: Number(yy)},
      m: {value: Number(mm), label: mm},
      d: {value: Number(dd), label: dd},
      mx: Number(mm)
    }
    //  console.log('userinfo', info)

    PSYInfoX.astrology = info
    await this.props.dispatch(PSYInfo(PSYInfoX))
    // this.setState({ mx: Number(mm) })

    console.log(199, this.props.PSYInfo)

  }

  onResize = () => {
    this.setState({ w: window.innerWidth })
  }

  checkNull = () => {
    const {y, m, d} = this.props.PSYInfo.astrology
    // console.log(this.props.PSYIknfo.astrology)
    var infoErr = {}
    if(y.value===undefined || y.value==='' || m.value===undefined || m.value==='' || d.value===undefined || d.value==='') infoErr.birthErr = this.props.setLT.birthErr
    return infoErr
  }

  onApprove = async () => {
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
    } else {
      this.setState({
        ASum1: undefined,
        BSum1: undefined,
      })
      const {yyOption, mmOption, ddOption} = this.state
      var PSYInfoX = this.props.PSYInfo
      const info = {
          y: {value: yyOption.value, label: yyOption.value},
          m: {value: mmOption.value, label: mmOption.label},
          d: {value: ddOption.value, label: ddOption.label},
      }
      PSYInfoX.astrology = info
      await this.props.dispatch(PSYInfo(PSYInfoX))
  
      var infoErr = this.checkNull()
      if(Object.keys(infoErr).length>0) {
        this.setState({
            birthErr: infoErr.birthErr,
            ASum1: 0,
            BSum1: 0,    
        })
  
      } else {
        this.LoadingBar.continuousStart()
        this.setState({ 
          AChartData1 : [], 
          BChartData1 : [],
        })
  
        const user = {
          fakeId: this.props.PSYInfo.fakeId,
          genderValue: this.props.PSYInfo.genderValue,
          itemNo: info.m.value,
          card: 'astrology'
        }
        // console.log(10, user)
        await this.setLogo()
        await axios.post('http://localhost:4200/astrology/update', user).then(res => {});
        await axios.get('http://localhost:4200/astrology/chart/' + user.fakeId).then(res => {
          this.props.dispatch(astrologyDataChart(res.data))
        });
        await axios.get('http://localhost:4200/astrology/delete/' + user.fakeId).then(res => {});
        await this.astrologyChart()
        await this.props.dispatch(cardChange(true))
  
        this.setState({
          birthErr: '',
        })
        // console.log(777, this.props.astrologyDataChart)
        this.LoadingBar.complete()
      }

    }

  }

  astrologyChart = async () => {
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
    })

    var xt = this.props.astrologyDataChart
    var {rtl, lang} = this.props
    // console.log(xt)

    var A = []
    var B = []
    // var sumA = 0
    // var sumB = 0
    for(var i=0; i<xt.length; i++) {
      if(xt[i].score>0) {
        A.push(xt[i])
        // sumA = sumA + xt[i].score
      } else {
        // xt[i].score = -xt[i].score
        B.push(xt[i])
        // sumB = sumB + xt[i].score
      }
    }

    // this.props.dispatch(colorsDataChart(xt))

    A.sort((a, b) => (a.score > b.score) ? -1 : 1)
    B.sort((a, b) => (a.score > b.score) ? 1 : -1)

    // console.log(A)
    // console.log(B)

    var maxA = A[0] ? A[0].score : 0
    var minB = B[0] ? B[0].score : 0
    var maxX = Math.max(maxA, -minB)

    // console.log(maxA)
    // console.log(minB)
    // console.log(maxX)

    for (var i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      xt[i]['character']=attributes[cx][lang]
      xt[i]['definition']=definition[cx][lang]
    }

    var PSYInfoX = this.props.PSYInfo
    if(xt.length===0){
      PSYInfoX.card.astrology = 0
    } else {
      PSYInfoX.card.astrology = 1
    }
    await this.props.dispatch(PSYInfo(PSYInfoX))

    const definitionIcone = (
      <div className=''
          style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
          <FaInfoCircle/>
      </div>
    )
    var p
    var AChartData1 = A.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'green', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    var BChartData1 = B.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent:'', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'red', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    this.setState({ 
      AChartData1, 
      BChartData1,
      ASum1: A.length,
      BSum1: B.length
     })
  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }

  onDelete = async () => {
    this.LoadingBar.continuousStart()
    var PSYInfoX = this.props.PSYInfo
    const info = {
      y: {value: '', label: ''},
      m: {value: '', label: ''},
      d: {value: '', label: ''},
      mx: ''
    }
    PSYInfoX.astrology = info
    await this.props.dispatch(PSYInfo(PSYInfoX))

    this.setState({
      yyOption:'',
      mmOption:'',
      ddOption:'',
      mx:'',
      birthErr: '',
    })
    await this.props.dispatch(astrologyDataChart([]))
    await this.astrologyChart()
    await this.props.dispatch(cardChange(true))
    this.LoadingBar.complete()
  }

  onGenderChenge = async () => {
    await this.props.dispatch(genderChange(''))
    this.onApprove()
  }

  render() {
    const { w, birthErr, yyOption, mmOption, ddOption, mx,
      AChartData1, BChartData1, ASum1, BSum1 } = this.state
    const { rtl, lang, setLT, genderChange,  } = this.props

    const loaderZ = <div className='loader-02' style={{margin: '0px', color:'#ffffff', transform: rtl ? 'rotate(180deg)' : ''}}></div>

    if(genderChange==='astrology-Gender') this.onGenderChenge()

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column', padding: '0px 10px'}}>
        <div className='d-flex' style={{justifyContent:rtl ? 'space-between' : 'flex-end', alignItems:'center', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.astrology}</h1>
          <BackToCharacterCards/>
        </div>
        <img
          className='animated fadeInUpX'
          style={{width: w<s ? '100%' : '400px', borderRadius:'5px'}}
          src={require(`../../../public/images/character/cosmicNumbers/astrology/0.jpg`)}
          alt={setLT.astrology}
        />
      </div>
    )

    const noteX = (
      <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'', margin:'20px 5px 30px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
          <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
              <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                  <p style={{fontSize:'25px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 0px'}}>{setLT.astrologyText2}</p>
              </div>
          </div>
      </div>
    )

    const note = (
      <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'', margin:'20px 5px 30px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
          <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
              <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                  <p style={{fontSize:'16px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 0px'}}>{setLT.astrologyText}</p>
              </div>
          </div>
      </div>
    )

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        textAlign:'center',
        padding:'0px'
      }),
      control: (base, state) => ({
        ...base,
        textAlign:'center',
        cursor: 'pointer',
        // width:'150px',
        // border:'2px solid #999999',
        // backgroundColor:'#ffffff',
        // width:'150px !important',
        // height: 35,
        // minHeight: 35,
        // fontSize: '14px',
        // padding: '3px 0px 0px',
        // borderRadius:'5px'
      }),
      singleValue: (provided, state) => {
    
        return { ...provided };
      }
    }

    const yyC = (
      <div style={{ width: '75px', marginTop:yyOption.value ? '-20px' : '' }}>
          <div className='animated fadeInUp' style={{fontSize:'12px', lineHeight:'20px', display:yyOption.value ? 'block' : 'none'}}>{setLT.yy}</div>
          <Select
              components={
                  {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }
              }
              styles={customStyles}
              value={yyOption}
              onChange={this.yyChange}
              options={this.props.lang==='fa' ? shYYOptions : mlYYOptions}
              placeholder= {setLT.yy}
          />
      </div>
    )

    const mmC = (
      <div style={{ width: '65px', marginTop:mmOption.value ? '-20px' : '' }}>
          <div className='animated fadeInUp' style={{fontSize:'12px', lineHeight:'20px', display:mmOption.value ? 'block' : 'none'}}>{setLT.mm}</div>
          <Select
              components={
                  {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }
              }
              styles={customStyles}
              value={mmOption}
              onChange={this.mmChange}
              options={mmOptions}
              placeholder= {setLT.mm}
          />
      </div>
    )

    const ddC = (
      <div style={{ width: '65px', marginTop:ddOption.value ? '-20px' : '' }}>
          <div className='animated fadeInUp' style={{fontSize:'12px', lineHeight:'20px', display:ddOption.value ? 'block' : 'none'}}>{setLT.dd}</div>
          <Select
              components={
                  {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }
              }
              styles={customStyles}
              value={ddOption}
              onChange={this.ddChange}
              options={ddOptions}
              placeholder={setLT.dd}
          />
      </div>
    )

    // console.log(66,  mx) 
    const birthLogo = (
      <div className='center' style={{}}>
      {mx
        ? <img className='animated fadeInUpX'
            src={require(`../../../public/images/character/cosmicNumbers/astrology/Gold/${mx}.png`)}
            style={{height:'150px', width:'150px'}} alt="birth date logo"/>
        : <div style={{height:'150px'}}></div>
      }
      </div>
    )

    const birthBox = (
      <div>
          <div className='center' style={{alignItems:'', margin: '20px 0px 0px 0px', fontSize:'15px', alignItems:'center', flexDirection:'column'}}>
              <span className='d-flex' style={{ margin:'0px 0px 30px', fontSize:'18px', fontWeight:'bold', textAlign: 'center'}}>{setLT.birthDate}</span>
              <div className='d-flex justify-content-center' style={{width:'100%'}}>
                  <div className='d-flex' style={{marginTop:'5px', width:'', alignItems:'center', textAlign:'center',  direction:'rtl'}}>
                    {ddC}-
                    {mmC}-
                    {yyC}
                  </div>
              </div>
              {birthLogo}
          </div>
          <span className='invalid-feedback' style={{ display: birthErr ? 'block' : 'none', textAlign: 'center'}}>{birthErr}</span>
      </div>
    )

    const approveBtn = (
      <Button color='success' onClick={this.onApprove} style={{fontSize:'15px', width:'100px'}}>
        {setLT.approve}
      </Button>
    )

    const unselectBtn = (
      <div className="text-center">
          <Button color="danger" onClick={() => this.onDelete()} style={{fontSize:'15px', width:'100px'}}>
              {setLT.delete}
          </Button>
      </div>
    )

    const btns = (
      <div className= 'd-flex justify-content-center' style={{margin: '0px'}}>
        {approveBtn}
        {unselectBtn}
      </div>
    )

    const form = (
      <div className='center' style={{width: w<s ? '100%' : '350px', height:'100%', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', margin: '10px 5px', flexDirection:'column'}}>
        <div style={{textAlign:rtl ? 'justify' : 'left', fontSize:'16px', fontWeight:'', width: '100%', backgroundColor:'#ffffff', borderRadius:'5px', padding:'10px'}}>
          {setLT.completeBirthDate}
        </div>
        <div className='center' style={{width: '100%', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', margin:'10px 0px', flexDirection:'column'}}>
          <Gender/>
          {birthBox}
        </div>
        <div style={{textAlign:rtl ? 'justify' : 'left', fontSize:'16px', fontWeight:'', width: '100%', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px'}}>
          {btns}
        </div>
      </div>
    )

    const AChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#007304', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;{ASum1!==undefined ? `(${ASum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='scroller' style={{top:0, width:'100%', height:'350px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {AChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const BChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', borderRadius:'0px', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#FE0202', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;{BSum1!==undefined ? `(${BSum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='scroller' style={{top:0, width:'100%', height:'350px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {BChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const chart = (
      <div className='center' style={{width:w<s ? '100%' : '', marginTop:'0px', padding: '0px', zIndex:''}}>
        {AChart1}
        {BChart1}
      </div>
    )

    const loadingBar = (
      <LoadingBar
              onRef={ref => (this.LoadingBar = ref)}
              height={LBH}
              background={LBC}
          />
    )

    return (
      <div className='' style= {{fontFamily: "Vazir", backgroundColor:'', paddingBottom:adsSpace, zIndex:'0'}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        {loadingBar}
        <Container>
          <div className='center' style={{flexDirection:'column', alignItems:'center'}}>
            {header}
          </div>
          {noteX}
          {note}
          <div className='d-flex' style={{justifyContent:'space-between', flexWrap:'wrap'}}>
            {form}
            {chart}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userId: state.userInfo['_id'],
    username: state.userInfo['username'],
    birthDate: state.subUser ? state.subUserInfo['birthDate'] : state.userInfo['birthDate'],
    birthZ: state.birthZ,
    astrologyDataChart: state.astrologyDataChart,
    rtl: state.rtl,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    subUser: state.subUser,
    chSp: state.chSp,
    chSpZ: state.chSpZ,
    subChSp: state.subChSp,
    subChSpZ: state.subChSpZ,
    totalDataChart: state.totalDataChart,
    subTotalDataChart: state.subTotalDataChart,
    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(AstrologyPage);
