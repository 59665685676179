import React, { Component } from 'react';
import { connect } from 'react-redux';

class Lips extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3, type4, type5, type6, type7, type8 } = this.props
            
    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type1}.jpg`)}
                    className='imgSelectSm' alt="lips type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type2}.jpg`)}
                    className='imgSelectSm' alt="lips type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type3}.jpg`)}
                    className='imgSelectSm' alt="lips type 3"/>
              </div>
            }
            { type4 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type4}.jpg`)}
                    className='imgSelectSm' alt="lips type 4"/>
              </div>
            }
            { type5 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type5}.jpg`)}
                    className='imgSelectSm' alt="lips type 5"/>
              </div>
            }
            { type6 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type6}.jpg`)}
                    className='imgSelectSm' alt="lips type 6"/>
              </div>
            }
            { type7 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type7}.jpg`)}
                    className='imgSelectSm' alt="lips type 7"/>
              </div>
            }
            { type8 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/lips/${type8}.jpg`)}
                    className='imgSelectSm' alt="lips type 8"/>
              </div>
            }

          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    type1: state.lips['type1'],
    type2: state.lips['type2'],
    type3: state.lips['type3'],
    type4: state.lips['type4'],
    type5: state.lips['type5'],
    type6: state.lips['type6'],
    type7: state.lips['type7'],
    type8: state.lips['type8'],
    lang: state.lang,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(Lips);
