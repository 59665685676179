import React, { Component } from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { Carousel, CarouselCaption, CarouselInner, CarouselItem, View } from "mdbreact";
import { bigImage } from '../../dataStore/actions'; 
import ReactResizeDetector from 'react-resize-detector';
import c1 from '../../public/images/carousel/c1.jpg';
import c2 from '../../public/images/carousel/c2.jpg';
import c3 from '../../public/images/carousel/c3.jpg';
import c4 from '../../public/images/carousel/c4.jpg';
import c5 from '../../public/images/carousel/c5.jpg';
import srcSet from '../../srcSet';

var s = srcSet.s

class CarouselComponent extends Component {

  state = {
    w: window.innerWidth,
    psyCarousel1: this.props.bigImage.psyCarousel1 ? this.props.bigImage.psyCarousel1 : c1,
    psyCarousel2: this.props.bigImage.psyCarousel2 ? this.props.bigImage.psyCarousel2 : c2,
    psyCarousel3: this.props.bigImage.psyCarousel3 ? this.props.bigImage.psyCarousel3 : c3,
    psyCarousel4: this.props.bigImage.psyCarousel4 ? this.props.bigImage.psyCarousel4 : c4,
    psyCarousel5: this.props.bigImage.psyCarousel5 ? this.props.bigImage.psyCarousel5 : c5,
  }

  async componentDidMount () {
    await this.setImage()
  }

  setImage = async () => {
    var bigImage = this.props.bigImage
    delete bigImage.psyCarousel1
    delete bigImage.psyCarousel2
    delete bigImage.psyCarousel3
    delete bigImage.psyCarousel4
    delete bigImage.psyCarousel5

    // if(!bigImage.psyCarousel1) await this.getBigImage('psyCarousel1')
    // if(!bigImage.psyCarousel2) await this.getBigImage('psyCarousel2')
    // if(!bigImage.psyCarousel3) await this.getBigImage('psyCarousel3')
    // if(!bigImage.psyCarousel4) await this.getBigImage('psyCarousel4')
    // if(!bigImage.psyCarousel5) await this.getBigImage('psyCarousel5')
  }

  getBigImage = async (title) => {
    // console.log(1111)
    await axios.post('http://localhost:4200/image/getBigImage', {title})
    .then(async res => {
      // console.log(2222)
      var d = res.data
      var data = this.props.bigImage
      data[d[0].title] = d[0].xImageData
      await this.props.dispatch(bigImage(data))
      this.setState({[`${title}`]: d[0].xImageData})
      // console.log(33333, this.props.bigImage)
    })
  }

  onResize = () => {
    this.setState({
      w: window.innerWidth
    })
  }

  render() {
    const {w, psyCarousel1, psyCarousel2, psyCarousel3, psyCarousel4, psyCarousel5} = this.state
    const {bigImage, } = this.props

    var CH = '60Vw'
    return (
      <div>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        <Carousel
          activeItem={1}
          length={5}
          showControls={false}
          showIndicators={false}
          className="z-depth-1"
        >
          <CarouselInner>
            <CarouselItem itemId="1">
              <View>
                <img
                  className="d-block w-100"
                  style={{height: w<s ? CH : ''}}
                  src={psyCarousel1 ? psyCarousel1 : c1}
                  alt="First slide"
                />
              </View>
  {/*             <CarouselCaption>
                <h3 className="h3-responsive">Cosmic Numbers Science</h3>
                <p>Cosmic Numbers Science Cosmic Numbers Science</p>
              </CarouselCaption> */}
            </CarouselItem>
            <CarouselItem itemId="2">
              <View>
                <img
                  className="d-block w-100"
                  style={{height: w<s ? CH : ''}}
                  src={psyCarousel2 ? psyCarousel2 : c2}
                  alt="Second slide"
                />
              </View>
  {/*             <CarouselCaption>
                <h3 className="h3-responsive">Color Psychology</h3>
                <p>Color Psychology Color Psychology Color Psychology</p>
              </CarouselCaption> */}
            </CarouselItem>
            <CarouselItem itemId="3">
              <View>
                <img
                  className="d-block w-100"
                  style={{height: w<s ? CH : ''}}
                  src={psyCarousel3 ? psyCarousel3 : c3}
                  alt="Third slide"
                />
              </View>
  {/*             <CarouselCaption>
                <h3 className="h3-responsive" style={{color: '#28579c'}}>Astrology</h3>
                <p style={{color: '#28579c'}}>Astrology Astrology Astrology Astrology Astrology</p>
              </CarouselCaption> */}
            </CarouselItem>
            <CarouselItem itemId="4">
              <View>
                <img
                  className="d-block w-100"
                  style={{height: w<s ? CH : ''}}
                  src={psyCarousel4 ? psyCarousel4 : c4}
                  alt="Mattonit's item"
                />
              </View>
  {/*             <CarouselCaption>
                <h3 className="h3-responsive">Face Reading</h3>
                <p>Face Reading Face Reading Face Reading Face Reading</p>
              </CarouselCaption> */}
            </CarouselItem>
            <CarouselItem itemId="5">
            <View>
              <img
                className="d-block w-100"
                style={{height: w<s ? CH : ''}}
                src={psyCarousel5 ? psyCarousel5 : c5}
                alt="Mattonit's item"
              />
            </View>
  {/*             <CarouselCaption>
              <h3 className="h3-responsive">Face Reading</h3>
              <p>Face Reading Face Reading Face Reading Face Reading</p>
            </CarouselCaption> */}
          </CarouselItem>
          </CarouselInner>
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bigImage: state.bigImage,

  }
}

export default connect (mapStateToProps)(CarouselComponent);
