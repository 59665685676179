import React, { Component } from 'react';
import { connect } from 'react-redux';

class Ears extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3, type4, type5, type6 } = this.props

    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type1}.jpg`)}
                    className='imgSelectSm' alt="ears type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type2}.jpg`)}
                    className='imgSelectSm' alt="ears type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type3}.jpg`)}
                    className='imgSelectSm' alt="ears type 3"/>
              </div>
            }
            { type4 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type4}.jpg`)}
                    className='imgSelectSm' alt="ears type 4"/>
              </div>
            }
            { type5 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type5}.jpg`)}
                    className='imgSelectSm' alt="ears type 5"/>
              </div>
            }
            { type6 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/ears/${type6}.jpg`)}
                    className='imgSelectSm' alt="ears type 6"/>
              </div>
            }
            
          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    type1: state.ears['type1'],
    type2: state.ears['type2'],
    type3: state.ears['type3'],
    type4: state.ears['type4'],
    type5: state.ears['type5'],
    type6: state.ears['type6'],
    lang: state.lang,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(Ears);
