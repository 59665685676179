import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import srcSet from '../srcSet';

var s = srcSet.s

class PsychologyBtn extends Component {

  state = {
    w: window.innerWidth
  }

  componentDidMount() {  }

  onGoPsychology = () => {
    // window.open(`https://www.psychology.whoraly.com/${this.props.lang}`);
    window.location = `https://www.psychology.whoraly.com/${this.props.lang}`;

  }

  render() {
    const {w} = this.state
    const {setLT, fc, lang} = this.props
    const loaderAlert = <div className='loader-07' style={{marginTop:'10px', color:'#d1a44a', width:'70px', height:'70px', position:'absolute'}}></div>

    return (
      <div className='center' style={{padding:'10px'}}>
          <div className='d-flex btnShadowX animated fadeInUpX' onClick={() => this.onGoPsychology()}
                style={{textDecoration:'none', color:'#ffffff', padding:'2px', backgroundImage: `linear-gradient(to left , #00194c , #00194c)`, margin:'20px 0px', border: '2px solid #d1a44a', width:w<s ? '90%' : '350px', alignItems:'center', borderRadius:'0px 20px 0px 20px'}}>
              <div className='character' style={{width:'50px', height:'50px', backgroundColor: '#ffffff', border: '1px solid #d1a44a', borderRadius:'100px'}}></div>&nbsp;&nbsp;&nbsp;
              <div className="" style={{fontWeight:'', width:''}}>{setLT.BCR}</div>
          </div>
          {loaderAlert}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rtl: state.rtl,
    lang: state.lang,
    auth: state.auth,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(PsychologyBtn);

