
export const toggleLoading = toggleLoading => ({
    type: 'toggleLoading',
    toggleLoading
})

export const toggleShowVideo = toggleShowVideo => ({
    type: 'toggleShowVideo',
    toggleShowVideo
})

export const memberCountry = memberCountry => ({
    type: 'memberCountry',
    memberCountry
})

export const videoInfo = videoInfo => ({
    type: 'videoInfo',
    videoInfo
})

export const userVideo = userVideo => ({
    type: 'userVideo',
    userVideo
})

export const updateUserVideo = updateUserVideo => ({
    type: 'updateUserVideo',
    updateUserVideo
})

export const toggleEditVideoAdmin = toggleEditVideoAdmin => ({
    type: 'toggleEditVideoAdmin',
    toggleEditVideoAdmin
})

export const toggleEditVideo = toggleEditVideo => ({
    type: 'toggleEditVideo',
    toggleEditVideo
})

export const toggleNewVideo = toggleNewVideo => ({
    type: 'toggleNewVideo',
    toggleNewVideo
})

export const siteType = siteType => ({
    type: 'siteType',
    siteType
})

export const subject = subject => ({
    type: 'subject',
    subject
})

export const toggleGender = toggleGender => ({
    type: 'toggleGender',
    toggleGender
})

export const genderChange = genderChange => ({
    type: 'genderChange',
    genderChange
})

export const PSYInfo = PSYInfo => ({
    type: 'PSYInfo',
    PSYInfo
})

export const cardChange = cardChange => ({
    type: 'cardChange',
    cardChange
})

export const starredVideo = starredVideo => ({
    type: 'starredVideo',
    starredVideo
})

export const fullAccess = fullAccess => ({
    type: 'fullAccess',
    fullAccess
})

export const pageName = pageName => ({
    type: 'pageName',
    pageName
})

export const bigImage = bigImage => ({
    type: 'bigImage',
    bigImage
})

export const starredCompany = starredCompany => ({
    type: 'starredCompany',
    starredCompany
})

export const topElement = topElement => ({
    type: 'topElement',
    topElement
})

export const countryCountAllChart = countryCountAllChart => ({
    type: 'countryCountAllChart',
    countryCountAllChart
})

export const countryCountAll = countryCountAll => ({
    type: 'countryCountAll',
    countryCountAll
})

export const starredAds = starredAds => ({
    type: 'starredAds',
    starredAds
})

export const updateUserAds = updateUserAds => ({
    type: 'updateUserAds',
    updateUserAds
})

export const updateUserRequest = updateUserRequest => ({
    type: 'updateUserRequest',
    updateUserRequest
})

export const toggleUserShowAds = toggleUserShowAds => ({
    type: 'toggleUserShowAds',
    toggleUserShowAds
})

export const toggleUserShowRequest = toggleUserShowRequest => ({
    type: 'toggleUserShowRequest',
    toggleUserShowRequest
})

export const userAds = userAds => ({
    type: 'userAds',
    userAds
})

export const userRequest = userRequest => ({
    type: 'userRequest',
    userRequest
})

export const lastAds = lastAds => ({
    type: 'lastAds',
    lastAds
})

export const lastRequest = lastRequest => ({
    type: 'lastRequest',
    lastRequest
})

export const profileLang = profileLang => ({
    type: 'profileLang',
    profileLang
})

export const toggleNewAds = toggleNewAds => ({
    type: 'toggleNewAds',
    toggleNewAds
})

export const toggleNewRequest = toggleNewRequest => ({
    type: 'toggleNewRequest',
    toggleNewRequest
})

export const toggleEditAds = toggleEditAds => ({
    type: 'toggleEditAds',
    toggleEditAds
})

export const toggleEditRequest = toggleEditRequest => ({
    type: 'toggleEditRequest',
    toggleEditRequest
})

export const toggleDeleteAds = toggleDeleteAds => ({
    type: 'toggleDeleteAds',
    toggleDeleteAds
})

export const toggleDeleteRequest = toggleDeleteRequest => ({
    type: 'toggleDeleteRequest',
    toggleDeleteRequest
})

export const toggleSpecialAds = toggleSpecialAds => ({
    type: 'toggleSpecialAds',
    toggleSpecialAds
})

export const toggleStarAds = toggleStarAds => ({
    type: 'toggleStarAds',
    toggleStarAds
})

export const setLT = setLT => ({
    type: 'setLT',
    setLT
})

export const userLanguage = userLanguage => ({
    type: 'userLanguage',
    userLanguage
})

export const toggleChat = toggleChat => ({
    type: 'toggleChat',
    toggleChat
})

export const pageYOffset = pageYOffset => ({
    type: 'pageYOffset',
    pageYOffset
})

export const updateProfile = updateProfile => ({
    type: 'updateProfile',
    updateProfile
})

export const categoryApprove = categoryApprove => ({
    type: 'categoryApprove',
    categoryApprove
})

export const categoryArr = categoryArr => ({
    type: 'categoryArr',
    categoryArr
})

export const toggleCategory = toggleCategory => ({
    type: 'toggleCategory',
    toggleCategory
})

export const toggleEditAdsAdmin = toggleEditAdsAdmin => ({
    type: 'toggleEditAdsAdmin',
    toggleEditAdsAdmin
})

export const toggleEditRequestAdmin = toggleEditRequestAdmin => ({
    type: 'toggleEditRequestAdmin',
    toggleEditRequestAdmin
})

export const seoProfile = seoProfile => ({
    type: 'seoProfile',
    seoProfile
})

export const likees = likees => ({
    type: 'likees',
    likees
})

export const toggleSidebar = toggleSidebar => ({
    type: 'toggleSidebar',
    toggleSidebar
})

export const adsInfo = adsInfo => ({
    type: 'adsInfo',
    adsInfo
})

export const requestInfo = requestInfo => ({
    type: 'requestInfo',
    requestInfo
})

export const toggleShowAds = toggleShowAds => ({
    type: 'toggleShowAds',
    toggleShowAds
})

export const toggleShowRequest = toggleShowRequest => ({
    type: 'toggleShowRequest',
    toggleShowRequest
})

export const userSelected = userSelected => ({
    type: 'userSelected',
    userSelected
})

export const dateZ = dateZ => ({
    type: 'dateZ',
    dateZ
})

export const websiteTime = websiteTime => ({
    type: 'websiteTime',
    websiteTime
})

export const rubyQty = rubyQty => ({
    type: 'rubyQty',
    rubyQty
})

export const adsType = adsType => ({
    type: 'adsType',
    adsType
})

export const searchAds = searchAds => ({
    type: 'searchAds',
    searchAds
})

export const profileTab = profileTab => ({
    type: 'profileTab',
    profileTab
})

export const notSeenNotificationQTY = notSeenNotificationQTY => ({
    type: 'notSeenNotificationQTY',
    notSeenNotificationQTY
})

export const notSeenChatQTY = notSeenChatQTY => ({
    type: 'notSeenChatQTY',
    notSeenChatQTY
})

export const colorsSelectedZ = colorsSelectedZ => ({
    type: 'COLORS_SELECTED_Z',
    colorsSelectedZ
})

export const colorSelectedAds = colorSelectedAds => ({
    type: 'COLOR_SELECTED_ADS',
    colorSelectedAds
})

export const blockerList = blockerList => ({
    type: 'BLOCKER_LIST',
    blockerList
})

export const blockeeList = blockeeList => ({
    type: 'BLOCKEE_LIST',
    blockeeList
})

export const age = age => ({
    type: 'AGE',
    age
})

export const messageList = messageList => ({
    type: 'MESSAGE_LIST',
    messageList
})

export const typingSecond = typingSecond => ({
    type: 'TYPING_SECOND',
    typingSecond
})

export const activityStatus = activityStatus => ({
    type: 'ACTIVITY_STATUS',
    activityStatus
})

export const msgDraft = msgDraft => ({
    type: 'MSG_DRAFT',
    msgDraft
})

export const likeeInfo = likeeInfo => ({
    type: 'LIKEE_INFO',
    likeeInfo
})

export const simColor = simColor => ({
    type: 'SIM_COLOR',
    simColor
})

export const colorProgress = colorProgress => ({
    type: 'COLOR_PROGRESS',
    colorProgress
})

export const toggleProfile = toggleProfile => ({
    type: 'TOGGLE_PROFILE',
    toggleProfile
})

export const toggleLiker = toggleLiker => ({
    type: 'TOGGLE_LIKER',
    toggleLiker
})

export const likerList = likerList => ({
    type: 'LIKER_LIST',
    likerList
})

export const userInfo = userInfo => ({
    type: 'USER_INFO',
    userInfo
})

export const colorTestStart = colorTestStart => ({
    type: 'Color_Test_Start',
    colorTestStart
})

export const colorResult = colorResult => ({
    type: 'Color_Result',
    colorResult
})

export const colorChSp = colorChSp => ({
    type: 'Color_ChSp',
    colorChSp
})

export const colorIntLink = colorIntLink => ({
    type: 'Color_Int_Link',
    colorIntLink
})

export const subUserInfo = subUserInfo => ({
    type: 'SUB_USER_INFO',
    subUserInfo
})

export const simList = simList => ({
    type: 'SIM_LIST',
    simList
})

export const memberList = memberList => ({
    type: 'MEMBER_LIST',
    memberList
})

export const subUser = subUser => ({
    type: 'SUB_USER',
    subUser
})

export const abjadDataChart = abjadDataChart => ({
    type: 'ABJAD_DATA_CHART',
    abjadDataChart
})

export const astrologyDataChart = astrologyDataChart => ({
    type: 'ASTROLOGY_DATA_CHART',
    astrologyDataChart
})

export const color8Selected = color8Selected => ({
    type: 'COLOR8_SELECTED',
    color8Selected
})

export const color8DataChart = color8DataChart => ({
    type: 'COLOR8_DATA_CHART',
    color8DataChart
})

export const colorsSelected = colorsSelected => ({
    type: 'colorsSelected',
    colorsSelected
})

export const colorsDataChart = colorsDataChart => ({
    type: 'colorsDataChart',
    colorsDataChart
})

export const totalDataChart = totalDataChart => ({
    type: 'TOTAL_DATA_CHART',
    totalDataChart
})

export const subTotalDataChart = subTotalDataChart => ({
    type: 'SUB_TOTAL_DATA_CHART',
    subTotalDataChart
})

export const chSp = chSp => ({
    type: 'CHSP',
    chSp
})

export const chSpZ = chSpZ => ({
    type: 'CHSP_Z',
    chSpZ
})

export const totalSubChSp = totalSubChSp => ({
    type: 'TOTAL_SUB_CHSP',
    totalSubChSp
})

export const subChSp = subChSp => ({
    type: 'SUB_CHSP',
    subChSp
})

export const subChSpZ = subChSpZ => ({
    type: 'SUB_CHSP_Z',
    subChSpZ
})

export const character = character => ({
    type: 'CHARACTER',
    character
})

export const colorType = colorType => ({
    type: 'COLOR_TYPE',
    colorType
})

export const bodyShape = bodyShape => ({
    type: 'BODY_SHAPE',
    bodyShape
})

export const bodyShapeDataChart = bodyShapeDataChart => ({
    type: 'BODY_SHAPE_DATA_CHART',
    bodyShapeDataChart
})

export const bloodType = bloodType => ({
    type: 'BLOOD_TYPE',
    bloodType
})

export const bloodTypeDataChart = bloodTypeDataChart => ({
    type: 'BLOOD_TYPE_DATA_CHART',
    bloodTypeDataChart
})
//////////////////////
export const cheeks = cheeks => ({
    type: 'CHEEKS',
    cheeks
})

export const cheeksDataChart = cheeksDataChart => ({
    type: 'CHEEKS_DATA_CHART',
    cheeksDataChart
})

export const chin = chin => ({
    type: 'CHIN',
    chin
})

export const chinDataChart = chinDataChart => ({
    type: 'CHIN_DATA_CHART',
    chinDataChart
})

export const ears = ears => ({
    type: 'EARS',
    ears
})

export const earsDataChart = earsDataChart => ({
    type: 'EARS_DATA_CHART',
    earsDataChart
})

export const eyebrows = eyebrows => ({
    type: 'EYEBROWS',
    eyebrows
})

export const eyebrowsDataChart = eyebrowsDataChart => ({
    type: 'EYEBROWS_DATA_CHART',
    eyebrowsDataChart
})

export const eyeColor = eyeColor => ({
    type: 'EYE_COLOR',
    eyeColor
})

export const eyeColorDataChart = eyeColorDataChart => ({
    type: 'EYE_COLOR_DATA_CHART',
    eyeColorDataChart
})

export const eyes = eyes => ({
    type: 'EYES',
    eyes
})

export const eyesDataChart = eyesDataChart => ({
    type: 'EYES_DATA_CHART',
    eyesDataChart
})

export const faceShape = faceShape => ({
    type: 'FACE_SHAPE',
    faceShape
})

export const faceShapeDataChart = faceShapeDataChart => ({
    type: 'FACE_SHAPE_DATA_CHART',
    faceShapeDataChart
})

export const forehead = forehead => ({
    type: 'FOREHEAD',
    forehead
})

export const foreheadDataChart = foreheadDataChart => ({
    type: 'FOREHEAD_DATA_CHART',
    foreheadDataChart
})

export const lips = lips => ({
    type: 'LIPS',
    lips
})

export const lipsDataChart = lipsDataChart => ({
    type: 'LIPS_DATA_CHART',
    lipsDataChart
})

export const nose = nose => ({
    type: 'NOSE',
    nose
})

export const noseDataChart = noseDataChart => ({
    type: 'NOSE_DATA_CHART',
    noseDataChart
})

export const page = page => ({
    type: 'PAGE',
    page
})

export const geo = geo => ({
    type: 'GEO',
    geo
})

export const pageTitle = pageTitle => ({
    type: 'PAGE_TITLE',
    pageTitle
})

export const subNavH = subNavH => ({
    type: 'SUB_NAV_H',
    subNavH
})

export const pageY = pageY => ({
    type: 'PAGE_Y',
    pageY
})

export const url = url => ({
    type: 'URL',
    url
})

export const lang = lang => ({
    type: 'LANG',
    lang
})

export const simUnd = simUnd => ({
    type: 'SIM_UND',
    simUnd
})

export const card = card => ({
    type: 'CARD',
    card
})

export const comment = comment => ({
    type: 'COMMENT',
    comment
})

export const sim = sim => ({
    type: 'SIM',
    sim
})

export const totalSim = totalSim => ({
    type: 'TOTAL_SIM',
    totalSim
})

export const requestIn = requestIn => ({
    type: 'REQUEST_IN',
    requestIn
})

export const requestOut = requestOut => ({
    type: 'REQUEST_OUT',
    requestOut
})

export const follower = follower => ({
    type: 'FOLLOWER',
    follower
})

export const und = und => ({
    type: 'UND',
    und
})

export const totalUnd = totalUnd => ({
    type: 'TOTAL_UND',
    totalUnd
})

export const rtl = rtl => ({
    type: 'RTL',
    rtl
})

export const flag = flag => ({
    type: 'FLAG',
    flag
})

export const birthDate = birthDate => ({
    type: 'BIRTH_DATE',
    birthDate
})

export const birthZ = birthZ => ({
    type: 'BIRTH_Z',
    birthZ
})

export const auth = auth => ({
    type: 'AUTH',
    auth
})

export const member = member => ({
    type: 'MEMBER',
    member
})

export const membership = membership => ({
    type: 'MEMBERSHIP',
    membership
})

export const userImg = userImg => ({
    type: 'USER_IMG',
    userImg
})

export const subUserImg = subUserImg => ({
    type: 'SUB_USER_IMG',
    subUserImg
})

