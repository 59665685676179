import axios from 'axios';

const countryName = (code, name) => {

    var x
    switch (code) {
        case 'IR' : x = 'Iran'; break;
        case 'US' : x = 'United State'; break;
        case 'BO' : x = 'Bolivia'; break;
        case 'BQ' : x = 'Bonaire/Eust'; break;
        case 'BA' : x = 'Bosnia Herzg'; break;
        case 'IO' : x = 'Br Ind Oc Tr'; break;
        case 'UM' : x = 'United States MOI'; break;
        case 'VG' : x = 'Virgin Isl (UK)'; break;
        case 'VI' : x = 'Virgin Isl (US)'; break;
        case 'BN' : x = 'Brunei'; break;
        case 'CF' : x = 'Central African'; break;
        case 'CC' : x = 'Cocos Islands'; break;
        case 'CD' : x = 'Congo'; break;
        case 'DO' : x = 'Dominican'; break;
        case 'FK' : x = 'Falkland Islands'; break;
        case 'TF' : x = 'French SRN TERR'; break;
        case 'HM' : x = 'Heard McDonald Isl'; break;
        case 'LA' : x = 'Lao'; break;
        case 'MK' : x = 'Macedonia'; break;
        case 'FM' : x = 'Micronesia'; break;
        case 'MD' : x = 'Moldova'; break;
        case 'KP' : x = 'North Korea'; break;
        case 'MP' : x = 'Northern Mariana'; break;
        case 'PS' : x = 'Palestine'; break;
        case 'XK' : x = 'Kosovo'; break;
        case 'RU' : x = 'Russian'; break;
        case 'SH' : x = 'St Helena'; break;
        case 'MF' : x = 'St Martin'; break;
        case 'PM' : x = 'St Pier Mq'; break;
        case 'VC' : x = 'St Vincent'; break;
        case 'KN' : x = 'St Kitts Nev'; break;
        case 'ST' : x = 'Sao Tome Prn'; break;
        case 'SX' : x = 'Sint Maarten'; break;
        case 'GS' : x = 'Georgia Sandwich Isl'; break;
        case 'KR' : x = 'South Korea'; break;
        case 'SJ' : x = 'Svalbard Jan Mayen'; break;
        case 'SY' : x = 'Syrian'; break;
        case 'TZ' : x = 'Tanzania'; break;
        case 'TC' : x = 'Turks Caicos Isl'; break;
        case 'GB' : x = 'United Kingdom'; break;
        case 'AE' : x = 'Emirates'; break;
        case 'VE' : x = 'Venezuela'; break;
        default: x = name
    }

    return x
}

export default countryName;