import React, { Component } from "react"; 
import {Helmet} from "react-helmet";
import './public/css/SelectColor.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { Container, Fa, Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import { BrowserRouter as Router , Link } from "react-router-dom";
// import "./sidebar.css";
import "./index.css";
import userN from './public/images/background/user1.png';
import male from './public/images/background/man2.png';
import female from './public/images/background/woman2.png';
import faImg from './public/images/flag/iran.jpg';
import enImg from './public/images/flag/unitedKingdom.jpg';
import arImg from './public/images/flag/arab.jpg';
import trImg from './public/images/flag/turkey.jpg';
import ruImg from './public/images/flag/russia.jpg';
import deImg from './public/images/flag/germany.jpg';
import frImg from './public/images/flag/france.jpg';
import esImg from './public/images/flag/spain.jpg';
import zhImg from './public/images/flag/china.jpg';
import Routes from "./Routes";
import { toggleUserShowAds, toggleLoading, siteType, fullAccess, bigImage, toggleUserShowRequest, toggleDeleteAds, toggleDeleteRequest, toggleGender, toggleSpecialAds,
        toggleEditAds, toggleEditVideo, toggleEditRequest,  toggleNewAds, toggleNewVideo, toggleNewRequest, totalDataChart, setLT, toggleChat, toggleCategory,
        seoProfile, toggleSidebar, toggleShowAds, toggleShowVideo, toggleShowRequest, toggleEditAdsAdmin, toggleEditVideoAdmin, toggleEditRequestAdmin, rubyQty,
        notSeenChatQTY, notSeenNotificationQTY, msgDraft, blockerList, blockeeList, likeeInfo, membership, userInfo, geo, url, simList,
        lang, rtl, auth, member, colorTestStart, colorResult, page, toggleLiker, toggleProfile, subUserInfo, colorProgress,
        colorsSelected, colorsDataChart, subUser } from './dataStore/actions'; 
import ReactResizeDetector from 'react-resize-detector';
import srcSet from './srcSet';
import date from 'date-and-time';
import setLangText from './modules/setLangText';
import CardFooter from './components/CardFooter';
import { FaRegBell, FaBell, FaImages, FaStore, FaBookOpen, FaSearch, FaUserEdit, FaRegCommentDots} from 'react-icons/fa';
import { MdStore, MdEmail, MdDashboard, MdArrowBack, MdClose } from 'react-icons/md';
import { RiUserSearchFill, RiShoppingCart2Fill, RiShoppingCart2Line, RiSearch2Line, RiSearch2Fill } from 'react-icons/ri';
import { BsPlusSquareFill, BsPlusSquare, BsChevronCompactDown, BsFillCaretDownFill, BsFillInfoSquareFill, BsGrid3X3GapFill, BsGrid3X3Gap, BsChatSquareDotsFill } from 'react-icons/bs';
import { AiFillCheckSquare, AiFillHome, AiOutlineHome } from 'react-icons/ai';
import { IoLogoWhatsapp, IoIosSearch, IoMdHeart } from 'react-icons/io';
import { BiSupport } from 'react-icons/bi';
// import { FcShop } from 'react-icons/fc';
import { RiAdvertisementFill, RiAdvertisementLine } from 'react-icons/ri';
import { GiShakingHands } from 'react-icons/gi';
import Gender from './components/Gender';
import ColorLoading from './components/ColorLoading';
import ShareSite from './components/ShareSite';
import PsychologyBtn from './components/PsychologyBtn';
import WhoralyBtn from './components/WhoralyBtn';
import siteView from './modules/siteView';
import { TiThList } from "react-icons/ti";
// import Swal from 'sweetalert2/dist/sweetalert2.js';

var s = srcSet.s
var vn = srcSet.version
var NavH = srcSet.NavH
var headerColor1 = srcSet.headerColor1
var headerColor2 = srcSet.headerColor2
var langArray = srcSet.langArray
var cardBackColor1 = srcSet.psyCard.cardBackColor1
var cardBackColor2 = srcSet.psyCard.cardBackColor2

class App extends Component {

  state = {
    pendingCommentsQTY:'0',
    pendingAdsQTY:'0',
    pendingRequestQTY:'0',
    pendingVideoQTY:'0',
    second: this.props.typingSecond,
    w: window.innerWidth,
    h: window.innerHeight,
    wh: window.innerHeight,
    dh: document.body.clientHeight,
    modal: false,
    n: 0,
    open: false,
    loginItems: false,
  };

  async componentDidMount () {
    await this.language()
    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
    this.props.dispatch(notSeenChatQTY('0'))
    this.props.dispatch(notSeenNotificationQTY('0'))
    this.setModals()
    
    if(this.props.auth) {
        this.notSeenChat()
        this.notSeenNotification()
        this.updateSeen()
        if(this.props.mainUser.access===1) {
            this.pendingComments()
            this.pendingAds()
            this.pendingRequest()
            this.pendingVideo()
        }
    }

    // setInterval( () => {
    //   if(this.props.auth) {
    //       this.notSeenChat()
    //       this.notSeenNotification()
    //   }
    // }, 60 * 1000);

    await this.getGeo()

    // if(this.props.subject==='') {
    //   await this.props.dispatch(subject(window.location.pathname.split('/')[2]))
    //   siteView(this.props)
    // }

    // this.hashChange()
    this.emptyCache()
  }

  emptyCache = () => {
    if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });
    }
  }

  hashChange = () => {
    window.onhashchange = () => {
      // console.log('hash change')
      this.props.dispatch(toggleSidebar(false))
      this.props.dispatch(toggleChat(false))
    }
  }

  onToggleShowAds = async () => {
    this.props.dispatch(toggleShowAds(!this.props.toggleShowAds))
  }

  onToggleShowVideo = async () => {
    this.props.dispatch(toggleShowVideo(!this.props.toggleShowVideo))
  }

  onToggleShowRequest = async () => {
    this.props.dispatch(toggleShowRequest(!this.props.toggleShowRequest))
  }

  onToggleUserShowAds = async () => {
    this.props.dispatch(toggleUserShowAds(!this.props.toggleUserShowAds))
  }

  onToggleUserShowRequest = async () => {
    this.props.dispatch(toggleUserShowRequest(!this.props.toggleUserShowRequest))
  }

  onToggleEditAdsAdmin = async () => {
    this.props.dispatch(toggleEditAdsAdmin(!this.props.toggleEditAdsAdmin))
  }

  onToggleEditVideoAdmin = async () => {
    this.props.dispatch(toggleEditVideoAdmin(true))
  }

  onToggleEditRequestAdmin = async () => {
    this.props.dispatch(toggleEditRequestAdmin(!this.props.toggleEditRequestAdmin))
  }

  onToggleNewAds = async () => {
    this.props.dispatch(toggleNewAds(true)) //Min. Order : 
  }

  onToggleNewVideo = async () => {
    this.props.dispatch(toggleNewVideo(true)) //Min. Order : 
  }

  onToggleNewRequest = async () => {
    this.props.dispatch(toggleNewRequest(!this.props.toggleNewRequest))
  }

  onToggleEditAds = async () => {
    this.props.dispatch(toggleEditAds(!this.props.toggleEditAds))
  }

  onToggleEditVideo = async () => {
    this.props.dispatch(toggleEditVideo(!this.props.toggleEditVideo))
  }

  onToggleEditRequest = async () => {
    this.props.dispatch(toggleEditRequest(!this.props.toggleEditRequest))
  }

  onToggleDeleteAds = async () => {
    this.props.dispatch(toggleDeleteAds(!this.props.toggleDeleteAds))
  }

  onToggleDeleteRequest = async () => {
    this.props.dispatch(toggleDeleteRequest(!this.props.toggleDeleteRequest))
  }

  onToggleGender = async () => {
    this.props.dispatch(toggleGender(!this.props.toggleGender))
  }

  onToggleLoading = async () => {
    this.props.dispatch(toggleLoading(!this.props.toggleLoading))
  }

  onToggleSpecialAds = async () => {
    this.props.dispatch(toggleSpecialAds(!this.props.toggleSpecialAds))
  }

  updateSeen = () => {
    const {mainUserId, subUserId, page} = this.props

    var data = {
      senderId: subUserId,
      receiverId: mainUserId,
    }
    if(page==='chat') {
      axios.post('http://localhost:4200/chat/updateSeen' , data).then(res => {
      });
    }
  }

  onToggleChat = async () => {
    this.props.dispatch(toggleChat(false))
    document.querySelector("body").style.overflow = 'visible';
  }

  onLikerList = () => {
    var data = {
      _id: this.props.mainUserId,
      selected: this.props.selected,
      imageData: this.props.userImg,
      genderValue: this.props.genderValue,
      businessType: this.props.businessType,
      username: this.props.username,
    }
    this.props.dispatch(likeeInfo(data))
    this.props.dispatch(subUser(false))
    this.props.dispatch(toggleSidebar(false))
  }

  onToggleSidebar = () => {
    this.props.dispatch(toggleSidebar(false))
  }

  notSeenChat = () => {
    axios.post('http://localhost:4200/chat/notSeenChat' , {id: this.props.mainUserId}).then(async res => {
      var x = (res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.props.dispatch(notSeenChatQTY(x))
    })
  }

  notSeenNotification = () => {
    axios.post('http://localhost:4200/notification/notSeen' , {visitee: this.props.mainUserId}).then(async res => {
      var x = (res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.props.dispatch(notSeenNotificationQTY(x))
    })
  }

  pendingComments = () => {
    axios.post('http://localhost:4200/comment/countNotApproved').then(async res => {
      var x = Number(res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.setState({ pendingCommentsQTY: x })
    })
  }

  pendingAds = () => {
    axios.post('http://localhost:4200/ads/countAllAds', {status:0}).then(async res => {
      var x = Number(res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.setState({ pendingAdsQTY: x })
    })
  }

  pendingRequest = () => {
    axios.post('http://localhost:4200/request/countAllRequest', {status:0}).then(async res => {
      var x = Number(res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.setState({ pendingRequestQTY: x })
    })
  }

  pendingVideo = () => {
    axios.post('http://localhost:4200/video/countAllVideo', {status:0}).then(async res => {
      var x = Number(res.data).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.setState({ pendingVideoQTY: x })
    })
  }

  language = async () => {
    var pth = window.location.href.replace('#!', ''); 
    var pth = window.location.pathname;
    var x = pth.split('/')[1]
    if(langArray.includes(x)) {
      await this.props.dispatch(lang(x))
      if(x==='fa' || x==='ar') {
        await this.props.dispatch(rtl(true))
      } else {
        await this.props.dispatch(rtl(false))
      }
    } else {
      await this.props.dispatch(lang('en'))
      this.props.dispatch(rtl(false))
      window.location.href='/en'
    }
    this.props.dispatch(setLT(setLangText(this.props.lang)))
  }

  setModals = () => {
    this.props.dispatch(toggleSidebar(false))
    this.props.dispatch(toggleShowAds(false))
    this.props.dispatch(toggleShowVideo(false))
    this.props.dispatch(toggleShowRequest(false))
    this.props.dispatch(toggleUserShowAds(false))
    this.props.dispatch(toggleUserShowRequest(false))
    this.props.dispatch(toggleEditAdsAdmin(false))
    this.props.dispatch(toggleEditVideoAdmin(false))
    this.props.dispatch(toggleEditRequestAdmin(false))
    this.props.dispatch(toggleProfile(false))
    this.props.dispatch(toggleChat(false))
    this.props.dispatch(toggleCategory(false))
    this.props.dispatch(toggleNewAds(false))
    this.props.dispatch(toggleNewVideo(false))
    this.props.dispatch(toggleNewRequest(false))
    this.props.dispatch(toggleNewRequest(false))
    this.props.dispatch(toggleEditAds(false))
    this.props.dispatch(toggleEditVideo(false))
    this.props.dispatch(toggleEditRequest(false))
    this.props.dispatch(toggleDeleteAds(false))
    this.props.dispatch(toggleDeleteRequest(false))
    this.props.dispatch(toggleGender(false))
    this.props.dispatch(toggleLoading(false))
    this.props.dispatch(toggleSpecialAds(false))
    this.props.dispatch(toggleLiker(false))
    this.props.dispatch(membership(false))
  }

  toggleMembership = () => {
    this.props.dispatch(membership(!this.props.membership))
  }

  onToggleProfile = async (item) => {
    if(this.props.member) {
      this.props.dispatch(toggleProfile(!this.props.toggleProfile))
      if(item) this.props.dispatch(subUserInfo(item))
      if(this.props.subUser===false) this.props.dispatch(subUser(true))
    } else {
      this.props.dispatch(toggleProfile(!this.props.toggleProfile))
    }
  }

  onToggleLiker() {
    this.props.dispatch(page('liker-list'))
  }

  onSetSidebarOpen = async () => {
    if(this.state.w<s) {
      this.setState({loginItems: false})
      this.props.dispatch(toggleSidebar(!this.props.toggleSidebar))
    }
  }

  onLogin = async () => {
    this.props.dispatch(toggleSidebar(false))
    this.props.dispatch(toggleProfile(false))
  }

  getGeo = async () => {
    await axios.get('https://extreme-ip-lookup.com/json/?key=0Pj2L6oNoa307KidCFE6')
    .then(async (res) => {
      let data = res.data;
      // console.log(data)
      data.country_name = data.country
      await this.props.dispatch(geo(data))
    })
  }

  onResize = () => {
    this.setState({
      w: document.body.clientWidth,
      h: window.innerHeight,
      wh: window.innerHeight,
      dh: document.body.clientHeight
    })
  }

  userPanel = async () => {
    window.scrollTo(0,0)
    await this.props.dispatch(subUser(false))
    this.onSetSidebarOpen()
    if(this.props.page==='user-panel') window.location.reload()
  }

  userImagePanel = () => {
    window.scrollTo(0,0)
    this.onToggle('toggleUserPanel')
    this.props.dispatch(subUser(true))
    this.props.dispatch(seoProfile(true))
    if(this.props.page==='user-panel') window.location.reload()
  }

  subUserImagePanel = async () => {
    this.props.dispatch(toggleProfile(true))
  }

  signUp = () => {
    window.scrollTo(0,0)
    this.setState({page:'login'})
    this.onSetSidebarOpen()
  }

  signin = () => {
    window.scrollTo(0,0)
    this.setState({page:'login'})
    this.onSetSidebarOpen()
  }

  changeLanguage = async (x) => {
    setTimeout(async () => {
      this.props.dispatch(lang(x))
      this.props.dispatch(setLT(setLangText(x)))

      if(x==='fa' || x==='ar') {
        this.props.dispatch(rtl(true))
      } else {
        this.props.dispatch(rtl(false))
      }

      var pth = window.location.pathname;
      var firstRout = pth.split('/')[1]
      // console.log(pth, firstRout)
      if(langArray.includes(firstRout)) {
          window.history.pushState('data', 'Title', pth.replace(firstRout, x));
          let newUrlIS =  window.location.origin + pth.replace(firstRout, x);
          await window.history.pushState({}, null, newUrlIS);
      }
      window.location.reload();
    }, 500);
  }

  langText = (x) => {
    var w = this.state.w
    var auth = this.props.auth
    switch (this.props.lang) {

      case 'en': x = 'English'; break;
      case 'fa': x = 'فارسی'; break;
      case 'ar': x = 'العربية'; break;
      case 'tr': x = 'Türkçe'; break;
      case 'ru': x = 'Русский'; break;
      case 'de': x = 'Deutsch'; break;
      case 'fr': x = 'Français'; break;
      case 'es': x = 'Española'; break;
      case 'zh': x = '中文'; break;

      default:   x = 'language';
    }
    return x
  }

  backGX = () => {
    var x = this.props.siteType
    switch (x) {

      case 1: x = 'worldTradeX'; break;
      case 2: x = 'quran'; break;
      case 3: x = 'cosmic'; break;

      default:   x = 'worldTradeX';
    }
    return x
  }

  siteTypeX = () => {
    var w = this.state.w
    var x = this.props.siteType
    switch (x) {

      case 1: x = w>320 ? 'Business' : 'BIZ'; break;
      case 2: x = w>320 ? 'Psychology' : 'PSY'; break;
      case 3: x = w>320 ? 'Istikhara' : 'IST'; break;

      default:   x = 'Business';
    }
    return x
  }

  logout = () => { 
    localStorage.removeItem('jwtToken');
    this.props.dispatch(page(''))
    this.props.dispatch(auth(false))
    this.props.dispatch(fullAccess(false))
    this.props.dispatch(member(false))
    this.props.dispatch(userInfo([]))
    this.props.dispatch(totalDataChart([]))
    this.props.dispatch(colorsSelected([]))
    this.props.dispatch(colorsDataChart([]))
    this.props.dispatch(colorTestStart(false))
    this.props.dispatch(colorResult(false))
    this.props.dispatch(colorProgress(0))
    this.props.dispatch(simList([]))
    this.props.dispatch(blockerList([]))
    this.props.dispatch(blockeeList([]))
    this.props.dispatch(likeeInfo([]))
    this.props.dispatch(msgDraft(''))
    this.props.dispatch(rubyQty(0))

    window.scrollTo(0, 0);
    window.location.reload();
    window.location.href = `/${this.props.lang}`;
  }

  onToggle = (x) => {
    window.scrollTo(0, 0);
    // this.props.dispatch(page(x))
    if(x!=='notifications') {
      this.notSeenNotification()
      this.notSeenChat()
    }
    if(this.props.siteType!==1) this.setState({toggleCardFooter: true})
  }

  onGoWhoraly = () => {
    // window.open(`https://www.psychology.whoraly.com/${this.props.lang}`);
    window.location = `https://www.whoraly.com/${this.props.lang}`;
  }

  onGoWhoralyLogin = () => {
    // window.open(`https://www.psychology.whoraly.com/${this.props.lang}`);
    window.location = `https://www.whoraly.com/${this.props.lang}/login`;
  }

  onGoWhoralyAbout = () => {
    // window.open(`https://www.psychology.whoraly.com/${this.props.about-us}`);
    window.location = `https://www.whoraly.com/${this.props.lang}/about-us`;
  }

  render() {

    const {w, h, pendingCommentsQTY, pendingAdsQTY, pendingRequestQTY, pendingVideoQTY, subStatus, loginItems, toggleCardFooter,
    } = this.state
    const {auth, setLT, fc, access, toggleSidebar, toggleShowAds, toggleShowVideo, toggleShowRequest, toggleUserShowAds, toggleNewAds, toggleNewVideo,
          toggleNewRequest, toggleEditAds, toggleEditVideo, toggleDeleteAds, toggleSpecialAds, toggleUserShowRequest, toggleEditRequestAdmin,
          toggleEditRequest, toggleDeleteRequest, toggleGender, toggleLoading, toggleEditAdsAdmin, toggleEditVideoAdmin, notSeenChatQTY, notSeenNotificationQTY, subSelected,
          toggleProfile, membership, toggleChat, toggleLiker, likerList, username, genderValue, userImg, lang, rtl, page, subUsername, 
          subGenderValue, subImageData, businessType, subBusinessType, bigImage, 
          siteType} = this.props
    const temColor = siteType!==2 ? headerColor1 : headerColor2
    const loaderAlert = <div className='loader-07' style={{margin: '', color:'#00CCFF', width:'100px', height:'100px', position:'absolute'}}></div>

    const loginImg = (
      <div className='d-flex justify-content-center'
          style={{margin: '0px', padding:'0px',
                borderRadius:'100px', alignItems:'center'}}>
          <img
              style={{width:'20px', height:'20px', borderRadius:'0px'}}
              src={require('./public/images/background/login.png')}
              alt="login"
          />
      </div>
    )
//☨
    var siteTypeXConst = this.siteTypeX()
    const versionX = <span style={{fontSize:'12px', margin:'0px'}}>{vn}</span>
    const logoX = (
      <Link to={`/${lang}`} className='center' style={{textDecoration:'none', width: w>320 ? '80px' : '40px', padding:'0px', direction:'ltr', flexDirection:'column', overflow:'hidden', alignItems:'center'}}>
        <h1 className={`center`} style={{ color:'#00CCFF', fontSize:'20px', fontWeight:'bold', margin:'0px 0px -5px ', alignItems:'center', whiteSpace:'nowrap' }}>
          <span>ET</span>&nbsp;
          {w>320 && versionX}
        </h1>
        <div className='rainbow' style={{height:'3px', width:'100%', margin:'3px 0px'}}>
        </div>
        <h2 className={`center`} style={{ color:'#ffd400', fontSize:w<350 ? '13px' : '14px', margin:'0px', width:'100%', whiteSpace:'nowrap'}}>
          <span>{siteTypeXConst}</span>
        </h2>
      </Link>
    )

    const UserImage = (
      <img
          className={`C${fc}`}
          style={{opacity: page==='user-panel' ? '1' : '1',filter: page==='user-panel' ? '' : 'grayscale(100%)', objectFit: 'contain', width:'30px', height:'30px', borderRadius:businessType===1 ? '3px' : '100px', border:'2px solid #ffffff40', margin:'0px', padding:'0px'}}
          src={
            !auth
            ? userN
            :((userImg!=='' && userImg!==undefined)
              ? userImg
              : genderValue===0 ? female : male)
          }
          alt="user"
      />
    )
    const subUserImage = (
      <img
          className={`C${subSelected ? subSelected[0] : ''}`}
          style={{objectFit: 'contain', width:"40px", height:"40px", borderRadius:subBusinessType===1 ? '3px' : '100px', border:'2px solid #ffffff40', margin:'0px 5px', padding:'0px'}}
          src={
            !auth
            ? userN
            :((subImageData!=='' && subImageData!==undefined)
              ? subImageData
              : subGenderValue===0 ? female : male)
          }
          alt="subuser"
      />
    )
    
    const UserLine = (
        <div className='d-flex' onClick={() => this.onToggle('user-panel')}
            style={{flexDirection:'column', margin: w<s ? '5px 10px 0px' : '5px 20px 0px', alignItems:'center'}}>
            <Link to={`/${lang}/user-panel`} className=''
              style={{textDecoration:'none', color:'#ffffff', alignItems:'center', flexDirection:'column', padding:'0px', margin:'0px'}}
              onClick={this.userImagePanel}>
              {UserImage}
            </Link>
            <span style={{fontSize:'10px', fontWeight: '', marginTop:'2px',  color: page==='user-panel' ? '#ffd400' : '#ffffff'}}>{setLT.userPanel}</span>
        </div>
    )

    const PSY = (
        <div className='d-flex' onClick={() => this.onToggle('character-card')}
            style={{flexDirection:'column', margin:'5px 5px 0px', alignItems:'center', filter: page==='character-card' ? '' : 'grayscale(100%)'}}>
            <Link to={`/${lang}`} className='character'
              style={{textDecoration:'none', width:'30px', height:'30px', color:'#ffffff', alignItems:'center', flexDirection:'column', borderRadius:'100px', border:'0px solid #ffffff50'}}
            >
            </Link>
            <span style={{fontSize:'10px', fontWeight: '', marginTop:'2px',  color: page==='character-card' ? '#ffd400' : '#ffffff'}}>PSY</span>
        </div>
    )

    const analysis = (
        <div className='d-flex' onClick={() => this.onToggle('character-analysis')}
            style={{flexDirection:'column', margin:'5px 15px 0px 10px', alignItems:'center', filter: page==='character-analysis' ? '' : 'grayscale(100%)'}}>
            <Link to={`/${lang}/character-analysis`} className='analysis'
              style={{textDecoration:'none', width:'30px', height:'30px', backgroundColor: page==='character-analysis' ? '#ffffff' : '#ffffff99', border: page==='character-analysis' ? '2px solid #ffd400' : '2px solid #ffffff', alignItems:'center', flexDirection:'column', borderRadius:'5px'}}
            >
            </Link>
            <span style={{fontSize:'10px', fontWeight: '', marginTop:'2px',  color: page==='character-analysis' ? '#ffd400' : '#ffffff'}}>Analysis</span>
        </div>
    )

    const subUserLine = (
      <Link to={`/${lang}/chat#!`} className='d-flex'
        style={{textDecoration:'none', color:'#ffffff', alignItems:'center', flexDirection:'', cursor:'pointer'}}
        onClick={this.subUserImagePanel}>
        {subUserImage}
        <div className='d-flex' style={{flexDirection:'column', }}>
            <div style={{margin:'0px 0px -5px ', fontSize:'13px'}}>{subUsername}</div>
            <div style={{fontSize:'13px'}}>{subStatus}</div>
        </div>
      </Link>
    )

    const UserLineX = (
      <div>
        {UserImage}{w>s && username}
      </div>
    )

    const MImage = (
      <img
          style={{objectFit: 'contain', width:"47px", height:"47px", borderRadius:businessType===1 ? '3px' : '100px', border:'1px solid #ffffff40', margin:'0px 5px', backgroundColor:'#ffffff50'}}
          src={ (userImg!=='' && userImg!==undefined)
              ? userImg
              : genderValue===0 ? female : male
          }
          alt="user male female"
      />
    )

    const MUserLineX = (
      <div>
        {MImage}{w>s && username}
      </div>
    )

    const backIcon = (
      <Link to={`/${lang}/color-sim`}  className='backIcon center' style={{width:'35px', height:'35px', borderRadius:'100px', alignItems:'center'}}>
        <MdArrowBack
            style={{textDecoration:'none', margin:'0px', fontSize:'20px', cursor:'pointer', color:'#ffffff'}}
        />
      </Link>
    )
    const dot = (
      <div style={{visibility:'hidden', margin:'10px  20px'}}>.</div>
    )

    const langBox = (
      <div className={ w<s ? "dropleft" : 'dropdown'} style={{padding:'0px', fontSize:'15px', margin:'3px 10px 0px', cursor:'pointer',}}>
        <div className='' color=''
                type="" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="false" aria-expanded="false"
                style={{margin:'0px', padding:'0px', borderRadius:'3px'}}>
                <div className='d-flex' style={{flexDirection:'column', alignItems:'center', fontSize:'13px'}}>
                    <div style={{marginBottom:'-3px'}}>{this.langText(lang)}</div>
                    <BsChevronCompactDown/>
                </div>
        </div>
        <div className="dropdown-menu animated fadeInUpX" aria-labelledby="dropdownMenuButton"
              style={{fontSize:'13px', cursor:'pointer', margin:'30px 0px 0px -20px'}}>
            <div className="dropdown-item" style={{backgroundColor: lang==='en' ? '#00194c' : '', color: lang==='en' ? '#ffffff' : ''}}
                onClick={() => this.changeLanguage('en')}>
                English
            </div>
            <div className="dropdown-item" style={{backgroundColor: lang==='fa' ? '#00194c' : '', color: lang==='fa' ? '#ffffff' : ''}}
                onClick={() => this.changeLanguage('fa')}>
                فارسی
            </div>
            <div className="dropdown-item" style={{backgroundColor: lang==='ar' ? '#00194c' : '', color: lang==='ar' ? '#ffffff' : ''}}
                onClick={() => this.changeLanguage('ar')}>
                العربية
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('tr')}
            >
                Türkçe
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('ru')}
            >
                Русский
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('de')}
            >
                Deutsch
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('fr')}
            >
                Français
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('es')}
            >
                Española
            </div>
            <div className="dropdown-item" style={{color:'#999999'}}
                // onClick={() => this.changeLanguage('zh')}
            >
                中文
            </div>
        </div>
      </div>
    )

    const signInBtnSmall = (
        <Link to={`/${lang}/login`} className='d-flex btnShadow waves-effect waves-light btn-large'
            style={{width:'', height:'30px', margin:'0px 10px', padding:'5px', textDecoration:'none', color:'#ffffff',
            fontSize:'14px', flexDirection:'', alignItems:'center', border:'2px solid #00CCFF',
            backgroundColor: '#ffffff50', borderRadius:'5px'}}>
            {loginImg}&nbsp;&nbsp;
            <div style={{marginTop:'0px'}}>{setLT.login}</div>
            {loaderAlert}
        </Link>
    )

    const sidebarIcon = (
      <div className='sticky-top' style={{marginTop:'0px'}} onClick={this.onSetSidebarOpen}>
        {/*  (access===1 && (pendingCommentsQTY!=='0' || pendingAdsQTY!=='0' || pendingRequestQTY!=='0' || pendingVideoQTY!=='0' ) || (notSeenNotificationQTY!=='0' || notSeenChatQTY!=='0')) &&
          <span style={{color:'#ff0000', margin:'-12px 0px 0px 3px', fontSize:'45px', width:'45px', height:'40px', position:'absolute', textAlign:'center', left:0, top:0}}
              className=''>
              •
          </span>
         */}
        <Fa icon='bars' className='sidebarIcon2'
        style={{verticalAlign:'middle', margin:'10px', color:'#ffffff'}}/>
      </div>
    )

    const storeIcon = (
      <div className='d-flex' onClick={() => this.onToggle('store')}
          style={{flexDirection:'column', alignItems:'center', margin: w<s ? '5px 20px 0px 15px' : '5px 20px 0px 15px'}}>
        <Link to={`/${lang}/store`} className='' style={{textDecoration:'none', padding:'0px'}}>
            <MdStore className='btnShadowX C14' style={{color:'#d40000', filter: page==='store' ? '' : 'grayscale(100%)', border:'2px solid #ffffff50', borderRadius:'3px', width:'30px', fontSize: '30px', margin: '0px 0px 0px', transform: 'scaleX(-1)'}}/>
        </Link>
        {/* <span style={{fontSize:'10px', fontWeight: page==='home' ? 'bold' : '', color: '#ffffff'}}>{setLT.store}</span> */}
        <span style={{fontSize:'10px', fontWeight: '', marginTop:'2px',  color: page==='store' ? '#ffd400' : '#ffffff'}}>{setLT.store}</span>

      </div>
    )

    const homeIcon = (
        <Link to={`/${lang}`}  className='box-c waves-effect waves-light btn-large' onClick={() => this.onToggle('home')}
            style={{width:'100%', height:'100%'}}>
            <div className='center' style={{flexDirection:'column', alignItems:'center', margin: '10px 0px -5px'}}>
                {page==='home'
                  ? <AiFillHome style={{color: w<s ? '#84e6ff' : '#ffffff', width:'23px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                  : <AiOutlineHome style={{color: '#ffffff', width:'23px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                }
            </div>
            <span className="custom-underline" style={{width:'90%', fontSize:'10px', fontWeight:'', color: page==='home' && w<s ? '#84e6ff' : '#ffffff', borderBottom: page==='home' && w>s ? '2px solid #ffffff' : ''}}>{setLT.home}</span>
        </Link>
    )

    const unsNotificationQTY = (
      <div className='sticky-top' style={{backgroundColor: 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', visibility:notSeenNotificationQTY==='0' ? 'hidden' : 'visible',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'-10px 0px 0px 20px'}}>
          {notSeenNotificationQTY}
      </div>
    )
    const notificationIcon = (
        <Link to={`/${lang}/notifications`}  className='box-c waves-effect waves-light btn-large' onClick={() => this.onToggle('notifications')}
            style={{width:'100%', height:'100%'}}>
            <div className='center' style={{flexDirection:'column', alignItems:'center', margin: '10px 0px -5px'}}>
                <div style={{position:'absolute'}}>{unsNotificationQTY}</div>
                {page==='notifications'
                  ? <FaBell style={{color: w<s ? '#84e6ff' : '#ffffff', width:'20px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                  : <FaRegBell style={{color: '#ffffff', width:'20px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                }
            </div>
            <span className="custom-underline" style={{width:'90%', fontSize:'10px', fontWeight:'', color: page==='notifications' && w<s ? '#84e6ff' : '#ffffff', borderBottom: page==='notifications' && w>s ? '2px solid #ffffff' : ''}}>{setLT.notifications}</span>
        </Link>
    )

    const postAds = (
        <Link to={`/${lang}/post-ads`}  className='box-c waves-effect waves-light btn-large' onClick={() => this.onToggle('post-ads')}
            style={{width:'100%', height:'100%'}}>
            <div className='center' style={{flexDirection:'column', alignItems:'center', margin: '9px 0px -5px'}}>
                {page==='post-ads'
                ? <BsPlusSquareFill style={{color: w<s ? '#84e6ff' : '#ffffff', width:'20px', fontSize: '30px', transform: 'scaleX(1)'}}/>
                : <BsPlusSquare style={{color: '#ffffff', width:'20px', fontSize: '30px', transform: 'scaleX(1)'}}/>
                }
            </div>
            <span className="custom-underline" style={{width:'90%', fontSize:'10px', fontWeight:'', color: page==='post-ads' && w<s ? '#84e6ff' : '#ffffff', borderBottom: page==='post-ads' && w>s ? '2px solid #ffffff' : ''}}>{setLT.postAds}</span>
        </Link>
    )

    const searchProducts = (
        <Link to={`/${lang}/search-product-and-service`}  className='box-c waves-effect waves-light btn-large' onClick={() => this.onToggle('search-product-and-service')}
            style={{width:'100%', height:'100%'}}>
            <div className='center' style={{flexDirection:'column', alignItems:'center', margin: '10px 0px -5px'}}>
                {page==='search-product-and-service'
                ? <RiSearch2Fill style={{color: w<s ? '#84e6ff' : '#ffffff', width:'22px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                : <RiSearch2Line style={{color: '#ffffff', width:'22px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                }
            </div>
            <span className="custom-underline" style={{width:'90%', fontSize:'10px', fontWeight:'', color: page==='search-product-and-service' && w<s ? '#84e6ff' : '#ffffff', borderBottom: page==='search-product-and-service' && w>s ? '2px solid #ffffff' : ''}}>{setLT.productListTitle}</span>
        </Link>
    )

    const categoryIcon = (
        <Link to={`/${lang}/category`}  className='box-c waves-effect waves-light btn-large' onClick={() => this.onToggle('category')}
            style={{width:'100%', height:'100%'}}>
            <div className='center' style={{flexDirection:'column', alignItems:'center', margin: '10px 0px -5px'}}>
                {page==='category'
                ? <BsGrid3X3GapFill style={{color: w<s ? '#84e6ff' : '#ffffff', width:'22px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                : <BsGrid3X3Gap style={{color: '#ffffff', width:'22px', fontSize: '28px', transform: 'scaleX(-1)'}}/>
                }
            </div>
            <span className="custom-underline" style={{width:'90%', fontSize:'10px', fontWeight:'', color: page==='category' && w<s ? '#84e6ff' : '#ffffff', borderBottom: page==='category' && w>s ? '2px solid #ffffff' : ''}}>{setLT.category}</span>
        </Link>
    )

    const footerItems = (
      <div className='d-flex' style={{width: w<s ? '100%' : '350px', padding:'0px', justifyContent:'space-between', alignItems:'center', direction:'ltr'}}>
        {homeIcon}
        {searchProducts}
        {postAds}
        {notificationIcon}
        {/* businessAds */}
        {/* purchaseRequest */}
        {categoryIcon}
      </div>
    )

    const sidebarIconBox = (
      <div className='d-flex' style={{alignItems:'center'}}>
        {sidebarIcon}
        {langBox}
        {/* !auth && signInBtnSmall */}
        {auth && UserLine}
        {siteType!==2 && storeIcon}
        {siteType===2 && analysis}
        {siteType===2 && PSY}

        <div style={{width:'50px'}}></div>
        <div className='d-flex' style={{width: w<s ? '100%' : '500px', padding:'0px 10px', justifyContent:'space-between', alignItems:'center', direction: rtl ? 'rtl' : 'ltr'}}>
          {homeIcon}
          {searchProducts}
          {postAds}
          {notificationIcon}
          {/* businessAds */}
          {/* purchaseRequest */}
          {categoryIcon}
        </div>
      </div>
    )

    const sidebarIconBoxM = (
      <div className='d-flex' style={{alignItems:'center'}}>
        {sidebarIcon}
        {langBox}
      </div>
    )

    const sidebarUserLine = (
      <div className='d-flex' style={{alignItems:'center'}}>
      {sidebarIcon}{UserLine}
      </div>
    )

    const DoubleUserLineBackRtl = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>{dot}{MUserLineX}{UserLineX}{backIcon}</div>//
    )
    const DoubleUserLineBackLtr = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>{backIcon}{MUserLineX}{UserLineX}{dot}</div>//
    )
    const SidebarUserLineDot = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>{dot}{UserLine}{dot}</div>//
    )
    const BackSubUserLineDotRtl = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>
          {sidebarUserLine}
          <span className='d-flex'>{subUserLine}{backIcon}</span>
      </div>
    )

    const BackUserLineDotRtl = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>{sidebarIcon}{UserLine}{backIcon}</div>//
    )
    const BackUserLineDotLtr = ( 
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%'}}>{backIcon}{UserLine}{sidebarIcon}</div>//
    )
    const logoDotRtl = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%', direction:'rtl'}}>{w<s ? sidebarIconBoxM : sidebarIconBox}{logoX}{/* memberQTYConst */}</div>//
    )
    const headerStyle = {height: NavH, color:'white', alignItems:'center', color:'#ffffff', backgroundImage: `linear-gradient(to right , ${temColor} , ${temColor})`, //, backgroundImage: 'linear-gradient(to right , #db5bff , #5b5bff)', 
                    justifyContent:'space-between', padding: '0px', top:'500px', transition:'.5s'}
    function headerItems (x) {
      var a
      switch (x) {
          case '' :
          case 'home' :
          case 'color-test' :
          case 'personality-analysis' :
          case 'color-sim' :
          case 'color-interest' :
          case 'register' :
          case 'login' :
          case 'dashboard-panel' :
          case 'comment' :
          case 'ads' :
          case 'all-ads' :
          case 'user-ads' :
          case 'ruby' :
          case 'user-comment' :
          case 'user-panel' :
          case 'search-member' :
          case 'liker-list' :
          case 'chat-list' :
          case 'block-list' :
          case 'istikhara' :
              a = logoDotRtl//rtl ? logoDotRtl : logoDotLtr
              break;
          case 'member-list' :
              a = rtl ? BackUserLineDotRtl : BackUserLineDotLtr
              break;
          case 'chat' :
          case 'chat#!' :
              a = BackSubUserLineDotRtl
              break;
          case 'understanding' :
          case 'follower' :
          case 'comment' :
          case 'request' :
          case 'yourCharacter' :
          case 'psychoTest' :
              a = SidebarUserLineDot
              break;
          case 'simUnd' :
              a = rtl ? DoubleUserLineBackRtl : DoubleUserLineBackLtr
              break;
          default: a = logoDotRtl

      }
      return a
    }

    const header = (
        <div className='sticky-top' style={{fontFamily:'Vazir'}}>
            <div className='cardShadowX' style={headerStyle}>
                <Container className='d-flex' style={{height:NavH, padding:w<s ? '0px 10px' : '0px', alignItems:'center'}}>
                    {headerItems(page)}
                </Container>
            </div>
        </div>
    )
//☨
    const sidebarHeader = (
      <div className='d-flex justify-content-center sticky-top'
          style={{backgroundImage: `linear-gradient(to right , ${temColor} , ${temColor})`, height: '90px',
                color:'white', top:'0px',
                flexDirection:'column', alignItems:'center'}}>
          <MdClose style={{width:'20px', fontSize:'20px', cursor:'pointer', position:'absolute',
                    top:'10px', right:rtl ? '10px' : '', left:rtl ? '' : '10px', border:'1px '}}
                    onClick={() => this.onSetSidebarOpen()}/>
          <div className='center' onClick={() => this.onGoWhoraly()}
              style={{flexDirection:'column', alignItems:'center'}}>
              <h1 className='' style={{ color:'#00CCFF', fontSize:'20px', margin:'0px 0px -5px 0px', fontWeight:'bold' }}>
                <span>ET</span>&nbsp;
                {versionX}
              </h1>

              <div className='rainbow' style={{height:'3px', width:'100%', margin:'5px 0px'}}>
              </div>
              <h2 className='' style={{ color:'#ffd400', fontSize:'14px', margin:'0px', direction:'ltr' }}>
                  whoraly Platform
              </h2>
          </div>
      </div>
    )

    const navItemsStyle = {textDecoration: "none", color:'#000000', alignItems:'center', height:'40px'}

    const userPanelBtn = (
      <Link to={`/${lang}/user-panel`} className={`d-flex ${page==='user-panel' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.userPanel()}>
          <FaUserEdit style={{width:'20px', margin:'10px 20px', fontSize:'20px', color:page==='user-panel' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color:page==='user-panel' ? '#ffffff' : '#00194c'}}>{setLT.userPanel}</div>
      </Link>
    )

    const homeBtn = (
      <Link to={`/${lang}`} className={`d-flex ${page==='character-cardx' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <Fa icon='home' style={{width:'17px', margin:'10px 20px', fontSize:'23px', color:page==='character-cardx' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='character-cardx' ? '#ffffff' : '#00194c'}}>{setLT.home}</div>
      </Link>
    )

    const aboutUsBtn = (
      <div className={`d-flex ${page==='about-us' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onGoWhoralyAbout()}>
          <BsFillInfoSquareFill style={{width:'17px', margin:'10px 20px', fontSize:'17px', color:page==='about-us' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='about-us' ? '#ffffff' : '#00194c'}}>{setLT.about}</div>
      </div>
    )

    const serviceBtn = (
      <Link to={`/${lang}/services`} className={`d-flex ${page==='services' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <GiShakingHands style={{width:'20px', margin:'10px 20px', fontSize:'20px', color:page==='services' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='services' ? '#ffffff' : '#00194c'}}>{setLT.services}</div>
      </Link>
    )

    const rulesBtn = (
      <Link to={`/${lang}/rules`} className={`d-flex ${page==='rules' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <FaBookOpen style={{width:'18px', margin:'10px 20px', fontSize:'18px', color:page==='rules' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='rules' ? '#ffffff' : '#00194c'}}>{setLT.rules}</div>
      </Link>
    )

    const pCommentQTY = (
      <div className='' style={{backgroundColor: pendingCommentsQTY==='0' ? '#00194c' : 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', position:'',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'0px 0px 0px 0px'}}>
          {pendingCommentsQTY}
      </div>
    )

    const pendingCommentsBtn = (
      <Link to={`/${lang}/dashboard-panel/comment`} className={`d-flex ${page==='comments-management' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <AiFillCheckSquare style={{width:'18px', margin:'10px 20px', fontSize:'18px', color:page==='comments-management' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='comments-management' ? '#ffffff' : '#00194c'}}>{setLT.memberComments}</div>
          {pCommentQTY}
      </Link>
    )

    const pAdsQTY = (
      <div className='' style={{backgroundColor: pendingAdsQTY==='0' ? '#00194c' : 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', position:'',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'0px 0px 0px 0px'}}>
          {pendingAdsQTY}
      </div>
    )

    const pendingAdsBtn = (
      <Link to={`/${lang}/dashboard-panel/advertising-management`} className={`d-flex ${page==='advertising-management' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <AiFillCheckSquare style={{width:'18px', margin:'10px 20px', fontSize:'18px', color:page==='advertising-management' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='advertising-management' ? '#ffffff' : '#00194c'}}>{setLT.businessAds}</div>
          {pAdsQTY}
      </Link>
    )

    const pRequestQTY = (
      <div className='' style={{backgroundColor: pendingRequestQTY==='0' ? '#00194c' : 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', position:'',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'0px 0px 0px 0px'}}>
          {pendingRequestQTY}
      </div>
    )

    const pVideoQTY = (
      <div className='' style={{backgroundColor: pendingVideoQTY==='0' ? '#00194c' : 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', position:'',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'0px 0px 0px 0px'}}>
          {pendingVideoQTY}
      </div>
    )

    const pendingRequestBtn = (
      <Link to={`/${lang}/dashboard-panel/request-management`} className={`d-flex ${page==='request-management' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <AiFillCheckSquare style={{width:'18px', margin:'10px 20px', fontSize:'18px', color:page==='request-management' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='request-management' ? '#ffffff' : '#00194c'}}>{setLT.purchaseRequests}</div>
          {pRequestQTY}
      </Link>
    )

    const pendingVideoBtn = (
      <Link to={`/${lang}/dashboard-panel/video-management`} className={`d-flex ${page==='video-management' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <AiFillCheckSquare style={{width:'18px', margin:'10px 20px', fontSize:'18px', color:page==='video-management' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='video-management' ? '#ffffff' : '#00194c'}}>{setLT.businessVideo}</div>
          {pVideoQTY}
      </Link>
    )

    const unsChatQTY = (
      <div className='' style={{backgroundColor: notSeenChatQTY==='0' ? '#00194c' : 'red', color: '#ffffff', fontSize:'13px', textAlign:'center', position:'',
          width: '18px', height: '18px', borderRadius: '100px', lineHeight: '20px', margin:'0px 0px 0px 0px'}}>
          {notSeenChatQTY}
      </div>
    )

    const membersBtn = (
      <Link to={`/${lang}/search-member`} className={`d-flex ${page==='search-member' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
          <RiUserSearchFill style={{width:'20px', margin:'10px 20px', fontSize:'20px', color:page==='search-member' ? '#ffffff' : '#00194c'}}/>
          <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='search-member' ? '#ffffff' : '#00194c'}}>{setLT.siteMembers}</div>
      </Link>
    )

    const subStyle = {fontSize:'14px', margin: '0px 10px 0px', alignItems:'center', direction: rtl ? 'ltr' : 'ltr'}
    const supportBtn = (
      <div>
          <div className={`d-flex`} style={navItemsStyle}>
              <BiSupport style={{width:'22px', margin:'10px 20px', fontSize:'22px', color:''}}/>
              <div style={{margin:rtl ? '10px 7px' : '13px 7px 10px', fontSize:'15px', color:''}}>{setLT.supportContacts}</div>
          </div>
          <div style={{margin: rtl ? '' : '5px 0px 20px 8px'}}>
              {/* <div className='d-flex' style = {subStyle}>
                  <IoLogoWhatsapp className='' style={{fontSize:'23px', margin:'0px', color:'#5ebc43'}}/>
                  <span style={{margin:'5px 10px 0px'}}>+98 913 790 87 97</span>
              </div> */}
              <div className='d-flex' style = {subStyle}>
                  <IoLogoWhatsapp className='' style={{fontSize:'23px', margin:'0px', color:'#5ebc43'}}/>
                  <span style={{margin:'5px 10px 0px'}}>+44 7513 340495</span>
              </div>
              <div className='d-flex' style = {subStyle}>
                  <MdEmail className='' style={{fontSize:'22px', margin:'0px', color:'#D54238'}}/>
                  <span style={{margin:'5px 10px 0px'}}>support@whoraly.com</span>
              </div>
          </div>
      </div>
    )

    const signInBtn = (
      <div  className={`d-flex sidebarItem`} style={navItemsStyle} onClick={() => this.onGoWhoralyLogin()}>{/*  onClick={() => this.setState({loginItems: !this.state.loginItems})} */}
          {/* n1 */}
          <div style={{margin:'0px 18px'}}>{loginImg}</div>
          <div className='' style={{width:'140px', margin:'10px', fontFamily:'Vazir', fontSize:'15px', border: !loginItems ? '2px solid #00CCFF' : '', borderRadius:'3px', padding: auth ? '' : '0px 0px', textAlign:!loginItems ? 'center' : '', color:'#00194c'}} onClick={() => this.onLogin()}>{setLT.signupLogin}{/* &nbsp;openIcon */}</div>
      </div>
    )

    const signOutBtn = (
      <Link to={`/${lang}`} className={`d-flex ${w<s ? '' : 'sidebarItem'}`} style={navItemsStyle} onClick={this.logout}>
          <Fa icon='sign-out' style={{width:'20px', margin:'10px 18px', fontSize:'20px', color: '#00194c'}}/>
          <div className='d-flex center' style={{width:'140px', margin:'10px', fontFamily:'Vazir', fontSize:'15px', border: '2px solid red', borderRadius:'3px', padding: auth ? '' : '0px 5px', alignItems:'center', color: '#00194c'}}>{setLT.exit}</div>
      </Link>
    )

    const dashboardPanelBtn = (
      <Link to={`/${lang}/dashboard-panel`} className={`d-flex ${page==='dashboard-panel' ? 'sidebarItemFix' : (w<s ? '' : 'sidebarItem')}`} style={navItemsStyle} onClick={() => this.onSetSidebarOpen()}>
        <MdDashboard style={{width:'20px', margin:'10px 20px', fontSize:'20px', color:page==='dashboard-panel' ? '#ffffff' : '#00194c'}}/>
        <div style={{margin:rtl ? '10px' : '13px 10px 10px', fontFamily:'Vazir', fontSize:'15px', color: page==='dashboard-panel' ? '#ffffff' : '#00194c'}}>{setLT.dashboardPanel}</div>
      </Link>
    )

    const hrB = <hr style={{width:'100%', margin:'5px -4px', backgroundColor: '#00194c', border: '2px solid #00194c'}}/>

    const sidebarItems = (
      <div style={{padding:'5px 0px', backgroundColor:'#ffffff00', fontWeight:450}}>
        {homeBtn}
        {aboutUsBtn}
        <div>
            {auth && <hr/>}
            {signInBtn}
        </div>

        {auth && 
            <div>
                {signOutBtn}
            </div>
        }
        <br/>
        {hrB}
        {supportBtn}
      </div>
    )

    const footerStyle = {backgroundImage: `linear-gradient(to right , ${temColor} , ${temColor})`, zIndex:'1000',
        color:'white', alignItems:'center', borderTop:'1px solid #', width:'100%', height:'50px', //w>s ? (wh===dh ? w-300 : w-300-10) : '100%'
        textAlign:'center', padding: '5px 0px', position:'fixed', bottom:0, right:0, left:0}
    const footer = (
        <div className='d-flex justify-content-center' style={footerStyle}>
            {siteType===2 ? <CardFooter/> : footerItems}
        </div>
    )

    const sidebarConst = (
        <div className='sticky-top cardShadow'
            style={{flex: '0 0 300px', minHeight: w<s ? window.innerHeight : window.innerHeight, backgroundImage: `url(${require("./public/images/background/worldTradeX.jpg")})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div style={{backgroundColor:'#ffffff99',}}>
                <div style={{minHeight: w<s ? window.innerHeight : window.innerHeight, backgroundColor:'#ffffff99',}}>
                    {sidebarHeader}
                    {sidebarItems}
                </div>
            </div>
        </div>
    )

    const modalSidebar = (
        <Modal isOpen={toggleSidebar}
            className=''
            toggle={() => this.onSetSidebarOpen()} //centered
            style={{backgroundColor:'#ffffff00', }}
            //fullHeight
            position = {rtl ? "right" : "left"}>
            <ModalBody style={{padding:'0px', }}>
                {sidebarConst}
            </ModalBody>
        </Modal>
    )

    const modalMembership = (
      <Modal isOpen={membership} toggle={() => this.toggleMembership()}>
          <ModalHeader style={{fontFamily:'Vazir', padding:'0px 10px', backgroundColor:'#ffffff99'}}>
            <MdClose style={{width:'20px', fontSize:'20px', cursor:'pointer'}} onClick={() => this.toggleMembership()}/>
          </ModalHeader>
          <ModalBody style={{textAlign: rtl ? 'right' : 'left', backgroundColor:'#ffffff99'}}>
            <div style={{backgroundColor:'', padding:'10px', borderRadius:'5px', border:'1px solid #999999'}}>
              <p>{setLT.mustSignUp}</p>
              <h6>{setLT.freeSignUp}</h6>
            </div>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-center' style={{textAlign: rtl ? 'right' : 'left', backgroundColor:'#ffffff99'}}>
            <span onClick={() => this.setModals()}>{signInBtn}</span>
          </ModalFooter>
      </Modal>
    )

    const likerListConst = (
      <div className="d-flex justify-content-center" style={{flexWrap: 'wrap' }}>
          {likerList}
      </div>
    )

    const modalLikerHeader =(
      <div className='d-flex sticky-top' style={{width:'100%' , height:'30px', justifyContent:'space-between', alignItems:'center', backgroundColor:'#f3f3fe', padding:'0px 10px'}}>
        <MdClose style={{width:'20px', fontSize:'20px', cursor:'pointer'}} onClick={() => this.onToggleLiker()}/>
        <div>{username}/Likers</div>
      </div>
    )

    const modalGender = (
      <Modal isOpen={toggleGender}
              toggle={() => this.onToggleGender()}
          >
          <div style={{marginBottom:'-20px'}}><Gender/></div>
      </Modal>
    )

    const modalLoading = (
      <Modal isOpen={toggleLoading} centered size='lg'
              toggle={() => this.onToggleLoading()}
              className='Cmodal'
          >
          <ModalBody className='center' style={{height:'200px', backgroundColor:'#ffffff00'}}>
              <ColorLoading/>
          </ModalBody>
      </Modal>
    )

    var backGXConst = this.backGX()
    const backG = (
        <img
            style={{position:'fixed', zIndex:'-1'}}
            height='100%'
            width='100%'
            src={require(`./public/images/background/${backGXConst}.jpg`)}
            alt="world background"
        />
    )

    const langClass = `d-flex btnShadow animated ${rtl ? 'fadeInRight' : 'fadeInLeft'} waves-effect waves-light btn-large`
    const flagStyle={objectFit: 'contain', width:'20px', height:'15px'}
    const flagStyleNull={objectFit: 'contain', width:'20px', height:'15px', opacity:'.5'}
    const enBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', animationDelay:'0s', border:'1px solid #00000090',
            backgroundColor: lang==='en' ? '#00194c' : '#ffffff',
            color: lang==='en' ? '#ffffff' : '#00194c'}}
            onClick={() => this.changeLanguage('en')}>
            <img src={enImg} alt='english flag' style={flagStyle}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>English</span>
        </div>
    )
    const faBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', animationDelay:'.2s', border:'1px solid #00000090',
            backgroundColor: lang==='fa' ? '#00194c' : '#ffffff', //justifyContent:'space-between',
            color: lang==='fa' ? '#ffffff' : '#00194c'}}
            onClick={() => this.changeLanguage('fa')}>
            <img src={faImg} alt='farsi flag' style={flagStyle}/>&nbsp;&nbsp;
            <span style={{marginTop:'0px'}}>فارسی</span>
        </div>
    )

    const arBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', animationDelay:'.2s', border:'1px solid #00000090',
            backgroundColor: lang==='ar' ? '#00194c' : '#ffffff', //justifyContent:'space-between',
            color: lang==='ar' ? '#ffffff' : '#00194c'}}
            onClick={() => this.changeLanguage('ar')}>
            <img src={arImg} alt='arabic flag' style={flagStyle}/>&nbsp;&nbsp;
            <span style={{marginTop:'0px'}}>العربية</span>
        </div>
    )

    const trBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'.6s', border:'1px solid #00000050',
            backgroundColor: lang==='tr' ? '#ffffff' : '#ffffff50'}}
            //onClick={() => this.changeLanguage('tr')}
            >
            <img src={trImg} alt='turkey flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>Türkçe</span>
        </div>
    )
    const ruBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'.8s', border:'1px solid #00000050',
            backgroundColor: lang==='ru' ? '#ffffff' : '#ffffff50'}}
            // onClick={() => this.changeLanguage('ru')}
            >
            <img src={ruImg} alt='russia flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>Русский</span>
        </div>
    )
    const deBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'1s', border:'1px solid #00000050',
            backgroundColor: lang==='de' ? '#ffffff' : '#ffffff50'}}
            // onClick={() => this.changeLanguage('de')}
            >
            <img src={deImg} alt='germany flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>Deutsch</span>
        </div>
    )
    const frBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'1.2s', border:'1px solid #00000050',
            backgroundColor: lang==='fr' ? '#ffffff' : '#ffffff50'}}
            // onClick={() => this.changeLanguage('fr')}
            >
            <img src={frImg} alt='france flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>Français</span>
        </div>
    )
    const esBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'1.4s', border:'1px solid #00000050',
            backgroundColor: lang==='es' ? '#ffffff' : '#ffffff50'}}
            // onClick={() => this.changeLanguage('es')}
            >
            <img src={esImg} alt='spain flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>Española</span>
        </div>
    )
    const zhBtn = (
        <div className={langClass}
            style={{width:'100px', height:'25px', alignItems:'center', margin:'5px', padding:'5px',
            borderRadius:'3px', color:'#444444', animationDelay:'1.6s', border:'1px solid #00000050',
            backgroundColor: lang==='zh' ? '#ffffff' : '#ffffff50'}}
            // onClick={() => this.changeLanguage('zh')}
            >
            <img src={zhImg} alt='china flag' style={flagStyleNull}/>&nbsp;&nbsp;
            <span style={{marginTop:'3px'}}>中文</span>
        </div>
    )

    const langs = (
      <div className='d-flex' style={{fontSize:'14px', width:'', overflow:'scroll'}}>
        <div className='d-flex' style={{height:'50px', alignItems:'', padding:'10px 0px', alignItems:'center'}}>
          {enBtn}
          {faBtn}
          {arBtn}
          {trBtn}
          {ruBtn}
          {deBtn}
          {frBtn}
          {esBtn}
          {zhBtn}
        </div>
      </div>
    )

    const langBar = (
        <div className='' style={{zIndex:'1', top:NavH}}>
            <Container className='d-flex'>
                {langs}
            </Container>
        </div>
    )

    const analysisBtn = (
      <div className='center' style={{margin:'10px'}}>
          <Link to={`/${lang}/character-analysis`} className='d-flex btnShadowX animated fadeInUpX' onClick={() => window.scrollTo(0, 0)}
                style={{textDecoration:'none', color:'#ffffff', padding:'5px', backgroundImage: `linear-gradient(to right , ${cardBackColor1} , ${cardBackColor2})`, margin:'30px 0px', border: '5px solid #FEED97', width:w<s ? '300px' : '300px', alignItems:'center', borderRadius:'100px'}}>
              <div className='analysis' style={{width:'50px', height:'50px', backgroundColor: '#ffffff', border: '2px solid #ffd400', borderRadius:'100px'}}></div>&nbsp;&nbsp;&nbsp;
              <div className="" style={{fontWeight: 450, width:''}}>{setLT.characterAnalysisChart}</div>
          </Link>
      </div>
    )

    const characterBtn = (
      <div className='center'>
          <Link to={`/${lang}`} className='d-flex btnShadowX animated fadeInUpX' onClick={() => window.scrollTo(0, 0)}
                style={{textDecoration:'none', color:'#ffffff', padding:'10px', backgroundImage: `linear-gradient(to right , ${cardBackColor1} , ${cardBackColor2})`, margin:'50px 0px 30px', border: '5px solid #FEED97', width:w<s ? '90%' : '350px', alignItems:'center', borderRadius:'100px'}}>
              <div className='character' style={{width:'50px', height:'50px', backgroundColor: '#ffffff', border: '2px solid #ffd400', borderRadius:'100px'}}></div>&nbsp;&nbsp;&nbsp;
              <div className="" style={{fontWeight: 450, width:''}}>{setLT.BCR}</div>
          </Link>
      </div>
    )

    const tools = (
      <div className='' style={{flexWrap:''}}>
        {(page!=='character-analysis' && page!=='istikhara') && analysisBtn}
        {/* (lang==='fa' && page!=='istikhara') && istikharaBtn */}
      </div>
    )

    const shortcutBtn = (
      <div className="center" style={{flexWrap:'wrap'}}>
        <PsychologyBtn/>
        <WhoralyBtn/>
      </div>
    )

    return (
        <Router>
            <div className='' style={{fontFamily: "Vazir", paddingBottom:'0px', minHeight:h, backgroundColor:'', direction: rtl ? 'rtl' : 'ltr'}}>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle}</title>
                    {/* <link rel="canonical" href="http://example.com" /> */}
                </Helmet>
                <div className=''>
                    <div className='d-flex' style={{width:'100%', flexDirection:'column'}}>
                        {backG}
                        {/* header */}
                        <div className={`sticky-top animated fadeInDown`} style={{top:0, zIndex:'10'}}><CardFooter/></div>
                        {page!=='istikhara' && langBar}
                        <div style={{}}>
                            {/* w<s && footer */}
                            <main style={{ marginTop: "0rem" }}><Routes/></main>
                            {page!=='login' && (siteType===1 ? (page==='home' ? characterBtn : '') : tools)}
                            {shortcutBtn}
                            <div style={{backgroundColor:'#ffffff00', width:'100%', padding:w<s ? '10px 0px' : '10px 0px'}}>
                                <ShareSite/>
                            </div>

                            {modalLoading}
                            {modalSidebar}
                            {modalMembership}
                            {modalGender}
                        </div>
                    </div>
                </div>
              </div>
        </Router>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userId: state.userInfo['_id'],
    username: state.userInfo['username'],
    fc: state.userInfo.fc,
    access: state.userInfo.access,
    businessType: state.userInfo.businessType,
    mainName: state.userInfo['mainName'],
    genderValue: state.userInfo['genderValue'],
    userImg: state.userInfo['imageData'],
    pageY: state.pageY,
    url: state.url,
    lang: state.lang,
    rtl: state.rtl,
    auth: state.auth,
    membership: state.membership,
    member: state.member,
    likerList: state.likerList,
    totalDataChart: state.totalDataChart,

    flag: state.flag,
    geo: state.geo,
    character: state.character,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    pageTitle: state.pageTitle,
    xtp: state.sim.xtp,
    sim: state.sim.simAve,
    und: state.und,
    subNavH: state.subNavH,

    chSp: state.chSp,
    chSpZ: state.chSpZ,
    subChSp: state.subChSp,
    subChSpZ: state.subChSpZ, 

    selected: state.colorsSelected,
    subSelected: state.subUserInfo.selected,
    subUser: state.subUser,
    subUserId: state.subUserInfo['_id'],
    subUserType: state.subUserInfo['subUserType'],
    subMainName: state.subUserInfo['mainName'],
    subUsername: state.subUserInfo['username'],
    subBusinessType: state.subUserInfo.businessType,
    subPassword: state.subUserInfo['password'],
    subEmail: state.subUserInfo['email'],
    subGenderValue: state.subUserInfo['genderValue'],
    subUserImg: state.subUserInfo['photo'],
    subBirthDate: state.subUserInfo['birthDate'],
    subMotherName: state.subUserInfo['motherName'],
    subCommonName: state.subUserInfo['commonName'],
    subImageData: state.subUserInfo['imageData'],
    userInfo: state.userInfo,
    subUserInfo: state.subUserInfo,
    requestIn: state.requestIn,
    requestOut: state.requestOut,
    followerN: state.follower,
    toggleProfile: state.toggleProfile,
    likeeInfo: state.likeeInfo,
    activityStatus: state.activityStatus,
    typingSecond: state.typingSecond,
    age: state.age,
    simList: state.simList,
    notSeenChatQTY: state.notSeenChatQTY,
    notSeenNotificationQTY: state.notSeenNotificationQTY,
    rubyQty: state.rubyQty,
    toggleShowAds: state.toggleShowAds,
    toggleShowVideo: state.toggleShowVideo,
    toggleShowRequest: state.toggleShowRequest,
    toggleUserShowAds: state.toggleUserShowAds,
    toggleUserShowRequest: state.toggleUserShowRequest,
    toggleSidebar: state.toggleSidebar,
    toggleChat: state.toggleChat,
    pageYOffset: state.pageYOffset,
    setLT: state.setLT,
    toggleEditAdsAdmin: state.toggleEditAdsAdmin,
    toggleEditVideoAdmin: state.toggleEditVideoAdmin,
    toggleEditRequestAdmin: state.toggleEditRequestAdmin,
    toggleNewAds: state.toggleNewAds,
    toggleNewVideo: state.toggleNewVideo,
    toggleNewRequest: state.toggleNewRequest,
    toggleEditAds: state.toggleEditAds,
    toggleEditVideo: state.toggleEditVideo,
    toggleEditRequest: state.toggleEditRequest,
    toggleDeleteAds: state.toggleDeleteAds,
    toggleDeleteRequest: state.toggleDeleteRequest,
    toggleGender: state.toggleGender,
    toggleLoading: state.toggleLoading,
    toggleSpecialAds: state.toggleSpecialAds,
    access: state.userInfo.access,
    bigImage: state.bigImage,
    fullAccess: state.fullAccess,
    siteType: state.siteType,
  }
}
export default connect (mapStateToProps)(App);
