module.exports = {
    s: 825,
    NavH: 60,
    langArray: ['fa', 'en', 'ar'],
    //subNavH: 70,
    headerColor1: '#00194c',
    headerColor2: '#5b5bff',
    totalBorder: '#9e5dff80',
    listRefreshQty: 10,
    listRefreshQtyBig: 50,
    listRefreshQtySmall: 3,
    adsSpace: 20,

    // local
    // sitekey:'6LcsaVcUAAAAAL7Onj_lTp7wYZyMpzK3ZXQ3xrDg',
    // secretkey:'6LcsaVcUAAAAAH__aEv9-X6agk1zQgCJ8v9PPr0K',

    // whoraly.com
    sitekey:'6LekfcIUAAAAADWhFzoiG8__B_cOuzn0-44pKUeM',
    secretkey:'6LekfcIUAAAAAGvJgAKYLXQwEiO9VezwkAR9B9NX',
    version:'-1.0.9',

    // درگاه پرداخت در وضعیت رد شده قرار دارد.
    // کد درگاه پرداخت
    // 0d46f80f-26f2-47d5-a7b2-b12c96f2b828

    loadingBar: {
        height: 10,
        color:'linear-gradient(to right , #db5bff, #f2e489)',
        //color:'linear-gradient(to left, yellow, orange, red, green, blue, indigo, violet)',
        L0: '#BAC4D4',
        L1: '#364CFF',
        L2: '#4CD45D',
        L3: '#FF9547',
        L4: '#f2e489',
        L5: '#B246D4',
        L6: '#824400',
        L7: '#000000',
        L8: '#ff002e',
        L9: '#D4A443',
        L10: 'rgb(253, 144, 224)',
        L11: '#ffffff',
        L12: '#00BFDD',
        L13: 'rgb(118, 120, 122)',
        L14: 'rgb(209, 164, 74)'
    },

    psyCard: {
        width: 250,
        subWidth: 260,
        cardBackColor1:'#ffffff',
        cardBackColor2:'#5b5bff',
    },

    greenLine: 5,
    redLine: -5,

    Lp1:5,
    Lp2:2.5,
    Lp3:-2.5,
    Lp4:-5,

    L1:250,
    L2:150,
    L3:-150,
    L4:-250,

    chart: {
        fontColor: '#000000',
        backColor: '#ffffff99',
        gridLinesColor: '#ededed90',
        zeroLineColor: '#999999',
        color0: '#31BA0D80',
        color1: '#9966ff80',
        color2: '#36a2eb80',
        color3: '#ffce5680',
        color4: '#ff9f4080',
        color5: '#ff638480',
        color6: '#ff000080',
        border0: '#31BA0D',
        border1: '#9966ff',
        border2: '#36a2eb',
        border3: '#ffce56',
        border4: '#ff9f40',
        border5: '#ff6384',
        border6: '#ff0000',
    },

    subChart: {
        fontColor: '#000000',
        backColor: '#ffffff',
        gridLinesColor: '#ededed',
        zeroLineColor: '#999999',
        color1: 'rgba(153, 102, 255, 1)',
        color2: 'rgba(54, 162, 235, 1)',
        color3: 'rgba(255, 206, 86, 1)',
        color4: 'rgba(255, 159, 64, 1)',
        color5: 'rgba(255,99,132,1)',
        border1: 'rgba(153, 102, 255, 1)',
        border2: 'rgba(54, 162, 235, 1)',
        border3: 'rgba(255, 206, 86, 1)',
        border4: 'rgba(255, 159, 64, 1)',
        border5: 'rgba(255,99,132,1)',
    },

        // chart: {
    //     fontColor: '#FDF9EF',
    //     backColor: '#000000',
    //     gridLinesColor: '#191919',
    //     zeroLineColor: '#616161',
    //     color0: '#31BA0D20',
    //     color1: 'rgba(153, 102, 255, 0.2)',
    //     color2: 'rgba(54, 162, 235, 0.2)',
    //     color3: 'rgba(255, 206, 86, 0.2)',
    //     color4: 'rgba(255, 159, 64, 0.2)',
    //     color5: 'rgba(255,99,132,0.2)',
    //     color6: '#ff000020',
    //     border0: '#31BA0D',
    //     border1: 'rgba(153, 102, 255, 1)',
    //     border2: 'rgba(54, 162, 235, 1)',
    //     border3: 'rgba(255, 206, 86, 1)',
    //     border4: 'rgba(255, 159, 64, 1)',
    //     border5: 'rgba(255,99,132,1)',
    //     border6: '#ff0000',
    // },

    // subChart: {
    //     fontColor: '#ffffff',
    //     backColor: '#00358C',
    //     gridLinesColor: '#19468e',
    //     zeroLineColor: '#1a6cef',
    //     color1: 'rgba(153, 102, 255, 1)',
    //     color2: 'rgba(54, 162, 235, 1)',
    //     color3: 'rgba(255, 206, 86, 1)',
    //     color4: 'rgba(255, 159, 64, 1)',
    //     color5: 'rgba(255,99,132,1)',
    //     border1: 'rgba(153, 102, 255, 1)',
    //     border2: 'rgba(54, 162, 235, 1)',
    //     border3: 'rgba(255, 206, 86, 1)',
    //     border4: 'rgba(255, 159, 64, 1)',
    //     border5: 'rgba(255,99,132,1)',
    // },

    shYY: [
        { value: 1300, label: 1300 },
        { value: 1301, label: 1301 },
        { value: 1302, label: 1302 },
        { value: 1303, label: 1303 },
        { value: 1304, label: 1304 },
        { value: 1305, label: 1305 },
        { value: 1306, label: 1306 },
        { value: 1307, label: 1307 },
        { value: 1308, label: 1308 },
        { value: 1309, label: 1309 },
        { value: 1310, label: 1310 },
        { value: 1311, label: 1311 },
        { value: 1312, label: 1312 },
        { value: 1313, label: 1313 },
        { value: 1314, label: 1314 },
        { value: 1315, label: 1315 },
        { value: 1316, label: 1316 },
        { value: 1317, label: 1317 },
        { value: 1318, label: 1318 },
        { value: 1319, label: 1319 },
        { value: 1320, label: 1320 },
        { value: 1321, label: 1321 },
        { value: 1322, label: 1322 },
        { value: 1323, label: 1323 },
        { value: 1324, label: 1324 },
        { value: 1325, label: 1325 },
        { value: 1326, label: 1326 },
        { value: 1327, label: 1327 },
        { value: 1328, label: 1328 },
        { value: 1329, label: 1329 },
        { value: 1330, label: 1330 },
        { value: 1331, label: 1331 },
        { value: 1332, label: 1332 },
        { value: 1333, label: 1333 },
        { value: 1334, label: 1334 },
        { value: 1335, label: 1335 },
        { value: 1336, label: 1336 },
        { value: 1337, label: 1337 },
        { value: 1338, label: 1338 },
        { value: 1339, label: 1339 },
        { value: 1340, label: 1340 },
        { value: 1341, label: 1341 },
        { value: 1342, label: 1342 },
        { value: 1343, label: 1343 },
        { value: 1344, label: 1344 },
        { value: 1345, label: 1345 },
        { value: 1346, label: 1346 },
        { value: 1347, label: 1347 },
        { value: 1348, label: 1348 },
        { value: 1349, label: 1349 },
        { value: 1350, label: 1350 },
        { value: 1351, label: 1351 },
        { value: 1352, label: 1352 },
        { value: 1353, label: 1353 },
        { value: 1354, label: 1354 },
        { value: 1355, label: 1355 },
        { value: 1356, label: 1356 },
        { value: 1357, label: 1357 },
        { value: 1358, label: 1358 },
        { value: 1359, label: 1359 },
        { value: 1360, label: 1360 },
        { value: 1361, label: 1361 },
        { value: 1362, label: 1362 },
        { value: 1363, label: 1363 },
        { value: 1364, label: 1364 },
        { value: 1365, label: 1365 },
        { value: 1366, label: 1366 },
        { value: 1367, label: 1367 },
        { value: 1368, label: 1368 },
        { value: 1369, label: 1369 },
        { value: 1370, label: 1370 },
        { value: 1371, label: 1371 },
        { value: 1372, label: 1372 },
        { value: 1373, label: 1373 },
        { value: 1374, label: 1374 },
        { value: 1375, label: 1375 },
        { value: 1376, label: 1376 },
        { value: 1377, label: 1377 },
        { value: 1378, label: 1378 },
        { value: 1379, label: 1379 },
        { value: 1380, label: 1380 },
        { value: 1381, label: 1381 },
        { value: 1382, label: 1382 },
        { value: 1383, label: 1383 },
        { value: 1384, label: 1384 },
        { value: 1385, label: 1385 },
        { value: 1386, label: 1386 },
        { value: 1387, label: 1387 },
        { value: 1388, label: 1388 },
        { value: 1389, label: 1389 },
        { value: 1390, label: 1390 },
        { value: 1391, label: 1391 },
        { value: 1392, label: 1392 },
        { value: 1393, label: 1393 },
        { value: 1394, label: 1394 },
        { value: 1395, label: 1395 },
        { value: 1396, label: 1396 },
        { value: 1397, label: 1397 },
        { value: 1398, label: 1398 },
        { value: 1399, label: 1399 },
        { value: 1400, label: 1400 },
        { value: 1401, label: 1401 },
        { value: 1402, label: 1402 },
        { value: 1403, label: 1403 },
        { value: 1404, label: 1404 },
        { value: 1405, label: 1405 },
    ],
    mlYY: [
        { value: 1900, label: 1900 },
        { value: 1901, label: 1901 },
        { value: 1902, label: 1902 },
        { value: 1903, label: 1903 },
        { value: 1904, label: 1904 },
        { value: 1905, label: 1905 },
        { value: 1906, label: 1906 },
        { value: 1907, label: 1907 },
        { value: 1908, label: 1908 },
        { value: 1909, label: 1909 },
        { value: 1910, label: 1910 },
        { value: 1911, label: 1911 },
        { value: 1912, label: 1912 },
        { value: 1913, label: 1913 },
        { value: 1914, label: 1914 },
        { value: 1915, label: 1915 },
        { value: 1916, label: 1916 },
        { value: 1917, label: 1917 },
        { value: 1918, label: 1918 },
        { value: 1919, label: 1919 },
        { value: 1920, label: 1920 },
        { value: 1921, label: 1921 },
        { value: 1922, label: 1922 },
        { value: 1923, label: 1923 },
        { value: 1924, label: 1924 },
        { value: 1925, label: 1925 },
        { value: 1926, label: 1926 },
        { value: 1927, label: 1927 },
        { value: 1928, label: 1928 },
        { value: 1929, label: 1929 },
        { value: 1930, label: 1930 },
        { value: 1931, label: 1931 },
        { value: 1932, label: 1932 },
        { value: 1933, label: 1933 },
        { value: 1934, label: 1934 },
        { value: 1935, label: 1935 },
        { value: 1936, label: 1936 },
        { value: 1937, label: 1937 },
        { value: 1938, label: 1938 },
        { value: 1939, label: 1939 },
        { value: 1940, label: 1940 },
        { value: 1941, label: 1941 },
        { value: 1942, label: 1942 },
        { value: 1943, label: 1943 },
        { value: 1944, label: 1944 },
        { value: 1945, label: 1945 },
        { value: 1946, label: 1946 },
        { value: 1947, label: 1947 },
        { value: 1948, label: 1948 },
        { value: 1949, label: 1949 },
        { value: 1950, label: 1950 },
        { value: 1951, label: 1951 },
        { value: 1952, label: 1952 },
        { value: 1953, label: 1953 },
        { value: 1954, label: 1954 },
        { value: 1955, label: 1955 },
        { value: 1956, label: 1956 },
        { value: 1957, label: 1957 },
        { value: 1958, label: 1958 },
        { value: 1959, label: 1959 },
        { value: 1960, label: 1960 },
        { value: 1961, label: 1961 },
        { value: 1962, label: 1962 },
        { value: 1963, label: 1963 },
        { value: 1964, label: 1964 },
        { value: 1965, label: 1965 },
        { value: 1966, label: 1966 },
        { value: 1967, label: 1967 },
        { value: 1968, label: 1968 },
        { value: 1969, label: 1969 },
        { value: 1970, label: 1970 },
        { value: 1971, label: 1971 },
        { value: 1972, label: 1972 },
        { value: 1973, label: 1973 },
        { value: 1974, label: 1974 },
        { value: 1975, label: 1975 },
        { value: 1976, label: 1976 },
        { value: 1977, label: 1977 },
        { value: 1978, label: 1978 },
        { value: 1979, label: 1979 },
        { value: 1980, label: 1980 },
        { value: 1981, label: 1981 },
        { value: 1982, label: 1982 },
        { value: 1983, label: 1983 },
        { value: 1984, label: 1984 },
        { value: 1985, label: 1985 },
        { value: 1986, label: 1986 },
        { value: 1987, label: 1987 },
        { value: 1988, label: 1988 },
        { value: 1989, label: 1989 },
        { value: 1990, label: 1990 },
        { value: 1991, label: 1991 },
        { value: 1992, label: 1992 },
        { value: 1993, label: 1993 },
        { value: 1994, label: 1994 },
        { value: 1995, label: 1995 },
        { value: 1996, label: 1996 },
        { value: 1997, label: 1997 },
        { value: 1998, label: 1998 },
        { value: 1999, label: 1999 },
        { value: 2000, label: 2000 },
        { value: 2001, label: 2001 },
        { value: 2002, label: 2002 },
        { value: 2003, label: 2003 },
        { value: 2004, label: 2004 },
        { value: 2005, label: 2005 },
        { value: 2006, label: 2006 },
        { value: 2007, label: 2007 },
        { value: 2008, label: 2008 },
        { value: 2009, label: 2009 },
        { value: 2010, label: 2010 },
        { value: 2011, label: 2011 },
        { value: 2012, label: 2012 },
        { value: 2013, label: 2013 },
        { value: 2014, label: 2014 },
        { value: 2015, label: 2015 },
        { value: 2016, label: 2016 },
        { value: 2017, label: 2017 },
        { value: 2018, label: 2018 },
        { value: 2019, label: 2019 },
        { value: 2020, label: 2020 },
        { value: 2021, label: 2021 },
        { value: 2022, label: 2022 },
        { value: 2023, label: 2023 },
        { value: 2024, label: 2024 },
        { value: 2025, label: 2025 },
    ],
    MM: [
        { value: 1, label: '01' },
        { value: 2, label: '02' },
        { value: 3, label: '03' },
        { value: 4, label: '04' },
        { value: 5, label: '05' },
        { value: 6, label: '06' },
        { value: 7, label: '07' },
        { value: 8, label: '08' },
        { value: 9, label: '09' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
    ],
    DD: [
        { value: 1, label: '01' },
        { value: 2, label: '02' },
        { value: 3, label: '03' },
        { value: 4, label: '04' },
        { value: 5, label: '05' },
        { value: 6, label: '06' },
        { value: 7, label: '07' },
        { value: 8, label: '08' },
        { value: 9, label: '09' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' },
        { value: 31, label: '31' }
    ]

}