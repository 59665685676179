import React, { Component } from 'react';
import { Container, Fa } from "mdbreact";
import axios from 'axios';
import { Link } from "react-router-dom";
import Chart from 'chart.js';
import 'chartjs-plugin-annotation';
import { connect } from 'react-redux';
// import "./../../public/css/alerts.css";
// import './../../public/css/SelectColor.css';
import { subject, siteType, pageTitle, genderChange, toggleGender, pageName, chSp, page } from '../../dataStore/actions';
import ReactResizeDetector from 'react-resize-detector';
import attributes from '../../langText/attributes';
import definition from '../../langText/definition';
import LoadingBar from 'react-top-loading-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
// import CardCompelete from '../../components/CardCompelete';
import BackToCharacterCards from '../../components/Psychology/BackToCharacterCards';
import Gender from '../../components/Gender';
import { } from 'react-icons/md';
import { } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';
import abjadCalculator from '../../modules/abjadCalculator';
import srcSet from '../../srcSet';
import siteView from '../../modules/siteView';

var s = srcSet.s
var NavH = srcSet.NavH
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

var chartFontColor = srcSet.chart.fontColor
var chartBackColor = srcSet.chart.backColor
var chartGridLinesColor = srcSet.chart.gridLinesColor
var chartZeroLineColor = srcSet.chart.zeroLineColor
var chartColor0 = srcSet.chart.color0
var chartColor1 = srcSet.chart.color1
var chartColor2 = srcSet.chart.color2
var chartColor3 = srcSet.chart.color3
var chartColor4 = srcSet.chart.color4
var chartColor5 = srcSet.chart.color5
var chartColor6 = srcSet.chart.color6
var chartBorder0 = srcSet.chart.border0
var chartBorder1 = srcSet.chart.border1
var chartBorder2 = srcSet.chart.border2
var chartBorder3 = srcSet.chart.border3
var chartBorder4 = srcSet.chart.border4
var chartBorder5 = srcSet.chart.border5
var chartBorder6 = srcSet.chart.border6

var subChartFontColor = srcSet.subChart.fontColor
var subChartGridLinesColor = srcSet.subChart.gridLinesColor
var subChartZeroLineColor = srcSet.subChart.zeroLineColor

class CharacterAnalysisPage extends Component { 

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.characterAnalysis,
    dataChart:[],
    complete: 0,
    notComplete: 15, //0,
    p0: 0,
    p1: 0,
    p2: 0,
    p31: 0,
    p32: 0,
    p4: 0,
    p5: 0,
    p6: 0,
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
      siteView(this.props)
    } else {
      // await this.props.dispatch(pageName(this.props.setLT.characterAnalysis))
      await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
      await this.props.dispatch(page('character-analysis'))
      await this.props.dispatch(subject('character-analysis'))
      await this.props.dispatch(siteType(2))
      // console.log(this.state.pageName)
      if(this.state.pageName===undefined) await window.location.reload()

      siteView(this.props)
      await this.completeChart()
      await this.makeChart()
    }
  }

  onResize = () => {
    this.setState({
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  completeChart = async () => {
    const { rtl, lang } = this.props;
    const { complete, notComplete } = this.state;
      document.getElementById("completeChartContainer").innerHTML = '&nbsp;';
      document.getElementById("completeChartContainer").innerHTML = '<canvas id="completeChart"></canvas>';
      var ctx = document.getElementById("completeChart").getContext("2d");

      var xSize = 120
      ctx.canvas.width = xSize;
      ctx.canvas.height = xSize;

      new Chart(ctx, {
        type: 'doughnut',
        data : {
          datasets: [{
              backgroundColor : ['#3bf503', '#f3f3f300'],
              data: [complete, notComplete]
          }]
        },
        options: {
          animation: {
            duration: 0 // general animation time
          },
          tooltips: {
            enabled: false
          },
          cutoutPercentage: 70,
          chartArea: {
            // backgroundColor: 'transparent'
          },
          responsive:true,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                //fontSize: 7,
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                display: false,
                color: "#ffffff",
                lineWidth:2
              }
            }],
            yAxes: [{
              display: false,
              position: rtl? 'right' : 'left', 
              ticks: {
                fontColor : "#e6e6e6",
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                color: "#ffffff",
                lineWidth:1,
                zeroLineColor: '#616161'
              }
            }]
          }
        }
      })  

  }

  setComplete = async () => {
    const { complete, notComplete } = this.state;
    this.setState({
      complete: complete + 1 ,
      notComplete: notComplete - 1 ,
    })
    this.completeChart()
    // console.log(this.state.complete, this.state.notComplete)
  }

  makeChart = async () => {
      const {abjad, astrology, colors, faceShape, lips, ears, eyes, eyeColor, cheeks, chin, forehead,
        eyebrows, nose, bodyShape, bloodType, sCount, nsCount} = this.props.PSYInfo.card
      // console.log(this.props.PSYInfo.card)
      // const {notComplete} = this.state

      this.setState({loader:true})
      const items = []
      if(colors===1) {
        items.push('colors')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(abjad===1) {
        items.push('abjad')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(astrology===1) {
        items.push('astrology')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(cheeks===1) {
        items.push('cheeks')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(chin===1) {
        items.push('chin')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(ears===1) {
        items.push('ears')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(eyebrows===1) {
        items.push('eyebrows')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(eyeColor===1) {
        items.push('eyeColor')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(eyes===1) {
        items.push('eyes')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(faceShape===1) {
        items.push('faceShape')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(forehead===1) {
        items.push('forehead')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(lips===1) {
        items.push('lips')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(nose===1) {
        items.push('nose')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(bloodType===1) {
        items.push('bloodType')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }
      if(bodyShape===1) {
        items.push('bodyShape')
        // this.setState({ notComplete: this.state.notComplete + 1 })
      }

      var txData = []
      var PSYInfoX = this.props.PSYInfo
      for(var i=0; i<items.length; i++) {
          var dataX = PSYInfoX[items[i]]
          var d = []
          if(items[i]==='colors') {
              // console.log(items[i])
              var a = this.props.colorsDataChart
              // console.log(items[i], a)
              var ds = a.map(({ score }) => score)
              var mx = Math.max(...ds)
              for(var x=0; x<a.length; x++) {
                  var sx = a[x].score
                  d[x] = {aCode: a[x].aCode, score: (sx/mx)*2}
                  // console.log(mx, sx, (sx/mx)*2)
              }
              // console.log(items[i], d[x])
          } else if(items[i]==='abjad') {
              const {commonName, motherName} = this.props.PSYInfo.abjad
              var xAbjad = commonName + motherName
              await abjadCalculator(xAbjad).then(res=>{
                  this.setState({ abjadItemNo: res })
              })
              const user = {
                fakeId: this.props.PSYInfo.fakeId,
                genderValue: this.props.PSYInfo.genderValue,
                commonName: commonName,
                motherName: motherName,
                itemNo: this.state.abjadItemNo
              }
              // console.log(user)
              await axios.post('http://localhost:4200/abjad/update', user).then(res => {});
              await axios.get('http://localhost:4200/abjad/chart/' + user.fakeId).then(res => {
                this.setState({dataChart:res.data})
              });
              await axios.get('http://localhost:4200/abjad/delete/' + user.fakeId).then(res => {});
              
              var a = this.state.dataChart
              for(var x=0; x<a.length; x++) d[x] = {aCode: a[x].aCode, score: a[x].score}

              // console.log(items[i], d[x])

          } else if(items[i]==='astrology') {

              const user = {
                fakeId: this.props.PSYInfo.fakeId,
                genderValue: this.props.PSYInfo.genderValue,
                itemNo: this.props.PSYInfo.astrology.m.value
              }
              // console.log(items[i], user)
  
              await axios.post('http://localhost:4200/astrology/update', user).then(res => {});
              await axios.get('http://localhost:4200/astrology/chart/' + user.fakeId).then(res => {
                this.setState({dataChart:res.data})
              });
              await axios.get('http://localhost:4200/astrology/delete/' + user.fakeId).then(res => {});
                    
              var a = this.state.dataChart
              for(var x=0; x<a.length; x++) d[x] = {aCode: a[x].aCode, score: a[x].score}
              // console.log(items[i], d[x])
          } else {
              for(var ix=0; ix<dataX.length; ix++) {
                dataX[ix].genderValue=PSYInfoX.genderValue
              }
              // console.log(items[i], dataX)
              await axios.post(`http://localhost:4200/${items[i]}/add`, dataX).then(async res => {});
              await axios.get(`http://localhost:4200/${items[i]}/chart/` + this.props.PSYInfo.fakeId).then(async res => {
                // this.props.dispatch(faceShapeDataChart(res.data))
                this.setState({dataChart:res.data})
                // console.log(items[i], res.data)
              });
      
              await axios.get(`http://localhost:4200/${items[i]}/delete/` + this.props.PSYInfo.fakeId).then(async res => {});
      
              var a = this.state.dataChart
              for(var x=0; x<a.length; x++) d[x] = {aCode: a[x].aCode, score: a[x].score}
              // console.log(items[i], d[x])
          }
  
          var txData = txData.concat(d)
          await this.totalChart(txData)
          await this.setComplete()
          
      }
      this.setState({loader:false})
  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }

  totalChart = async (txData) => {

      let accumulation = txData.reduce((total, val, index)=>{
        let foundItemIndex = total.findIndex((obj)=>obj.aCode == val.aCode);
        if(foundItemIndex < 0) total.push(val) 
        else total[foundItemIndex].score += val.score;
        return total;
      }, []);
      // console.log(123, accumulation)

      var xt = accumulation
      var {rtl, lang, subUser} = this.props
      if(rtl) {
        xt.sort((a, b) => (a.score > b.score) ? 1 : -1)
      } else {
        xt.sort((a, b) => (a.score > b.score) ? -1 : 1)
      }

      for (var i = 0; i < xt.length; i++) {
        var cx = xt[i]['aCode']
        xt[i]['character']=attributes[cx][lang]
        xt[i]['definition']=definition[cx][lang]
      }

      var d = xt.map(({ score }) => score)
      var n = xt.map(({ character }) => character)
      var df = xt.map(({ definition }) => definition)

      var A = []
      var B = []
      for(var i=0; i<d.length; i++) {
        if(d[i]>0) {
          A.push(d[i])
        } else {
          B.push(d[i])
        }
      }

      var average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
      var greenLine = average( A )
      var redLine = average( B )

      var p0Data = []
      var p1Data = []
      var p2Data = []
      var p31Data = []
      var p32Data = []
      var p4Data = []
      var p5Data = []
      var p6Data = []

      var colors = []
      var borders = []
      var labels = []

      var p0=0; var p1=0; var p2=0; var p31=0; var p32=0; var p4=0; var p5=0; var p6=0;
      var G = greenLine*2//srcSet.greenLine
      var R = redLine*2.8//srcSet.redLine  
      var L1 = G/1.5
      var L2 = G/2
      var L3 = R/2
      var L4 = R/1.5
      // console.log(123, L1)

      for(i = 0; i < d.length; i++){
        var color;
        var border;
        var label;
        var x = d[i]
        // console.log(x)
        switch(true){
          case (x > G):
            color = chartColor0;
            border = chartBorder0;
            p0 = p0 + 1//Math.abs(x)
            p0Data.push(xt[i])
            // console.log(p0Data)
            break;
          case (L1 < x && x <= G):
            color = chartColor1;
            border = chartBorder1;
            p1 = p1 + 1//Math.abs(x)
            p1Data.push(xt[i])
            break;
          case (L2 < x && x <= L1):
            color = chartColor2;
            border = chartBorder2;
            p2 = p2 + 1//Math.abs(x)
            p2Data.push(xt[i])
            break;
          case (x >= 0 && x <= L2):
            color = chartColor3;
            border = chartBorder3;
            p31 = p31 + 1//Math.abs(x)
            p31Data.push(xt[i])
            break;
          case (L3 <= x && x < 0):
            color = chartColor3;
            border = chartBorder3;
            p32 = p32 + 1//Math.abs(x)
            p32Data.push(xt[i])
            break;
          case (L4 <= x && x < L3):
            color = chartColor4;
            border = chartBorder4;
            p4 = p4 + 1//Math.abs(x)
            p4Data.push(xt[i])
            break;
          case (R <= x && x < L4):
            color = chartColor5;
            border = chartBorder5;
            p5 = p5 + 1//Math.abs(x)
            p5Data.push(xt[i])
            break;
          case (x < R):
            color = chartColor6;
            border = chartBorder6;
            p6 = p6 + 1//Math.abs(x)
            p6Data.push(xt[i])
            // console.log(p6Data)
            break;
          default:
            color = 'rgba(0, 0, 0, 0)';
            border = 'rgba(0, 0, 0, 0)';
        }
          colors[i] = color;
          borders[i] = border;
          labels[i] = label;
          //console.log(33333, p0, p1, p2, p3, p4, p5, p6)

        }
        var ds = p0+p1+p2+p31+p32+p4+p5+p6
        var p0x= Number(((p0/ds)*100).toFixed(0));
        var p1x= Number(((p1/ds)*100).toFixed(0));
        var p2x= Number(((p2/ds)*100).toFixed(0));
        var p3x= Number((((p31+p32)/ds)*100).toFixed(0));
        var p4x= Number(((p4/ds)*100).toFixed(0));
        var p5x= Number(((p5/ds)*100).toFixed(0));
        var p6x= Number(((p6/ds)*100).toFixed(0));
        // console.log(44444, p0x, p1x, p2x, p3x, p4x, p5x, p6x)

        const chartDataB = {
        type: 'bar',
        data: {
          labels: n,
          definitions: df,
          datasets : [
            {
              backgroundColor : colors,
              borderColor : borders,
              borderWidth: 1,
              data : d
            }
          ]
        },
        plugins: [{
          beforeDraw: function(c) {
            // var chartWidth = c.chart.width;
            // var chartHeight = chartWidth / 3;
            // var size = chartHeight * 5 / 190;
            c.scales['x-axis-0'].options.ticks.minor.fontSize = 13;
            c.scales['y-axis-0'].options.ticks.minor.fontSize = 13;
            //c11.chart.canvas.parentNode.style.height = pxChartHeight.toString();

            if (c.config.options.chartArea && c.config.options.chartArea.backgroundColor) {
              var ctx = c.chart.ctx;
              var chartArea = c.chartArea;
  
              ctx.save();
              ctx.fillStyle = c.config.options.chartArea.backgroundColor;
              ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
              ctx.restore();
            }
          }
        }],
        options: {
          tooltips: {
            titleAlign: 'right',
            titleFontFamily: "Vazir",
            titleFontStyle: 'normal',
            bodyFontFamily: "Vazir",
            bodyFontStyle: 'normal',
            footerAlign: 'right',
            footerFontFamily: "Vazir",
            footerFontStyle: 'normal',
            footerFontColor: '#FEED97',
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                    label += ': ';
                }
                //console.log(123, data.definitions[tooltipItem.datasetIndex])
                label += ''//"دارای شخصیت دوتایی - ناپایدار و نا متعادل در اخلاق - مدام در حال تغییر حوصله و حال و هوای خود هستند - هر لحظه امکان پرخاشگری و عصیان در این افراد وجود دارد - آنها برای جنس مخالف می توانند یک آن بسیار هیجان انگیز و گرم و ایده آل باشند و یک آن سرد و یخ و بی تفاوت و گریزان - یا منفی کامل هستند یا مثبت کامل - راه و روش خود را به طور دائم تغییر میدهند - گاهی شاد و گاهی غمگین - گاهی آرام و گاهی طوفانی - گاهی پر حرف و گاهی خاموش- اهل قهر و آشتی - گاهی خوب و گاهی بد - یک روز گرم و یک روز سرد - گاه بسیار ولخرج و گاه بسیار خسیس - به هیچ عنوان حد وسط ندارند."//data.definitions[tooltipItem.datasetIndex]//Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
              },
              // footer: function(tooltipItem, data) {
              //   var x = tooltipItem[0]['index'];
              //   var footer = data.definitions[x].split (' - ');
              //   //console.log(123, data.definitions[x].split (' - '))
              //   return footer; //('---------' + '/n' + footer).split('/n');
              // }

            }
          },
          chartArea: {
            //backgroundColor: '#FFFFFF'
          },
          responsive: false,
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                //fontSize: 7,
                fontFamily: "Vazir",
                fontColor : subUser ? subChartFontColor : chartFontColor,
                autoSkip: false,
                beginAtZero: true,
              },
              gridLines:{
                color: subUser ? subChartGridLinesColor : chartGridLinesColor,
                lineWidth:1
              }
            }],
            yAxes: [{
              position: rtl? 'right' : 'left',
              ticks: {
                fontColor : subUser ? subChartFontColor : chartFontColor,
                autoSkip: false,
                beginAtZero:true,
                display: false
              },
              gridLines:{
                color: subUser ? subChartGridLinesColor : chartGridLinesColor,
                lineWidth:1,
                zeroLineColor: subUser ? subChartZeroLineColor : chartZeroLineColor,
              }
            }]
          },
          annotation: {
            annotations: [{
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: G,
              borderColor: '#31BA0D',
              borderWidth: 3,
              label: {
                enabled: false,
                content: 'Test label'
              }
            },{
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: R,
              borderColor: '#ff0000',
              borderWidth: 3,
              label: {
                enabled: false,
                content: 'Test label'
              }
            }]
          },
          onClick: function (c,i) {
            var e = i[0];
            if(e!==undefined) {
              //console.log(e._index)
              var x_value = this.data.labels[e._index];
              var y_value = this.data.datasets[0].data[e._index];
              var c_value = this.data.definitions[e._index];
              // console.log(111,x_value);
              // console.log(222,y_value);
              // console.log(333,c_value);
              Swal({
                title: x_value,
                text: c_value,
                animation: false,
                customClass: 'animated fadeInDown swal-title swal2-text',
                // onClose: function(modal) {
                //   modal.className += ' animated fadeInDown';
                // }
              })
              //alert(x_value + "\n" + c_value)
            }
            //return c_value
          }
        }
        //this.setState({xx:chartDataB.options.onClick})
      }

      //console.log(666,this.state.w)

      if(document.getElementById("totalChartInContainer")) {
        document.getElementById("totalChartInContainer").innerHTML = '&nbsp;';
        document.getElementById("totalChartInContainer").innerHTML = '<canvas id="totalChartIn"></canvas>';
        var ctxB = document.getElementById("totalChartIn").getContext("2d");
        ctxB.canvas.height = 250;
        ctxB.canvas.width = (this.state.w<s ? xt.length<=5 : xt.length<=50) ? this.state.w : xt.length*25;
        new Chart(ctxB, chartDataB)
        // this.LoadingBar.complete()
      }

      if(rtl) {
        p0Data.reverse()
        p1Data.reverse()
        p2Data.reverse()
        p31Data.reverse()
      } else {
        p32Data.reverse()
        p4Data.reverse()
        p5Data.reverse()
        p6Data.reverse()
      }

      var maxp0 = p0Data[0] ? p0Data[0].score : 0
      var maxp1 = p1Data[0] ? p1Data[0].score : 0
      var maxp2 = p2Data[0] ? p2Data[0].score : 0
      var maxp31 = p31Data[0] ? p31Data[0].score : 0
      var minp32 = p32Data[0] ? p32Data[p32Data.length-1].score : 0
      var minp4 = p4Data[0] ? p4Data[p4Data.length-1].score : 0
      var minp5 = p5Data[0] ? p5Data[p5Data.length-1].score : 0
      var minp6 = p6Data[0] ? p6Data[p6Data.length-1].score : 0
      var maxPX = Math.max(maxp0, maxp1, maxp2, maxp31, -minp32, -minp4, -minp5, -minp6)
      // console.log('maxPX', maxPX, maxp0, -minp6)

      const definitionIcone = (
        <div className=''
            style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
            <FaInfoCircle/>
        </div>
      )
  
      var p
      var p0ChartData = p0Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            // console.log(1000000000000, item.score,maxPX),

            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'#31BA0D', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p1ChartData = p1Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'#9966ff', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p2ChartData = p2Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'#36a2eb', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p31ChartData = p31Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'#ffce56', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p32ChartData = p32Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'#ffce56', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p4ChartData = p4Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'#ff9f40', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
      // console.log(p4Data.reverse())
      var p5ChartData = p5Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'#ff6384', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      var p6ChartData = p6Data.map(
        (item, i) => (
            p = (item.score/maxPX)*100,
            <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
                <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left', alignItems:'center', justifyContent:'space-between'}}>
                  {item.character}
                  <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
                </span>
                <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
                <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'#ff0000', direction:rtl ? 'rtl' : 'ltr'}}/>
                <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
            </div>
        )
      )
  
      this.props.dispatch(chSp([p0x, p1x, p2x, p3x, p4x, p5x, p6x]))
      this.setState({
        ds, p0, p1, p2, p31, p32, p4, p5, p6,
        p0ChartData, p1ChartData, p2ChartData, p31ChartData, p32ChartData, p4ChartData, p5ChartData, p6ChartData, 
      })

  }

  onGenderChenge = async () => {
    await this.props.dispatch(genderChange(''))
    window.location.reload();
    // console.log(11)
  }

  render() {
    const {w, h, ds, p0, p1, p2, p31, p32, p4, p5, p6,
      p0ChartData, p1ChartData, p2ChartData, p31ChartData, p32ChartData, p4ChartData, p5ChartData, p6ChartData,
      complete, notComplete, loader} = this.state
    const {rtl, auth, setLT, member, lang, genderChange, PSYInfo, 
    } = this.props;

    if(genderChange==='character-analysis-Gender') this.onGenderChenge()

    const loaderX = <div className='loader-05' style={{width:'70px', height:'70px', margin: '0px 0px 0px 0px', color:'#ffffff'}}></div>
    const nCard = (complete===0 && notComplete===0) ? 0 : complete/(complete+notComplete)*100
    const chartx = (
      <div style={{ backgroundColor:'#ffffff00'}}>
        <div className="canvas center" id="completeChartContainer" style={{backgroundColor: '#ffffff00'}}>
          <canvas id="completeChart"></canvas>
        </div>
      </div>
    )

    const analysisPercentage = (
      <div style={{width:'120px'}}>
        <div className='center tx' style={{margin:'10px 10px -105px', fontWeight:'bold', fontSize:'20px', width:'100px', height:'100px', backgroundColor:'#226ee899', borderRadius:'100px', border:'0px solid #3bf503', alignItems:'center'}}>
          <span style={{color:'#ffffff'}}>{nCard.toFixed(0)}%</span>
        </div>
        {chartx}
        <div style={{margin:rtl ? '-100px 25px 0px 0px' : '-100px 0px 0px 25px', position:'absolute'}}>{loader ? loaderX : ''}</div>
      </div>
    )

    var p0x = this.props.chSp[0] ? this.props.chSp[0] : 0
    var p1x = this.props.chSp[1] ? this.props.chSp[1] : 0
    var p2x = this.props.chSp[2] ? this.props.chSp[2] : 0
    var p3x = this.props.chSp[3] ? this.props.chSp[3] : 0
    var p4x = this.props.chSp[4] ? this.props.chSp[4] : 0
    var p5x = this.props.chSp[5] ? this.props.chSp[5] : 0
    var p6x = this.props.chSp[6] ? this.props.chSp[6] : 0

    const p0T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'#31BA0D'}}/>
          <span>{setLT.p0Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p0})</span>{/* {p0.toFixed(0)}٪ */}
      </div>
    )
    const p1T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'rgba(153, 102, 255,1)'}}/>
          <span>{setLT.p1Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p1})</span>
      </div>
    )
    const p2T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'rgba(54, 162, 235,1)'}}/>
          <span>{setLT.p2Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p2})</span>
      </div>
    )
    const p3T = (
      <div className='container1' style={{margin: '0px 0px', padding: '0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'rgba(255, 206, 86,1)'}}/>
          <span>{setLT.p3Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p31+p32})</span>
      </div>
    )
    const p4T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'rgba(255, 159, 64,1)'}}/>
          <span>{setLT.p4Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p4})</span>
      </div>
    )
    const p5T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'rgba(255,99,132,1)'}}/>
          <span>{setLT.p5Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p5})</span>
      </div>
    )
    const p6T = (
      <div className='container1' style={{margin: '5px 0px 0px 0px'}}>
          <Fa icon="circle" style={{ fontSize: '18px', margin: rtl ? '2px 0px 0px 10px' : '0px 10px 0px 0px', color:'#ff0000'}}/>
          <span>{setLT.p6Title}</span>
          <span style={{margin: rtl ? '0px 5px 0px 0px' : '0px 0px 0px 5px'}}>({p6})</span>
      </div>
    )

    const chSpStyle={border:'1px solid #000000', margin:'0px 0px 0px', padding:'5px', width:'165px', borderRadius:'3px', }
    var sxSp = 2.5
    const chSpChart = (
      <div style={{margin:'40px auto 10px'}}>
        <div className='d-flex justify-content-end'>
          <div className={`tinted ${rtl ? 'tintedRTL' : 'tintedLTR'} ${PSYInfo.genderValue===0 ? 'female' : 'male'}`}>
            <div style={{height:`${p0x*sxSp}px`, backgroundColor:'#31BA0D'}}></div>
            <div style={{height:`${p1x*sxSp}px`, backgroundColor:'rgba(153, 102, 255,1)'}}></div>
            <div style={{height:`${p2x*sxSp}px`, backgroundColor:'rgba(54, 162, 235,1)'}}></div>
            <div style={{height:`${p3x*sxSp}px`, backgroundColor:'rgba(255, 206, 86,1)'}}></div>
            <div style={{height:`${p4x*sxSp}px`, backgroundColor:'rgba(255, 159, 64,1)'}}></div>
            <div style={{height:`${p5x*sxSp}px`, backgroundColor:'rgba(255,99,132,1)'}}></div>
            <div style={{height:`${p6x*sxSp}px`, backgroundColor:'#ff0000'}}></div>
          </div>
        </div>

        <div className='imgSelectGold cardShadow'
          style={{height: `${(110*sxSp)+30}px`, width:w<300 ? '275px' : '300px', marginTop:`${(-110*sxSp)-30}px`,
            fontWeight:'', fontSize:'14px', backgroundColor:'#ffffff99'}}>
          <div style={{widtht:'100%', height:'100%', backgroundColor:'#ffffff99', padding:'10px', }}>
              <div style={chSpStyle}>
                <div style={{margin:'0px 2px', fontWeight:'bold', textAlign: rtl ? 'right' : 'left'}}>{setLT.positiveTraits}</div>
                {p0T}
                {p1T}
                {p2T}
              </div>

              <div style={{margin:'5px 0px'}}>
                <div style={chSpStyle}>
                  <span style={{fontWeight:'bold'}}>{p3T}</span>
                </div>
              </div>

              <div style={chSpStyle}>
                <div style={{margin:'0px 2px', fontWeight:'bold', textAlign: rtl ? 'right' : 'left'}}>{setLT.negativeTraits}</div>
                {p4T}
                {p5T}
                {p6T}
              </div>
          </div>

        </div>
      </div>
    )

    const chartB = (
        <div className='' style={{margin: w<s ? '0px' : '0px 0px 50px', padding:'10px 0px', backgroundColor:''}}>
            {/* <Container>
                <div style={{margin:'10px', fontSize:'15px', textAlign: rtl ? 'right' : 'left'}}>{setLT.chSpText2}</div>
                <div className='d-flex' style={{margin:'7px', color:'#0b12e5', alignItems:'flex-start'}}>
                  <FaInfoCircle style={{marginTop:'3px', minWidth:'20px'}}/>&nbsp;&nbsp;
                  <div style={{fontSize:'15px', textAlign: rtl ? 'right' : 'left'}}>{setLT.chSpText3}</div>
                </div>
            </Container> */}
            <div className={`scroller ${w<s ? '' : 'sticky-top'}`} style={{top: w<s ? '' : NavH, margin: '0px 0px 15px', zIndex:'1'}}>
              <div className='cardShadow' id="totalChartInContainer"
                style={{backgroundColor: '#ffffff', paddingTop:'20px'}}>
              </div>
            </div>
        </div>
    )

    const chSpConst = (
      <div className='' style={{margin:'0px 0px 10px', padding:'0px 0px 10px ', backgroundColor:''}}>
          <div className='center' style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
            {chSpChart}
          </div>
          {/* <div className='d-flex' style={{margin:'7px', color:'#0b12e5', alignItems:'flex-start'}}>
            <FaInfoCircle style={{marginTop:'3px', minWidth:'20px'}}/>&nbsp;&nbsp;
            <div style={{fontSize:'15px', textAlign: rtl ? 'right' : 'left'}}>{setLT.chSpText1}</div>
          </div> */}
      </div>
    )

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column'}}>
        <div className='d-flex' style={{justifyContent:'center', alignItems:'center', padding:'0px 10px', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '25px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.characterAnalysis}</h1>
          <BackToCharacterCards/>
        </div>
      </div>
    )

    const gender = (
      <div className='center' style={{width:'100%'}}>
        <div style={{margin:'20px 5px 0px', width:w<300 ? '100%' : '300px'}}><Gender/></div>
      </div>
    )

    const ch = ((h-(NavH*2))/2)-60
    const p0Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#31BA0D', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#31BA0D', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.strengths}&nbsp;{setLT.p0Title}&nbsp;({p0})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p0ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p1Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#9966ff', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#9966ff', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p1Title}&nbsp;({p1})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p1ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p2Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#36a2eb', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#36a2eb', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p2Title}&nbsp;({p2})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p2ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p31Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#ffce56', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#ffce56', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p3Title}&nbsp;({p31})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p31ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p32Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#ffce56', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#ffce56', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p3Title}&nbsp;({p32})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p32ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p4Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#ff9f40', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#ff9f40', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p4Title}&nbsp;({p4})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p4ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p5Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#ff6384', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#ff6384', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.p5Title}&nbsp;({p5})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p5ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const p6Chart = (
      <div style={{width: w<s ? '50%' : '250px', minWidth: w<s ? '50%' : '250px', padding: '5px', borderRadius:'10px', zIndex:'0'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#ff0000', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', height:'45px', backgroundColor:'#ff0000', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.weaknesses}&nbsp;{setLT.p6Title}&nbsp;({p6})</h3>
                  </div>
              </div>

              <div className='scroller' style={{width:'100%', height:ch, padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {p6ChartData}
                  </div>
              </div>
          </div>
      </div>
    )

    const strengths = (
        <div style={{width: '100%', padding: '5px', borderRadius:'10px'}}>
            <div className='d-flex sticky-top' style={{backgroundColor:'#007304', borderRadius:'10px', top:NavH, zIndex:'1'}}>
                <div className='d-flex sticky-top' style={{width:'100%', height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px'}}>
                    <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width:'' , textAlign:'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;({p0+p1+p2+p31})</h3>
                </div>
            </div>

            <div className='center' style={{flexWrap:'wrap', flexDirection:'', width:'100%', height:'', padding:'5px 10px', backgroundColor:'', borderRadius:'0px 0px 10px 10px'}}>
                {p0Chart}
                {p1Chart}
                {p2Chart}
                {p31Chart}
            </div>
        </div>
    )
    
    const weaknesses = (
      <div style={{width: '100%', padding: '5px', borderRadius:'10px'}}>
              <div className='d-flex sticky-top' style={{backgroundColor:'#FE0202', borderRadius:'10px', top:NavH, zIndex:'1'}}>
                  <div className='d-flex sticky-top' style={{width:'100%', height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width:'' , textAlign:'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;({p32+p4+p5+p6})</h3>
                  </div>
              </div>

              <div className='center' style={{flexWrap:'wrap', flexDirection:'', width:'100%', height:'', padding:'5px 10px', backgroundColor:'', borderRadius:'0px 0px 10px 10px'}}>
                {p6Chart}
                {p5Chart}
                {p4Chart}
                {p32Chart}
              </div>
      </div>
    )
    
    return (
      <div>
          <LoadingBar onRef={ref => (this.LoadingBar = ref)} height={LBH} background={LBC}/>
          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
          <Container style= {{backgroundColor:'', zIndex:'0'}}>
              {header}
              <div className='center' style={{justifyContent:w<s ? 'center' : 'space-between', flexWrap:'wrap', alignItems:''}}>
                <div className='d-flex' style={{alignItems:'center', flexDirection:'column'}}>
                  {gender}
                  {analysisPercentage}
                </div>
                {chSpConst}
                <div style={{margin:'20px 5px 0px', width:'300px'}}></div>
              </div>
          </Container>
          {chartB}
          <div style={{margin:'0px 0px 20px'}}>
            {strengths}
            {weaknesses}
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userInfo: state.userInfo,
    userId: state.userInfo['_id'],
    username: state.userInfo['username'],
    colorsDataChart: state.colorsDataChart,
    selected: state.colorsSelected,
    auth: state.auth,
    member: state.member,
    rtl: state.rtl,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    pageTitle: state.pageTitle,
    subUser: state.subUser,
    chSp: state.chSp,
    genderValue: state.userInfo.genderValue,
    colorTestStart: state.colorTestStart,
    colorResult: state.colorResult,
    colorChSp: state.colorChSp,
    colorIntLink: state.colorIntLink,
    colorProgress: state.colorProgress,

    abjadDataChart: state.abjadDataChart,
    astrologyDataChart: state.astrologyDataChart,
    colorsDataChart: state.colorsDataChart,
    cheeksDataChart: state.cheeksDataChart,
    chinDataChart: state.chinDataChart,
    earsDataChart: state.earsDataChart,
    eyebrowsDataChart: state.eyebrowsDataChart,
    eyeColorDataChart: state.eyeColorDataChart,
    eyesDataChart: state.eyesDataChart,
    faceShapeDataChart: state.faceShapeDataChart,
    foreheadDataChart: state.foreheadDataChart,
    lipsDataChart: state.lipsDataChart,
    noseDataChart: state.noseDataChart,
    bloodTypeDataChart: state.bloodTypeDataChart,
    bodyShapeDataChart: state.bodyShapeDataChart,

    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    access: state.userInfo.access,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(CharacterAnalysisPage);
