import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Container, Card, CardHeader, CardBody} from "mdbreact";
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
// import './../../public/css/SelectColor.css';
import CarouselComponent from '../../components/Psychology/CarouselComponent';
import Abjad from '../../components/Psychology/CosmicNumbers/Abjad/Abjad';
import Astrology from '../../components/Psychology/CosmicNumbers/Astrology/Astrology';
import Colors from '../../components/Psychology/ColorPsychology/Colors/Colors';
import FaceShape from '../../components/Psychology/FaceReading/FaceShape/FaceShape';
import Ears from '../../components/Psychology/FaceReading/Ears/Ears';
import Lips from '../../components/Psychology/FaceReading/Lips/Lips';
import Eyes from '../../components/Psychology/FaceReading/Eyes/Eyes';
import EyeColor from '../../components/Psychology/FaceReading/EyeColor/EyeColor';
import Cheeks from '../../components/Psychology/FaceReading/Cheeks/Cheeks';
import Chin from '../../components/Psychology/FaceReading/Chin/Chin';
import Forehead from '../../components/Psychology/FaceReading/Forehead/Forehead';
import Eyebrows from '../../components/Psychology/FaceReading/Eyebrows/Eyebrows';
import Nose from '../../components/Psychology/FaceReading/Nose/Nose';
import BodyShape from '../../components/Psychology/Others/BodyShape/BodyShape';
import BloodType from '../../components/Psychology/Others/BloodType/BloodType';
import { subject, siteType, pageTitle, page, pageName, PSYInfo, card, chSp, subChSp, totalDataChart, subTotalDataChart, 
  colorsSelectedZ, colorsSelected, cheeks, chin, ears, eyebrows, eyeColor, eyes, faceShape, forehead, lips, nose, bloodType, bodyShape,
  abjadDataChart, astrologyDataChart, colorsDataChart, cheeksDataChart, chinDataChart, earsDataChart, eyebrowsDataChart, eyeColorDataChart, eyesDataChart, faceShapeDataChart, foreheadDataChart, lipsDataChart, noseDataChart, bloodTypeDataChart, bodyShapeDataChart,
 } from '../../dataStore/actions';
import ScrollView, { ScrollElement } from "../../scroller";
import attrib from '../../modules/attrib';
// import CardCompelete from '../../components/CardCompelete';
import srcSet, { NavH } from '../../srcSet';
import siteView from '../../modules/siteView';

var s = srcSet.s

class CharacterCardPage extends Component {

  state = {
      w: window.innerWidth,
      h: window.innerHeight,
      pageName: this.props.setLT.characterAnalysisCards,
  }

  async componentDidMount() {

    // await this.props.dispatch(pageName(this.props.setLT.characterAnalysisCards))
    await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
    await this.props.dispatch(page('character-card'))
    await this.props.dispatch(subject('character-card'))
    await this.props.dispatch(siteType(2))
    if(this.state.pageName===undefined) window.location.reload()
    // if(Object.keys(this.props.geo).length===0) await window.location.reload()

    siteView(this.props)

      //console.log(this.props.PSYInfo.fakeId)
  }

  onResize = () => {
    this.setState({
      w: document.body.clientWidth,
      h: window.innerHeight,
      wh: window.innerHeight,
      dh: document.body.clientHeight
    })
  }

  // cardSelected = async () => {
  //     await axios.get('http://localhost:4200/total/cardSelection/' + this.props.mainUserId).then( res => {
  //         console.log(12, res.data[0])
  //         if(res.data.length!==0) this.props.dispatch(card(res.data[0]))
  //     })
  // }

  xState = async () => {
    const {card} = this.props
    console.log(card)
      this.setState({
          xAbjad: card.abjad,
          xAstrology: card.astrology,
          xColors: card.colors,
          xFaceShape: card.faceShape,
          xLips: card.lips,
          xEars: card.ears,
          xEyes: card.eyes,
          xEyeColor: card.eyeColor,
          xCheeks: card.cheeks,
          xChin: card.chin,
          xForehead: card.forehead,
          xEyebrows: card.eyebrows,
          xNose: card.nose,
          xBodyShape: card.bodyShape,
          xBloodType: card.bloodType,
  
      })
  }

  chSp = async () => {
      const { rtl, lang, userId, subUser } = this.props;
      await attrib(userId, rtl, lang).then(res=>{
          var p0 = res.p0;
          var p1 = res.p1;
          var p2 = res.p2;
          var p3 = res.p3;
          var p4 = res.p4;
          var p5 = res.p5;
          var p6 = res.p6;
          if(subUser){
              this.props.dispatch(subChSp([p0, p1, p2, p3, p4, p5, p6]))
              this.props.dispatch(subTotalDataChart(res.xt))
          } else {
              this.props.dispatch(chSp([p0, p1, p2, p3, p4, p5, p6]))
              this.props.dispatch(totalDataChart(res.xt))
          }
      })
  }

  onShortcut = () => {
      this.setState({
          shortcut: !this.state.shortcut,
      })
      this.xState()
  }

  onImageOver(t, n) {
      this._scroller.scrollTo(t);
      this.setState({
          type: t, n
      })
  }

  render() {
      const {w, h, type, restartbtn} = this.state;
      const {lang, auth, rtl, setLT, PSYInfo, card, } = this.props;

        const abjadSh = (
          <div className='d-flex' onClick={() => this.onImageOver('abjad', card.xAbjad)}>
            <div className= {type==='abjad' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/cosmicNumbers/abjad/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.abjad===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="abjad"
              />
            </div>
          </div>
        )
        const astrologySh = (
          <div className='d-flex' onClick={() => this.onImageOver('astrology', card.xAstrology)}>
            <div className= {type==='astrology' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/cosmicNumbers/astrology/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.astrology===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="astrology"
              />
            </div>
          </div>
        )
        const colorsSh = (
          <div className='d-flex' onClick={() => this.onImageOver('colors', card.xColors)}>
            <div className= {type==='colors' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/colorPsychology/colors/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.colors===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="colors"
              />
            </div>
          </div>
        )

        const faceShapeSh = (
          <div className='d-flex' onClick={() => this.onImageOver('faceShape', card.xFaceShape)}>
            <div className= {type==='faceShape' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/faceShape/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.faceShape===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="face Shape"
              />
            </div>
          </div>
        )
        const cheeksSh = (
          <div className='d-flex' onClick={() => this.onImageOver('cheeks', card.xCheeks)}>
            <div className= {type==='cheeks' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/cheeks/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.cheeks===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="cheeks"
              />
            </div>
          </div>
        )
        const earsSh = (
          <div className='d-flex' onClick={() => this.onImageOver('ears', card.xEars)}>
            <div className= {type==='ears' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/ears/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.ears===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="ears"
              />
            </div>
          </div>
        )
        const lipsSh = (
          <div className='d-flex' onClick={() => this.onImageOver('lips', card.xLips)}>
            <div className= {type==='lips' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/lips/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.lips===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="lips"
              />
            </div>
          </div>
        )
        const chinSh = (
          <div className='d-flex' onClick={() => this.onImageOver('chin', card.xChin)}>
            <div className= {type==='chin' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/chin/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.chin===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="chin"
              />
            </div>
          </div>
        )
        const foreheadSh = (
          <div className='d-flex' onClick={() => this.onImageOver('forehead', card.xForehead)}>
            <div className= {type==='forehead' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/forehead/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.forehead===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="forehead"
              />
            </div>
          </div>
        )
        const eyebrowsSh = (
          <div className='d-flex' onClick={() => this.onImageOver('eyebrows', card.xEyebrows)}>
            <div className= {type==='eyebrows' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/eyebrows/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.eyebrows===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="eyebrows"
              />
            </div>
          </div>
        )
        const eyesSh = (
          <div className='d-flex' onClick={() => this.onImageOver('eyes', card.xEyes)}>
            <div className= {type==='eyes' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/eyes/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.eyes===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="eyes"
              />
            </div>
          </div>
        )
        const eyeColorSh = (
          <div className='d-flex' onClick={() => this.onImageOver('eyeColor', card.xEyeColor)}>
            <div className= {type==='eyeColor' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/eyeColor/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.eyeColor===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="eye color"
              />
            </div>
          </div>
        )
        const noseSh = (
          <div className='d-flex' onClick={() => this.onImageOver('nose', card.xNose)}>
            <div className= {type==='nose' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/faceReading/nose/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.nose===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="nose"
              />
            </div>
          </div>
        )
        const bodyShapeSh = (
          <div className='d-flex' onClick={() => this.onImageOver('bodyShape', card.xBodyShape)}>
            <div className= {type==='bodyShape' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/others/bodyShape/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.bodyShape===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="body shape"
              />
            </div>
          </div>
        )
        const bloodTypeSh = (
          <div className='d-flex' onClick={() => this.onImageOver('bloodType', card.xBloodType)}>
            <div className= {type==='bloodType' ? 'imgSelectOn' : ''}>
              <img src={require(`../../public/images/character/others/bloodType/0.jpg`)}
                className={`img-fluid ${PSYInfo.card.bloodType===1 ? 'imgSelectZ': 'imgNotSelectZ'}`} alt="blood type"
              />
            </div>
          </div>
        )

        const hStyle = {fontSize:'18px', fontWeight:'bold', margin:'20px 5px 10px', padding:'10px 10px', color:'#ffffff',
                      textAlign:'center', backgroundImage: 'linear-gradient(to right , #ffffff , #5b5bff , #ffffff)',
                      border:'1px solid ##FEED97', borderRadius:'5px', position:'sticky', top:NavH, zIndex:'1'}
        const cosmicNumbersH = (
          <div className='d-flex justify-content-center tx' style={hStyle}>
              {setLT.cosmicNumbers}
          </div>
        )
        const colorsPsychologyH = (
          <div className='d-flex justify-content-center tx' style={hStyle}>
              {setLT.colorsPsychology}
          </div>
        )
        const facialPsychologyH = (
          <div className='d-flex justify-content-center tx' style={hStyle}>
              {setLT.facialPsychology}
          </div>
        )
        const othersH = (
          <div className='d-flex justify-content-center tx' style={hStyle}>
              {setLT.other}
          </div>
        )

      const titleText = (
        <Container>
          <div className='animated fadeInUpX' style={{animationDelay:'0s', backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
            <div style={{backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
              <p style={{textAlign: 'justify', fontSize:'', lineHeight: '30px', margin:'0px'}}>
                {setLT.BCRText1}
              </p>
            </div>
          </div>
        </Container>
      )
  
      const carousel = (
        <div className='animated fadeInUpX' style={{animationDelay:'.5s', margin:'10px 0px'}}>
          <CarouselComponent/>
        </div>
      )

      const mainText = (
          <Container>
            <div className='animated fadeInUpX' style={{animationDelay:'1s', backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
              <div style={{backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
                <p style={{textAlign: 'justify', fontSize:'', lineHeight: '30px', margin:'0px'}}>
                  {setLT.characterText1}
                  <span style={{color:'#6600ff', fontWeight:''}}>{setLT.characterText2}</span>
                </p>
              </div>
            </div>
          </Container>
      )
  
      const shortcut = (
        <div>
          <div className='d-flex sticky-top scrollerV'
              style={{ top:NavH, width:'100px', height: '', margin:'20px 0px 0px 0px', padding:'10px', flexDirection:'column', justifyContent:'flex-start', zIndex:'2' }}
          >
              {faceShapeSh}
              {cheeksSh}
              {earsSh}
              {lipsSh}
              {chinSh}
              {foreheadSh}
              {eyebrowsSh}
              {eyesSh}
              {eyeColorSh}
              {noseSh}
              {colorsSh}
              {abjadSh}
              {astrologySh}
              {bodyShapeSh}
              {bloodTypeSh}
          </div>
        </div>
      )

      const psychoComp = (
        <ScrollView ref={scroller => this._scroller = scroller}>
            <div style={{marginTop: '10px'}}>
                {facialPsychologyH}
                <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
                    <ScrollElement name='faceShape'><FaceShape/></ScrollElement>
                    <ScrollElement name='cheeks'><Cheeks/></ScrollElement>
                    <ScrollElement name='ears'><Ears/></ScrollElement>
                    <ScrollElement name='lips'><Lips/></ScrollElement>
                    <ScrollElement name='chin'><Chin/></ScrollElement>
                    <ScrollElement name='forehead'><Forehead/></ScrollElement>
                    <ScrollElement name='eyebrows'><Eyebrows/></ScrollElement>
                    <ScrollElement name='eyes'><Eyes/></ScrollElement>
                    <ScrollElement name='eyeColor'><EyeColor/></ScrollElement>
                    <ScrollElement name='nose'><Nose/></ScrollElement>
                </div>
                {colorsPsychologyH}
                <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
                    {/* <ScrollElement name='colors'><Colors/></ScrollElement> */}
                    <ScrollElement name='colors'><Colors/></ScrollElement>
                </div>
                {cosmicNumbersH}
                <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
                    <ScrollElement name='abjad'><Abjad/></ScrollElement>
                    <ScrollElement name='astrology'><Astrology/></ScrollElement>
                </div>
                {othersH}
                <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
                    <ScrollElement name='bodyShape'><BodyShape/></ScrollElement>
                    <ScrollElement name='bloodType'><BloodType/></ScrollElement>
                </div>
            </div>
        </ScrollView>
      )

      const helpText = (
        <Container>
          <div className='animated fadeInUpX' style={{animationDelay:'1s', backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
            <div style={{backgroundColor:'#ffffff99', fontWeight:'bold', padding:'10px'}}>
              <p style={{textAlign: 'center', fontSize:'20px', lineHeight: '30px', margin:'0px'}}>
                {setLT.helpSelectCard}
              </p>
            </div>
          </div>
        </Container>
      )

      return (
          <Container>
              <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
              <h1 className='animated fadeInDown tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 0px 30px'}}>{setLT.BCR}</h1>
              {titleText}
              {carousel}
              {mainText}
              <h1 className='animated fadeInDown tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 0px 30px'}}>{setLT.characterAnalysisCards}</h1>
              {helpText}
              <div className='d-flex' style={{margin:'0px 0px 20px'}}>
                {w<s && shortcut}
                {psychoComp}
              </div>
          </Container>
      )

  }
}

const mapStateToProps = (state) => {
    return {
        mainUserId: state.userInfo['_id'],
        mainUser: state.userInfo,
        rtl: state.rtl,
        lang: state.lang,
        geo: state.geo,
        auth: state.auth,
        page: state.page,
        subject: state.subject,
        pageName: state.pageName,
        member: state.member,
        toggleProfile: state.toggleProfile,
        setLT: state.setLT,
        card: state.card,
        PSYInfo: state.PSYInfo,
        memberCountry: state.memberCountry,

    }
}
export default connect (mapStateToProps)(CharacterCardPage);
