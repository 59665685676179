import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { PSYInfo, genderChange, cardChange, toggleGender } from '../dataStore/actions';
import '../public/css/colorLoading.css';
import male from '../public/images/background/man2.png';
import female from '../public/images/background/woman2.png';
import srcSet from '../srcSet';

var s = srcSet.s

class Gender extends Component {

  state = {
    fakeId: this.props.PSYInfo.fakeId,
    genderValue: this.props.PSYInfo.genderValue,
  }

  componentDidMount() {
    if(this.state.genderValue==='') this.setState({ genderErr: this.props.setLT.genderErr })
  }

  onGender = (x) => {
    if(this.props.PSYInfo.fakeId==='') {
      var id = Math.floor(Math.random() * 1000000000000) + 1
      // console.log(id)
      var PSYInfoX = this.props.PSYInfo
      PSYInfoX.fakeId = id.toString()
      this.props.dispatch(PSYInfo(PSYInfoX))
    }

    var PSYInfoX = this.props.PSYInfo
    PSYInfoX.genderValue = x
    this.props.dispatch(PSYInfo(PSYInfoX))
    this.setState({
        genderValue: x,
        genderErr:''
    })
    this.props.dispatch(genderChange(this.props.page+'-Gender'))
    this.props.dispatch(cardChange(true))
    if(this.props.toggleGender) {
      this.props.dispatch(toggleGender(false))
      window.location.reload()
    }
    // console.log(this.props.genderChange)
  }
 
  render() {
    const {genderValue, genderErr} = this.state
    const {rtl, auth, setLT, lang} = this.props
    // const {genderValue} = this.props.PSYInfo

    return (
      <div className='silver2' style={{margin:'0px 0px 20px', padding:'5px', borderRadius:'5px', border:'0px solid #5b5bff', }}>
        <div className='center' style={{alignItems:'center', fontSize:'14px', fontWeight:'bold', borderRadius:'2px', padding:'10px', backgroundColor:'#ffffff', flexDirection:'column'}}>
            {/* <div style={{width:'70px', fontSize:'14px', fontWeight:'', margin: '0px', textAlign: rtl ? 'right' : 'left'}}>{setLT.gender}</div> */}
            <form className='d-flex'>
                <div className="radio" style={{margin:'0px 10px'}} onClick={() => this.onGender(1)}>
                    <label className='center' style={{margin:'0px', alignItems:'center', cursor:'pointer', flexWrap:'wrap'}}>
                        <img className='' src={male} alt="male" style={{filter: genderValue===1 ? '' : 'grayscale(100%)', objectFit: 'contain', borderRadius:'100px', width:'35px', height:'35px', margin:'0px', padding:'0px'}} />
                        <div className='d-flex' style={{alignItems:'center'}}>
                          <span style={{marginTop:lang==='fa' ? '0px' : '5px'}}>{setLT.male}</span>
                          <input type="radio" value="option2" checked={genderValue===1 ? true : false} style={{margin:'0px 5px', cursor:'pointer'}}/>
                        </div>
                    </label>
                </div>
                <div className="radio" style={{margin:'0px 10px'}} onClick={() => this.onGender(0)}>
                    <label className='center' style={{margin:'0px', alignItems:'center', cursor:'pointer', flexWrap:'wrap'}}>
                        <img className='' src={female} alt="female" style={{filter: genderValue===0 ? '' : 'grayscale(100%)', objectFit: 'contain', borderRadius:'100px', width:'35px', height:'35px', margin:'0px', padding:'0px'}} />
                        <div className='d-flex' style={{alignItems:'center'}}>
                          <span style={{marginTop:lang==='fa' ? '0px' : '5px'}}>{setLT.female}</span>
                          <input type="radio" value="option3" checked={genderValue===0 ? true : false} style={{margin:'0px 5px', cursor:'pointer'}}/>
                        </div>
                    </label>
                </div>
            </form>
            <span className='invalid-feedback' style={{fontSize:'12px', display: genderErr ? 'block' : 'none', textAlign: 'center'}}>{genderErr}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rtl: state.rtl,
    lang: state.lang,
    auth: state.auth,
    page: state.page,
    genderChange: state.genderChange,
    toggleGender: state.toggleGender,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(Gender);

