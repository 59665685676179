import axios from 'axios';
import date from 'date-and-time';
import srcSet from '../srcSet';

const siteView = async (props) => {
    var today = date.format(new Date(), 'YYYY/MM/DD')
    const siteViewInfo = {
        continent : props.geo.continent,
        countryCode : props.geo.countryCode,
        country : props.geo.country,
        city : props.geo.city,
        genderValue: props.mainUser.genderValue,
        username: props.mainUser.username,
        subject: props.subject!=='' ? props.subject : window.location.pathname.split('/')[2],
        viewDate: today,
        view: 1,
        version: srcSet.version,
    }

    // if(!siteViewInfo.countryCode) {
    //     await axios.get('https://extreme-ip-lookup.com/json/?key=0Pj2L6oNoa307KidCFE6')
    //     .then(async (res) => {
    //         let data = res.data;
    //         siteViewInfo.continent = data.continent
    //         siteViewInfo.countryCode = data.countryCode
    //         siteViewInfo.country = data.country
    //         siteViewInfo.city = data.city
    //     })
    // }

    axios.post('http://localhost:4200/view/addSiteView/' , siteViewInfo).then(res => {
    });

    if(siteViewInfo.countryCode!==undefined && siteViewInfo.countryCode!=='') {
        axios.post('http://localhost:4200/view/addSiteViewMain/' , siteViewInfo).then(res => {});
    }

    var dataView = {
      option: 1000000000,
      dx: 0
    }

    axios.post('http://localhost:4200/view/getSiteViewMain', dataView)
    .then(async res => {
        var data = res.data

        let accumulation = data.reduce((total, val, index)=>{
          let foundItemIndex = total.findIndex((obj)=>obj.country == val.country);
          if(foundItemIndex < 0) total.push(val) 
          else total[foundItemIndex].view = 1; //+= val.view;
          return total;
        }, []);

        var vx = accumulation
        var vxArr = []
        // console.log(vx)
        // var x = 0
        for(var i=0; i<vx.length; i++) {
          vxArr.push({"_id": vx[i].countryCode, "country": vx[i].country, "count": vx[i].view})
        }

        var cx = props.memberCountry
        // console.log('cx :', cx)
        // console.log('vxArr :', vxArr)
        var total = cx ? cx.concat(vxArr) : vxArr
        // console.log(vxArr)

        var tSum = total.map(n => n.count).reduce((a, b) => a + b, 0);
        // console.log(tSum, total)

        var fn = Math.floor(Math.random() * 10) //+ 1
        var tl = total.length
        // console.log('fn',fn)

        for(var x=0; x<fn; x++) {
            var ti = Math.floor(Math.random() * tSum) + 1
            // console.log('ti',ti)
            var q=0
            var finish = false
            for(var a=0; a<tl && !finish; a++) {
                q = q + total[a].count
                // console.log('q', q)

                if(ti<=q) {
                    const fakeViewInfo = {
                        countryCode : total[a]._id,
                        country : total[a].country,
                        viewDate: today,
                        view: 1
                    }
                    // console.log(fakeViewInfo.countryCode)
                    finish = true

                    if(fakeViewInfo.countryCode!=='AF') axios.post('http://localhost:4200/view/addSiteViewMain/', fakeViewInfo).then(res => {});
                }

            }
        }
  

      })



}

export default siteView;

  // function getGeo () {
  //   return axios.get('https://extreme-ip-lookup.com/json/')
  //     .then(async (res) => {
  //       let data = res.data;
  //       // console.log(data)
  //       data.country_name = data.country
  //       return data
  //   })
  // }

  // if(props.geo===undefined) {
    // getGeo().then(res => {
    //   console.log(res)
    //   const siteViewInfo = {
    //     continent : 'rrr',//res.continent,
    //     countryCode : res.countryCode,
    //     country : res.country,
    //     city : res.city,
    //     genderValue: props.mainUser.genderValue,
    //     username: props.mainUser.username,
    //     subject: '22222',//props.geo,
    //     viewDate: date.format(new Date(), 'YYYY/MM/DD'),
    //     view: 1
    //   }
    //   // console.log(siteViewInfo)
    //   axios.post('http://localhost:4200/view/addSiteView/' , siteViewInfo).then(res => {
    //   });
    // })
  // } else {
  //   const siteViewInfo = {
  //     continent : props.geo.continent,
  //     countryCode : props.geo.countryCode,
  //     country : props.geo.country,
  //     city : props.geo.city,
  //     genderValue: props.mainUser.genderValue,
  //     username: props.mainUser.username,
  //     subject: '33333',//props.geo,
  //     viewDate: date.format(new Date(), 'YYYY/MM/DD'),
  //     view: 1
  //   }
  //   // console.log(siteViewInfo)
  //   axios.post('http://localhost:4200/view/addSiteView/' , siteViewInfo).then(res => {
  //   });
  // }

