import axios from 'axios';
import attributes from './../langText/attributes';
import definition from './../langText/definition';
import srcSet from '../srcSet';

const attrib = async (userId, rtl, lang, subUser) => {

  return await axios.get('http://localhost:4200/total/chart/' + userId).then(async res => {

    var xt = res.data
    var chartColor0 = srcSet.chart.color0
    var chartColor1 = srcSet.chart.color1
    var chartColor2 = srcSet.chart.color2
    var chartColor3 = srcSet.chart.color3
    var chartColor4 = srcSet.chart.color4
    var chartColor5 = srcSet.chart.color5
    var chartColor6 = srcSet.chart.color6
    var chartBorder0 = srcSet.chart.border0
    var chartBorder1 = srcSet.chart.border1
    var chartBorder2 = srcSet.chart.border2
    var chartBorder3 = srcSet.chart.border3
    var chartBorder4 = srcSet.chart.border4
    var chartBorder5 = srcSet.chart.border5
    var chartBorder6 = srcSet.chart.border6
    
    var subChartColor1 = srcSet.subChart.color1
    var subChartColor2 = srcSet.subChart.color2
    var subChartColor3 = srcSet.subChart.color3
    var subChartColor4 = srcSet.subChart.color4
    var subChartColor5 = srcSet.subChart.color5
    var subChartBorder1 = srcSet.subChart.border1
    var subChartBorder2 = srcSet.subChart.border2
    var subChartBorder3 = srcSet.subChart.border3
    var subChartBorder4 = srcSet.subChart.border4
    var subChartBorder5 = srcSet.subChart.border5

    var i, La
    var ds = 0
    var dP = []

    switch (lang) {
      case 'IR': La = 'fa'; break;
      case 'US': La = 'en'; break;
      case 'TR': La = 'tr'; break;
      default:   La = 'en';
    }

    for (i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      if(xt.length>60){
        var s=xt[i]['score']
        var a
        var m = 10

        //"c039-خوش صحبت"
        var k15=1.5
        if(cx==='c039' && s<k15){
          var sx15 = 0
          for (a = 0; a < m; a++) {
            sx15 += xt[a]['score']/m
          }
          switch (true) {
            case sx15<s : xt[i]['score']=s; break;
            case sx15<k15 : xt[i]['score']=k15; break;
            case sx15>k15 : xt[i]['score']=k15; break;
            default: xt[i]['score']=s
          }
        } 

        //"c074-یار و یاور" //"c075-راز دار و قابل اعتماد" //"c048-مقاوم در سختی ها" //"c088-عادل و با انصاف" //"c061-مسئولیت پذیر" //"c055-عاشق امنیت و آرامش"
        var k25=2.5
        if((cx==='c074' || cx==='c075' || cx==='c048' || cx==='c088' || cx==='c061' || cx==='c055') && s<k25){
          var sx25 = 0
          for (a = 0; a < m; a++) {
            sx25 += xt[a]['score']/m
          }
          switch (true) {
            case sx25<s : xt[i]['score']=s; break;
            case sx25<k25 : xt[i]['score']=k25; break;
            case sx25>k25 : xt[i]['score']=k25; break;
            default: xt[i]['score']=s
          }
        }

        //"c060-لایق و کاردان" //"c043-سخاوتمند" //"c108-عاشق طبیعت" //"c044-شجاع" //"c084-تنوع طلب" //"c054-خوش بین و امیدوار"
        var k30=3
        if((cx==='c060' || cx==='c043' || cx==='c108' || cx==='c044' || cx==='c084' || cx==='c054') && s<k30){
          var sx30 = 0
          for (a = 0; a < m; a++) {
            sx30 += xt[a]['score']/m
          }
          switch (true) {
            case sx30<s : xt[i]['score']=s; break;
            case sx30<k30 : xt[i]['score']=k30; break;
            case sx30>k30 : xt[i]['score']=k30; break;
            default: xt[i]['score']=s
          }
        }

        //"c094-حسابگر"//"c106-قدرشناس"
        var k35=3.5
        if((cx==='c094' || cx==='c106') && s<k35){
          var sx35 = 0
          for (a = 0; a < m; a++) {
            sx35 += xt[a]['score']/m
          }
          switch (true) {
            case sx35<s : xt[i]['score']=s; break;
            case sx35<k35 : xt[i]['score']=k35; break;
            case sx35>k35 : xt[i]['score']=k35; break;
            default: xt[i]['score']=s
          }
        }

        //"c035-محترم و درستکار"
        var k35=5
        if((cx==='c035') && s<k35){
          var sx35 = 0
          for (a = 0; a < m; a++) {
            sx35 += xt[a]['score']/m
          }
          switch (true) {
            case sx35<s : xt[i]['score']=s; break;
            case sx35<k35 : xt[i]['score']=k35; break;
            case sx35>k35 : xt[i]['score']=k35; break;
            default: xt[i]['score']=s
          }
        }

      }
      xt[i]['character']=attributes[cx][La] 
      xt[i]['definition']=definition[cx][La] 
    }

    if(rtl) {
      xt.sort((a, b) => (a.score > b.score) ? 1 : -1)
    } else {
      xt.sort((a, b) => (a.score > b.score) ? -1 : 1)
    }
    
    var d = xt.map(({ score }) => score)
    var ds = 0
    
    var n = xt.map(({ character }) => character)

    for (i = 0; i < d.length; i++) ds = ds + Math.abs(d[i])
    for (i = 0; i < d.length; i++) {
      var x = d[i] / ds;
      dP.push(x)
      xt[i].dpM = Math.round(x * 10000) / 100
      xt[i].dp = Math.round(Math.abs(x) * 10000) / 100 + '%'
      xt[i].score = Math.round(d[i] * 100) / 100
    }

    var colors = []
    var borders = []
    var labels = []
    var p0=0; var p1=0; var p2=0; var p3=0; var p4=0; var p5=0; var p6=0;
    var G = srcSet.greenLine
    var R = srcSet.redLine
    var Lp1 = srcSet.Lp1
    var Lp2 = srcSet.Lp2
    var Lp3 = srcSet.Lp3
    var Lp4 = srcSet.Lp4

    for(i = 0; i < d.length; i++){
      var color;
      var border;
      var label;
      x = d[i]
      switch(true){
        case (x > G):
          color = chartColor0;
          border = chartBorder0;
          p0 = p0 + Math.abs(x)
          break;
        case (Lp1 < x && x <= G):
          color = chartColor1;
          border = chartBorder1;
          p1 = p1 + Math.abs(x)
          break;
        case (Lp2 < x && x <= Lp1):
          color = chartColor2;
          border = chartBorder2;
          p2 = p2 + Math.abs(x)
          break;
        case (Lp3 <= x && x <= Lp2):
          color = chartColor3;
          border = chartBorder3;
          p3 = p3 + Math.abs(x)
          break;
        case (Lp4 <= x && x < Lp3):
          color = chartColor4;
          border = chartBorder4;
          p4 = p4 + Math.abs(x)
          break;
        case (R <= x && x < Lp4):
          color = chartColor5;
          border = chartBorder5;
          p5 = p5 + Math.abs(x)
          break;
        case (x < R):
          color = chartColor6;
          border = chartBorder6;
          p6 = p6 + Math.abs(x)
          break;
        default:
            color = 'rgba(0, 0, 0, 0)';
            border = 'rgba(0, 0, 0, 0)';
      }

      colors[i] = color;
      borders[i] = border;
      labels[i] = label;
      xt[i].color = color;
      xt[i].border = border;
    }

    // p1=p1-p0
    // p5=p5-p6

    p0= Number(((p0/ds)*100).toFixed(0));
    p1= Number(((p1/ds)*100).toFixed(0));
    p2= Number(((p2/ds)*100).toFixed(0));
    p3= Number(((p3/ds)*100).toFixed(0));
    p4= Number(((p4/ds)*100).toFixed(0));
    p5= Number(((p5/ds)*100).toFixed(0));
    p6= Number(((p6/ds)*100).toFixed(0));

    return { xt, d, n, dP, colors, borders,
      p0, p1, p2, p3, p4, p5, p6 }

  })
}

export default attrib; 