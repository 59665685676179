import axios from 'axios';
import { Container, Button } from "mdbreact";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { subject, siteType, abjadDataChart, pageName, PSYInfo, toggleGender, genderChange, page, pageTitle, cardChange } from '../../../dataStore/actions';
import '../../../public/css/SelectColor.css';
import '../../../public/css/alerts.css';
import ReactResizeDetector from 'react-resize-detector';
import attributes from '../../../langText/attributes';
import srcSet from '../../../srcSet';
import LoadingBar from 'react-top-loading-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import definition from '../../../langText/definition';
import { FaInfoCircle } from 'react-icons/fa';
import CardCompelete from '../../../components/CardCompelete';
import BackToCharacterCards from '../../../components/Psychology/BackToCharacterCards';
import Gender from '../../../components/Gender';
import toFarsi from '../../../modules/toFarsi';
import abjadCalculator from '../../../modules/abjadCalculator';
import siteView from '../../../modules/siteView';

var s = srcSet.s
// var NavH = srcSet.NavH
var adsSpace = srcSet.adsSpace
var LBH = srcSet.loadingBar.height
var LBC = srcSet.loadingBar.color

class abjadPage extends Component {

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageName: this.props.setLT.abjad,
    ASum1:0,
    BSum1:0,
    commonName: this.props.PSYInfo.abjad.commonName,
    motherName: this.props.PSYInfo.abjad.motherName,
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    // this.LoadingBar.continuousStart()
    // await this.props.dispatch(pageName(this.props.setLT.abjad))
    await this.props.dispatch(pageTitle(`${this.state.pageName} | Whoraly | ${this.props.lang.toLowerCase()}`))
    await this.props.dispatch(page('abjad'))
    await this.props.dispatch(subject('abjad'))
    await this.props.dispatch(siteType(2))
    if(this.state.pageName===undefined) window.location.reload()

    siteView(this.props)
    await this.onApprove()
    // this.LoadingBar.complete()
    // console.log(this.props.pageName, this.props.setLT.abjad)
  }

  changeHandler = e => {
    var tx = this.props.lang==='fa' ? toFarsi(e.target.value) : e.target.value
    this.setState({ ...this.state, [e.target.name]: tx.toLowerCase().replace(/\s/g, '') });
  };

  onResize = () => {
    this.setState({ w: window.innerWidth })
  }

  checkNull = () => {
    const {genderValue} = this.props.PSYInfo
    const {commonName, motherName} = this.props.PSYInfo.abjad
    var infoErr = {}
    if(genderValue==='') infoErr.genderErr = this.props.setLT.genderErr
    if(commonName==='') infoErr.commonNameErr = this.props.setLT.commonNameErr
    if(motherName==='') infoErr.motherNameErr = this.props.setLT.motherNameErr
    return infoErr
  }

  onApprove = async () => {
    if(this.props.PSYInfo.genderValue==='') {
      this.props.dispatch(toggleGender(true))
    } else {
      this.setState({
        ASum1: undefined,
        BSum1: undefined,
      })
      var PSYInfoX = this.props.PSYInfo
      const info = {
        commonName: this.state.commonName,
        motherName: this.state.motherName,
      }
      PSYInfoX.abjad = info
  
      await this.props.dispatch(PSYInfo(PSYInfoX))
      // console.log(1, PSYInfoX)
  
      var infoErr = this.checkNull()
      if(Object.keys(infoErr).length>0) {
        this.setState({
            // genderErr: infoErr.genderErr,
            commonNameErr: infoErr.commonNameErr,
            motherNameErr: infoErr.motherNameErr,
            ASum1: 0,
            BSum1: 0,    
        })
  
      } else {
        this.LoadingBar.continuousStart()
  
        this.setState({
          AChartData1 : [], 
          BChartData1 : [],
        })
  
        var xAbjad = this.state.commonName + this.state.motherName
        await abjadCalculator(xAbjad).then(res=>{
            this.setState({ itemNo: res })
        })
  
        const user = {
          fakeId: this.props.PSYInfo.fakeId,
          genderValue: this.props.PSYInfo.genderValue,
          commonName: this.state.commonName,
          motherName: this.state.motherName,
          itemNo: this.state.itemNo
        }
  
        await axios.post('http://localhost:4200/abjad/update', user).then(res => {});
        await axios.get('http://localhost:4200/abjad/chart/' + user.fakeId).then(res => {
          this.props.dispatch(abjadDataChart(res.data))
        });
        await axios.get('http://localhost:4200/abjad/delete/' + user.fakeId).then(res => {});
  
        await this.abjadChart()
        await this.props.dispatch(cardChange(true))
  
        this.setState({
          commonNameErr: '',
          motherNameErr: '',
        })
        // console.log(777, this.props.abjadDataChart)
        this.LoadingBar.complete()
      }

    }

  }

  abjadChart = async () => {
    this.setState({ 
      AChartData1 : [], 
      BChartData1 : [],
    })
 
    var xt = this.props.abjadDataChart
    var {rtl, lang} = this.props
    // console.log(xt)

    var A = []
    var B = []
    // var sumA = 0
    // var sumB = 0
    for(var i=0; i<xt.length; i++) {
      if(xt[i].score>0) {
        A.push(xt[i])
        // sumA = sumA + xt[i].score
      } else {
        // xt[i].score = -xt[i].score
        B.push(xt[i])
        // sumB = sumB + xt[i].score
      }
    }

    // this.props.dispatch(colorsDataChart(xt))

    A.sort((a, b) => (a.score > b.score) ? -1 : 1)
    B.sort((a, b) => (a.score > b.score) ? 1 : -1)

    // console.log(A)
    // console.log(B)

    var maxA = A[0] ? A[0].score : 0
    var minB = B[0] ? B[0].score : 0
    var maxX = Math.max(maxA, -minB)

    // console.log(maxA)
    // console.log(minB)
    // console.log(maxX)

    for (var i = 0; i < xt.length; i++) {
      var cx = xt[i]['aCode']
      xt[i]['character']=attributes[cx][lang]
      xt[i]['definition']=definition[cx][lang]
    }

    var PSYInfoX = this.props.PSYInfo
    if(xt.length===0){
      PSYInfoX.card.abjad = 0
    } else {
      PSYInfoX.card.abjad = 1
    }
    await this.props.dispatch(PSYInfo(PSYInfoX))

    const definitionIcone = (
      <div className=''
          style={{fontSize:'20px', color:'#00aff5', cursor:'pointer', display:lang!=='fa' ? 'none' : ''}}>
          <FaInfoCircle/>
      </div>
    )
    var p
    var AChartData1 = A.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent: '', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${p}%)`, height:'2px', backgroundColor:'green', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    var BChartData1 = B.map(
      (item, i) => (
          p = (item.score/maxX)*100,
          <div key={i} className='d-flex' style={{width:'100%', alignItems:rtl ? 'flex-end' : 'flex-start', justifyContent:'', flexDirection:'column', overflow:'hidden'}}>
              <span className='d-flex' style={{width:'100%', margin:'15px 0px -2px', fontSize:'15px', alignItems:'center', justifyContent:'space-between', direction: rtl ? 'rtl' : 'ltr', textAlign: rtl ? 'right' : 'left'}}>
                {item.character}
                <div onClick={() => this.onDefine(item)}>{definitionIcone}</div>
              </span>
              <hr style={{margin:'3px 0px 5px', width: '100%', height:'hairline', backgroundColor:'#99999910', marginBottom:'-7px'}}/>
              <hr className={rtl ? 'right' : 'left'} style={{margin:'5px 0px 5px', width: `calc(${-p}%)`, height:'2px', backgroundColor:'red', direction:rtl ? 'rtl' : 'ltr'}}/>
              <hr className='sticky-top' style={{margin:'-12px -22px 0px', width: '20px', height:'10px', backgroundColor:'#ffffff', border:'1px solid #ffffff', direction:rtl ? 'rtl' : 'ltr'}}/>
          </div>
      )
    )

    this.setState({ 
      AChartData1, 
      BChartData1,
      ASum1: A.length,
      BSum1: B.length
     })
    //  console.log(A.length, B.length)
  }

  onDefine = (item) => {
    Swal({
      title: item.character,
      text: item.definition,
      animation: false,
      customClass: 'animated fadeInDown swal-title swal2-text',
      // onClose: function(modal) {
      //   modal.className += ' animated fadeInDown';
      // }
    })
  }

  onDelete = async () => {
    this.LoadingBar.continuousStart()
    var PSYInfoX = this.props.PSYInfo
    const info = {
      commonName: '',
      motherName: '',  
    }
    PSYInfoX.abjad = info
    await this.props.dispatch(PSYInfo(PSYInfoX))
    this.setState({
      commonName: '',
      motherName: '',
      commonNameErr: '',
      motherNameErr: '',
    })
    await this.props.dispatch(abjadDataChart([]))
    await this.abjadChart()
    await this.props.dispatch(cardChange(true))
    this.LoadingBar.complete()
  }

  onGenderChenge = async () => {
    await this.props.dispatch(genderChange(''))
    this.onApprove()
  }

  render() {
    const { w, genderValue, commonName, motherName, genderErr, commonNameErr, motherNameErr, AChartData1, BChartData1, ASum1, BSum1} = this.state
    const { auth, rtl, lang, setLT, genderChange,  } = this.props

    const loaderZ = <div className='loader-02' style={{margin: '0px', color:'#ffffff', transform: rtl ? 'rotate(180deg)' : ''}}></div>

    if(genderChange==='abjad-Gender') this.onGenderChenge()

    const header = (
      <div className='center' style={{alignItems:'center', flexDirection:'column', padding: '0px 10px'}}>
        <div className='d-flex' style={{justifyContent:rtl ? 'space-between' : 'flex-end', alignItems:'center', width:'100%', direction:'rtl'}}>
          <h1 className='animated fadeInLeft tx' style={{animationDelay:'.5s', color:'#ffffff', fontWeight:'bold', fontSize: w<s ? '30px' : '', textAlign:'center', margin:'30px 10px 30px'}}>{setLT.abjad}</h1>
          <BackToCharacterCards/>
        </div>
        <img
          className='animated fadeInUpX'
          style={{width: w<s ? '100%' : '400px', borderRadius:'5px'}}
          src={require(`../../../public/images/character/cosmicNumbers/abjad/0.jpg`)}
          alt={setLT.abjad}
        />
      </div>
    )

    const noteX = (
      <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'', margin:'20px 5px 30px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
          <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
              <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                  <p style={{fontSize:'25px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 0px'}}>{setLT.abjadText2}</p>
              </div>
          </div>
      </div>
    )

    const note = (
      <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'', margin:'20px 5px 30px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
          <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
              <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                  <p style={{fontSize:'16px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 0px'}}>{setLT.abjadText}</p>
              </div>
          </div>
      </div>
    )

    const commonNameConst = (
        <div className='d-flex justify-content-center' style={{margin:'0px 0px 20px', flexDirection:'column'}}>
            <div style={{textAlign: rtl ? 'right' : 'left', fontSize:'14px', fontWeight:'bold'}}>{setLT.commonName}</div>
            <input className='cardShadow' value={commonName} style={{width: '100%', height:'30px', borderRadius:'5px', border:'0px solid', textAlign:'center'}} name="commonName" onChange={this.changeHandler}/>
            <span style={{textAlign: rtl ? 'right' : 'left', fontSize:'12px', fontWeight:'', margin:'5px 0px 0px'}}>{setLT.commonNameComment}</span>
            <span style={{textAlign: rtl ? 'right' : 'left', fontSize:'12px', fontWeight:'', margin:'5px 0px'}}>{setLT.enterFarsiArabi}</span>
            <span className='invalid-feedback' style={{ margin: '5px 0px 10px 0px', display: commonNameErr ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>{commonNameErr}</span>
        </div>
    )

    const motherNameConst = (
        <div className='d-flex justify-content-center' style={{margin:'0px 0px 0px', flexDirection:'column'}}>
            <div style={{textAlign:rtl ? 'right' : 'left', fontSize:'14px', fontWeight:'bold'}}>{setLT.motherName}</div>
            <input className='cardShadow' value={motherName} style={{width: '100%', height:'30px', borderRadius:'5px', border:'0px solid', textAlign:'center'}} name="motherName" onChange={this.changeHandler}/>
            <span style={{textAlign: rtl ? 'right' : 'left', fontSize:'12px', fontWeight:'', margin:'5px 0px'}}>{setLT.enterFarsiArabi}</span>
            <span className='invalid-feedback' style={{ margin: '5px 0px 10px 0px', display: motherNameErr ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>{motherNameErr}</span>
        </div>
    )

    const approveBtn = (
      <Button color='success' onClick={this.onApprove} style={{fontSize:'15px', width:'100px'}}>
        {setLT.approve}
      </Button>
    )

    const unselectBtn = (
      <div className="text-center">
          <Button color="danger" onClick={() => this.onDelete()} style={{fontSize:'15px', width:'100px'}}>
              {setLT.delete}
          </Button>
      </div>
    )

    const btns = (
      <div className= 'd-flex justify-content-center' style={{margin: '0px'}}>
        {approveBtn}
        {unselectBtn}
      </div>
    )

    const form = (
      <div className='center' style={{width: w<s ? '100%' : '350px', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', margin: '10px 5px', flexDirection:'column'}}>
        <div style={{textAlign:rtl ? 'justify' : 'left', fontSize:'16px', fontWeight:'', width: '100%', backgroundColor:'#ffffff', borderRadius:'5px', padding:'10px'}}>
          {setLT.completeInfo}
        </div>
        <div style={{width: '100%', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', margin:'10px 0px'}}>
          <Gender/>
          {commonNameConst}
          {motherNameConst}
        </div>
        <div style={{textAlign:rtl ? 'justify' : 'left', fontSize:'16px', fontWeight:'', width: '100%', backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px'}}>
          <span className='invalid-feedback' style={{fontSize:'12px', display: genderErr ? 'block' : 'none', textAlign: rtl ? 'right' : 'left'}}>{genderErr}</span>
          {btns}
        </div>
      </div>
    )

    const AChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#007304', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#007304', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.positiveTraits}&nbsp;{ASum1!==undefined ? `(${ASum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='scroller' style={{top:0, width:'100%', height:'350px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {AChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const BChart1 = (
      <div style={{width: w<s ? '50%' : '300px', minWidth: w<s ? '50%' : '300px', padding: '5px', borderRadius:'10px'}}>
          <div className='d-flex cardShadow' style={{width: '100%', height:'', borderRadius:'0px', backgroundColor:'', margin:'0px', border:'0px solid #00CCFF', flexDirection:'column', direction:'ltr', borderRadius:'10px'}}>
              <div className='d-flex' style={{backgroundColor:'#FE0202', borderRadius:'10px 10px 0px 0px'}}>
                  <div className='d-flex' style={{width:'100%', top:0, height:'45px', backgroundColor:'#FE0202', padding:'10px', margin:'0px 0px 0px', justifyContent:'center', alignItems:'center', borderRadius:'10px 10px 0px 0px'}}>
                      <h3 className='' style={{color:'#ffffff', margin:'0px', fontSize:'15px', fontWeight:'', width: '' , textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>{setLT.negativeTraits}&nbsp;{BSum1!==undefined ? `(${BSum1})` : loaderZ}</h3>
                  </div>
              </div>

              <div className='scroller' style={{top:0, width:'100%', height:'350px', padding:'0px 10px', backgroundColor:'#ffffff', borderRadius:'0px 0px 10px 10px'}}>
                  <div style={{width:'100%', padding:'0px', borderRadius:'10px'}}>
                      {BChartData1}
                  </div>
              </div>
          </div>
      </div>
    )

    const chart = (
      <div className='center' style={{width:w<s ? '100%' : '', marginTop:'0px', padding: '0px', zIndex:''}}>
        {AChart1}
        {BChart1}
      </div>
    )

    const loadingBar = (
      <LoadingBar
              onRef={ref => (this.LoadingBar = ref)}
              height={LBH}
              background={LBC}
          />
    )

    return (
      <div className='' style= {{fontFamily: "Vazir", backgroundColor:'', paddingBottom:adsSpace, zIndex:'0'}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        {loadingBar}
        <Container>
          <div className='center' style={{flexDirection:'column', alignItems:'center'}}>
            {header}
          </div>
          {noteX}
          {note}
          <div className='d-flex' style={{justifyContent:'space-between', flexWrap:'wrap'}}>
            {form}
            {chart}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mainUserId: state.userInfo['_id'],
    mainUser: state.userInfo,
    userId: state.userInfo['_id'],
    username: state.userInfo['username'],
    // genderValue: state.subUser ? state.subUserInfo['genderValue'] : state.userInfo['genderValue'],
    commonName: state.subUser ? state.subUserInfo['commonName'] : state.userInfo['commonName'],
    motherName: state.subUser ? state.subUserInfo['motherName'] : state.userInfo['motherName'],
    abjadDataChart: state.abjadDataChart,
    auth: state.auth,
    rtl: state.rtl,
    lang: state.lang,
    geo: state.geo,
    page: state.page,
    subject: state.subject,
    pageName: state.pageName,
    subUser: state.subUser,
    chSp: state.chSp,
    chSpZ: state.chSpZ,
    subChSp: state.subChSp,
    subChSpZ: state.subChSpZ,
    totalDataChart: state.totalDataChart,
    subTotalDataChart: state.subTotalDataChart,
    genderChange: state.genderChange,
    PSYInfo: state.PSYInfo,
    setLT: state.setLT,
    memberCountry: state.memberCountry,

  }
}

export default connect (mapStateToProps)(abjadPage);
