import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import '../../public/css/SelectColor.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';

class BackToCharacterCards extends Component {

    state = {}
    
    componentDidMount() {

    }

    render() {
        const { } = this.state;
        const {rtl, lang, setLT} = this.props;
        return (
            <Link to={`/${lang}`} className='center btnShadow animated fadeInRight character' style={{animationDelay:'1s', backgroundColor:'#ffffff00', borderRadius:'100px', border:'3px solid #ffffff', width:'50px', height:'50px', alignItems:'center'}}>
                <HiArrowNarrowLeft className='sidebarIcon' style={{color:'#ffffff', fontWeight:'bold', fontSize:'25px', marginTop:'0px'}}/>
            </Link>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo['_id'],
    rtl: state.rtl,
    lang: state.lang,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(BackToCharacterCards);
