import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../public/css/SelectColor.css';

class HelpSelect extends Component {

    state = {}
    
    componentDidMount() {

    }

    render() {
        const { } = this.state;
        const {rtl, lang, setLT} = this.props;
        return (
            <div className='animated fadeInUpX' style={{animationDelay:'.5s', width:'100%', margin:'20px 5px -70px', padding:'10px', fontFamily:'Vazir', top:'50px', zIndex:'0', backgroundColor:'#ffffff99', borderRadius:'5px'}}>
                <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'10px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                    <div style={{backgroundColor:'#ffffff99', borderRadius:'5px', padding:'0px', textAlign: 'center', direction: rtl ? 'rtl' : 'ltr'}}>
                        <p style={{fontSize:'16px', fontWeight:450, lineHeight:'35px', margin:'0px 0px 50px'}}>{setLT.helpSelect}</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo['_id'],
    rtl: state.rtl,
    lang: state.lang,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(HelpSelect);
