import React, { Component } from 'react';
import { connect } from 'react-redux';

class FaceShape extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3} = this.props
    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/faceShape/${type1}.jpg`)}
                    className='imgSelectSm' alt="face shape type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/faceShape/${type2}.jpg`)}
                    className='imgSelectSm' alt="face shape type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/faceReading/faceShape/${type3}.jpg`)}
                    className='imgSelectSm' alt="face shape type 3"/>
              </div>
            }

          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo['_id'],
    type1: state.faceShape['type1'],
    type2: state.faceShape['type2'],
    type3: state.faceShape['type3'],
    lang: state.lang,
    setLT: state.setLT,
  }
}

export default connect (mapStateToProps)(FaceShape);
