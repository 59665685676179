import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container } from "mdbreact";
import { Button } from 'react-bootstrap';
import ReactResizeDetector from 'react-resize-detector';
import whoralyAds from '../public/images/background/logo.png'
import PSYImg from '../public/images/background/chSpNull.jpg'
import '../public/css/SelectColor.css';
import srcSet from '../srcSet';

import {
  EmailShareButton, FacebookShareButton, InstapaperShareButton, LineShareButton,
  LinkedinShareButton, LivejournalShareButton, MailruShareButton, OKShareButton,
  PinterestShareButton, PocketShareButton, RedditShareButton, TelegramShareButton,
  TumblrShareButton, TwitterShareButton, ViberShareButton, VKShareButton,
  WhatsappShareButton, WorkplaceShareButton, WeiboShareButton,
} from "react-share";
import {
  EmailIcon, FacebookIcon, InstapaperIcon, LineIcon, LinkedinIcon, LivejournalIcon, MailruIcon, OKIcon, PinterestIcon, PocketIcon,
  RedditIcon, TelegramIcon, TumblrIcon, TwitterIcon, ViberIcon, VKIcon, WeiboIcon, WhatsappIcon, WorkplaceIcon,
} from "react-share";

var s = srcSet.s
var NavH = srcSet.NavH

class ShareSite extends Component {

  state = {
    w: window.innerWidth
  }

  componentDidMount () {}

  onResize = () => {
    this.setState({
      w: window.innerWidth
    })
  }

  copyToClipBoard = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
    this.setState({copy: true})
  }

  outFunc = () => {
    var tooltip = document.getElementById("myTooltip");
    //tooltip.innerHTML = "Copy to clipboard";
  }

  render() {
    const {w, copy} = this.state
    const {rtl, lang, setLT, siteType} = this.props

    const shareUrl = `https://www.psychology.whoraly.com/${lang}`;
    const title = siteType===1
                  ? `${setLT.globalMarketText}
                  ${setLT.importExportServices}
                  ` 
                  : `${setLT.BCR}`;

    const facebook = (
      <div style={{margin: '5px'}}>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button">
          <FacebookIcon
            size={32}
            round />
        </FacebookShareButton>
      </div>
    )
    const twitter = (
      <div style={{margin: '5px'}}>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <TwitterIcon
            size={32}
            round />
        </TwitterShareButton>
    </div>
    )
    const telegram = (
      <div style={{margin: '5px'}}>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <TelegramIcon size={32} round />
        </TelegramShareButton>
    </div>
    )
    const whatsapp = (
      <div style={{margin: '5px'}}>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
    </div>
    )
    const linkedin = (
      <div style={{margin: '5px'}}>
        <LinkedinShareButton
          url={shareUrl}
          windowWidth={750}
          windowHeight={600}
          className="Demo__some-network__share-button">
          <LinkedinIcon
            size={32}
            round />
        </LinkedinShareButton>
      </div>
    )
    const pinterest = (
      <div style={{margin: '5px'}}>
        <PinterestShareButton
          url={String(window.location)}
          media={`${String(window.location)}/${siteType===1 ? whoralyAds : PSYImg}`}
          windowWidth={1000}
          windowHeight={730}
          className="Demo__some-network__share-button">
          <PinterestIcon size={32} round />
        </PinterestShareButton>
      </div>
    )
    const vk = (
      <div style={{margin: '5px'}}>
        <VKShareButton
          url={shareUrl}
          image={`${String(window.location)}/${siteType===1 ? whoralyAds : PSYImg}`}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button">
          <VKIcon
            size={32}
            round />
        </VKShareButton>
      </div>
    )
    const okIcon = (
      <div style={{margin: '5px'}}>
        <OKShareButton
          url={shareUrl}
          image={`${String(window.location)}/${siteType===1 ? whoralyAds : PSYImg}`}
          className="Demo__some-network__share-button">
          <OKIcon
            size={32}
            round />
        </OKShareButton>
      </div>
    )
    const reddit = (
      <div style={{margin: '5px'}}>
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button">
          <RedditIcon
            size={32}
            round />
        </RedditShareButton>
      </div>
    )
    const tumblr = (
      <div style={{margin: '5px'}}>
        <TumblrShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button">
          <TumblrIcon
            size={32}
            round />
        </TumblrShareButton>
      </div>
    )
    const livejournal = (
      <div style={{margin: '5px'}}>
        <LivejournalShareButton
          url={shareUrl}
          title={title}
          description={shareUrl}
          className="Demo__some-network__share-button"
        >
          <LivejournalIcon size={32} round />
        </LivejournalShareButton>
      </div>
    )
    const mailru = (
      <div style={{margin: '5px'}}>
        <MailruShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <MailruIcon
            size={32}
            round />
        </MailruShareButton>
      </div>
    )
    const email = (
      <div style={{margin: '5px'}}>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="Demo__some-network__share-button">
          <EmailIcon
            size={32}
            round />
        </EmailShareButton>
      </div>
    )
    const viber = (
      <div style={{margin: '5px'}}>
        <ViberShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <ViberIcon
            size={32}
            round />
        </ViberShareButton>
      </div>
    )
    const workplace = (
      <div style={{margin: '5px'}}>
        <WorkplaceShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button">
          <WorkplaceIcon
            size={32}
            round />
        </WorkplaceShareButton>
      </div>
    )
    const line = (
      <div style={{margin: '5px'}}>
        <LineShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <LineIcon
            size={32}
            round />
        </LineShareButton>
      </div>
    )
    const weibo = (
      <div style={{margin: '5px'}}>
        <WeiboShareButton
          url={shareUrl}
          title={title}
          image={`${String(window.location)}/${siteType===1 ? whoralyAds : PSYImg}`}
          className="Demo__some-network__share-button">
          <img width='32px'  className="Demo__some-network__custom-icon" src="http://icons.iconarchive.com/icons/martz90/circle-addon2/512/weibo-icon.png" alt="Weibo share button" />
        </WeiboShareButton>
      </div>
    )
    const pocket = (
      <div style={{margin: '5px'}}>
        <PocketShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <PocketIcon
            size={32}
            round />
        </PocketShareButton>
      </div>
    )
    const instapaper = (
      <div style={{margin: '5px'}}>
        <InstapaperShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button">
          <InstapaperIcon
            size={32}
            round />
        </InstapaperShareButton>
      </div>
    )


    const share = (
      <div className='d-flex justify-content-center' style={{flexWrap:'wrap'}}>
        {facebook}
        {twitter}
        {telegram}
        {whatsapp}
        {/* instagram */}
        {linkedin}
        {pinterest}
        {vk}
        {okIcon}
        {reddit}
        {tumblr}
        {livejournal}
        {mailru}
        {/* email */}
        {viber}
        {workplace}
        {line}
        {weibo}
        {pocket}
        {instapaper}
      </div>
    )
    const inputStyle = {width: w<s ? '100%' : '400px', height:'30px', borderRadius:'4px', border:'0px solid', textAlign:'center', direction:'ltr'}

    return (
        <Container>
          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
          <div className='' style={{margin: w<s ? '20px 10px 10px' : '20px 0px', border:'3px solid #0b12e580', borderRadius:'5px', padding:'10px', textAlign: rtl ? 'right' : 'left', backgroundColor:'#ffffff99', fontWeight:450}}>
            <div style={{fontWeight:'', fontSize:'15px', textAlign:'', margin:'0px 0px 10px'}}>{setLT.shareWhoralyTextPSY}</div>
            {share}
            <p style={{marginTop:'20px', fontSize:'15px'}}>{setLT.copyWhoralyLinkText}</p>
            <Button variant= {copy ? 'primary' : "success"} onClick={!copy ? this.copyToClipBoard : ''} onMouseOut={this.outFunc()}
                style={{width:'', height:'30px', lineHeight:'10px', margin:'12px 0px 10px'}}>
                <span className="tooltiptext" id="myTooltip">{setLT.copyLink}</span>
            </Button>&nbsp;
            {!copy && <input className='cardShadow' id="myInput" type="text" value={`https://www.psychology.whoraly.com/${lang}`} style={inputStyle} readOnly/>}
          </div>
        </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      mainUserId: state.userInfo['_id'],
      rtl: state.rtl,
      lang: state.lang,
      auth: state.auth, 
      page: state.page,
      setLT: state.setLT,
      siteType: state.siteType,
  }
}

export default connect (mapStateToProps)(ShareSite);
