import React, { Component } from 'react';
import { connect } from 'react-redux';

class BloodType extends Component {

  state = {}

  componentDidMount = () => {}

  render() {
    const {} = this.state
    const { lang, type1, type2, type3 } = this.props
    
    return (
          <div className='d-flex container' style={{margin: '10px 0px'}}>
            { type1 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/others/bloodType/${type1}.jpg`)}
                    className='imgSelectSm' alt="blood type 1"/>
              </div>
            }
            { type2 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/others/bloodType/${type2}.jpg`)}
                    className='imgSelectSm' alt="blood type 2"/>
              </div>
            }
            { type3 &&
              <div>
                  <img
                    src={require(`../../../../public/images/character/others/bloodType/${type3}.jpg`)}
                    className='imgSelectSm' alt="blood type 3"/>
              </div>
            }

          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.subUser ? state.subUserInfo['_id'] : state.userInfo['_id'],
    type1: state.bloodType['type1'],
    type2: state.bloodType['type2'],
    type3: state.bloodType['type3'],
    lang: state.lang,
    setLT: state.setLT,

  }
}

export default connect (mapStateToProps)(BloodType);
